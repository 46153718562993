import React from "react";

import { DashboardHeaderWrapper, DashboardHeaderLabel, DashboardValue } from "./components";

const DashboardHeader = ({ label, value, placeholder, opacity }) => {
  return (
    <DashboardHeaderWrapper>
      {!!value ? (
        <>
          <DashboardHeaderLabel opacity={opacity ? "true" : undefined}>
            {label}
          </DashboardHeaderLabel>
          <DashboardValue opacity={opacity ? "true" : undefined}>{value}</DashboardValue>
        </>
      ) : (
        placeholder
      )}
    </DashboardHeaderWrapper>
  );
};

export default DashboardHeader;
