import { Field, Form, Formik } from "formik";
import { useHistory } from "react-router";
import React from "react";

import OutlineAuthorised from "../../components/outline-authorised/OutlineAuthorised";
import { FormContentWrapper } from "../../components/general/components";
import CustomButton from "../../components/button/CustomButton";
import InputDropdown from "../../components/input/InputDropdown";
import InputOption from "../../components/input/InputOption";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
// import ICONS from "../../constants/icons";
import COLORS from "../../constants/colors";
import URLS from "../../constants/urls";

import { useApp, useCategory } from "../../hooks";
import { createAnAppSchema } from "../../schemas";

import {
  InputNote,
  InputWrapper,
  BoldText,
  PageNote,
  PageFooter,
} from "./components";
import { TRACK_EVENTS } from "../../constants/mixpanel";
import mixpanel from "mixpanel-browser";

const AddApp = () => {
  const history = useHistory();
  const { addApp } = useApp();
  const { categories, categoriesLoading } = useCategory();

  return (
    <OutlineAuthorised pageTitle={STRINGS.create_an_app}>
      <Formik
        onSubmit={addApp}
        initialValues={{
          name: "",
          type: "",
          paid: false,
          icon: "",
          shortDescription: "short desc",
          description: "main desc",
          confirmed: false,
        }}
        validationSchema={createAnAppSchema}
      >
        {({ setFieldValue, errors, touched, isSubmitting }) => (
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormContentWrapper disabled={isSubmitting} leftAlign>
              <Field id="name" name="name">
                {({ field }) => (
                  <InputWrapper>
                    <InputText
                      width="42.4rem"
                      height="6.4rem"
                      borderColor={COLORS.secondary.water_green}
                      placeholder={STRINGS.app_name}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.app_name}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      backgroundColor="#fff"
                    />
                    <InputNote>* {STRINGS.this_is_how_your_app}</InputNote>
                  </InputWrapper>
                )}
              </Field>
              <Field id="type" name="type">
                {({ field }) => (
                  <InputWrapper>
                    <InputDropdown
                      width="42.4rem"
                      height="6.4rem"
                      isLoading={categoriesLoading}
                      borderColor={COLORS.secondary.water_green}
                      placeholder={STRINGS.application_type}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.application_type}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      errorStyle={{ left: "2rem" }}
                      backgroundColor="#fff"
                      padding="0 0 0 2rem "
                      options={categories?.map((x) => ({
                        ...x,
                        value: x?.id,
                        label: x?.name,
                      }))}
                    />
                    <InputNote>* {STRINGS.you_can_change}</InputNote>
                  </InputWrapper>
                )}
              </Field>
              {/* <Field id="paid" name="paid">
                {({ field }) => (
                  <InputWrapper>
                    <BoldText>{STRINGS.free_or_paid}</BoldText>
                    <InputOption
                      icon={ICONS.CircleWhite}
                      padding="0 0.8rem"
                      isChecked={!field.value}
                      setIsChecked={() => setFieldValue(field.name, false)}
                    >
                      {STRINGS.free}
                    </InputOption>
                    <InputOption
                      icon={ICONS.CircleWhite}
                      padding="0 0.8rem"
                      isChecked={field.value}
                      setIsChecked={() => setFieldValue(field.name, true)}
                    >
                      {STRINGS.paid}
                    </InputOption>
                  </InputWrapper>
                )}
              </Field> */}

              <Field id="confirmed" name="confirmed">
                {({ field }) => (
                  <InputOption
                    padding="1.6rem 0 0.8rem"
                    isChecked={field.value}
                    setIsChecked={() => setFieldValue(field.name, !field.value)}
                    error={touched[field.name] && errors[field.name]}
                  >
                    <BoldText>{STRINGS.confirm_app_meets}</BoldText>
                  </InputOption>
                )}
              </Field>
              <PageNote>
                {STRINGS.you_are_confirming_app_meets}
                <a href={URLS.terms_of_use} rel="noreferrer" target="_blank">
                  {STRINGS.terms_of_use}
                </a>
                {STRINGS.and}
                <a href={URLS.privacy_policy} rel="noreferrer" target="_blank">
                  {STRINGS.privacy_policy}
                </a>
                {STRINGS.please_check_out_our_terms}
              </PageNote>
              <PageFooter>
                <CustomButton
                  height="auto"
                  padding="0"
                  textColor={COLORS.primary.oxford}
                  margin="0 4.4rem"
                  fontFamily="Ubuntu-Medium"
                  onClick={() => {
                    mixpanel.track(TRACK_EVENTS.appCreationCanceled);
                    history.goBack();
                  }}
                  capitalize
                  type="button"
                >
                  {STRINGS.cancel}
                </CustomButton>
                <CustomButton
                  type="submit"
                  height="5.6rem"
                  padding="0 4rem"
                  backgroundColor={COLORS.secondary.ncs}
                  fontFamily="Ubuntu-Medium"
                  capitalize
                >
                  {STRINGS.create_app}
                </CustomButton>
              </PageFooter>
            </FormContentWrapper>
          </Form>
        )}
      </Formik>
    </OutlineAuthorised>
  );
};

export default AddApp;
