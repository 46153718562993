import React, { useMemo, useState } from "react";
import { useAlert } from "react-alert";
import moment from "moment";

import VerticalDotsDropdown from "../../components/vertical-dots-dropdown/VerticalDotsDropdown";
import AppReleaseAdd from "../../components/app-release-add/AppReleaseAdd";
import TableOutline from "../../components/table-outline/TableOutline";
import CustomTable from "../../components/custom-table/CustomTable";
import InfoModal from "../../components/info-modal/InfoModal";
import NoDataCard from "../../components/no-data-card/NoDataCard";
import CustomButton from "../../components/button/CustomButton";

import { AppReleaseStatus, InfoModalTypes } from "../../constants/types";
import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

import { useApplicationRelease, useHistoryCustom } from "../../hooks";
import URLS from "../../constants/urls";

const APP_RELEASE_ICONS = {
  [AppReleaseStatus.SUBMITTED]: ICONS.AppReleaseInReview,
  [AppReleaseStatus.PUBLISHED]: ICONS.AppReleaseLive,
  [AppReleaseStatus.BLOCKED]: ICONS.AppReleaseCancelled,
  [AppReleaseStatus.CANCELLED]: ICONS.AppReleaseCancelled,
};
const APP_RELEASE_TEXTS = {
  [AppReleaseStatus.DRAFT]: STRINGS.draft,
  [AppReleaseStatus.SUBMITTED]: STRINGS.waiting_for_review,
  [AppReleaseStatus.PUBLISHED]: STRINGS.app_status_live,
  [AppReleaseStatus.ARCHIVED]: STRINGS.Archived,
  [AppReleaseStatus.BLOCKED]: STRINGS.app_status_blocked,
  [AppReleaseStatus.CANCELLED]: STRINGS.app_status_cancelled,
};

const Actions = ({ releaseId, onDelete }) => {
  const { SetQs } = useHistoryCustom();

  return (
    <>
      <VerticalDotsDropdown
        actions={[
          {
            name: STRINGS.edit,
            icon: ICONS.GrayEdit,
            onClick: () => SetQs({ edittingRelease: releaseId }),
          },
          {
            name: STRINGS.delete,
            icon: ICONS.GrayTrash,
            onClick: onDelete,
          },
        ]}
      />
    </>
  );
};

const AppTabRealeses = ({ currApp, refetchApp, isAppInfoValid }) => {
  const alert = useAlert();

  const { SetQs, qs, setCategory } = useHistoryCustom();
  const [releaseToDelete, setReleaseToDelete] = useState(null);
  const [infoNotFilled, setInfoNotFilled] = useState(null);
  const { appReleaseDelete } = useApplicationRelease({ refetchApp });

  const data = useMemo(
    () => ({
      headers: [
        STRINGS.release_version,
        STRINGS.release_status,
        STRINGS.last_updated,
        STRINGS.number_of_installs,
      ],
      order: ["version", "status", "updatedAt", "installs", "actions"],
      grids: "24% 24% 24% 24% 4%",
      iconGetters: {
        status: (row) => APP_RELEASE_ICONS[row.status],
      },
      textGetters: {
        status: (row) => APP_RELEASE_TEXTS[row.status] || row.status,
        updatedAt: (row) => moment(row.updatedAt).format("DD/MM/YYYY, hh:mm A"),
        actions: (row) =>
          row?.status === "Draft" && (
            <Actions releaseId={row.id} onDelete={() => setReleaseToDelete(row.id)} />
          ),
        installs: () => "N/A",
      },
      rows: currApp?.releases,
    }),
    [currApp?.releases]
  );

  return (
    <>
      {releaseToDelete && (
        <InfoModal
          isOpen={true}
          type={InfoModalTypes.WARNING}
          onRequestClose={() => setReleaseToDelete(null)}
          title={STRINGS.warning}
          subTitle={STRINGS.you_are_about_to_delete_release}
          content={STRINGS.are_you_sure_delete_release}
          cancelText={STRINGS.cancel}
          submitText={STRINGS.yes_delete}
          submitAction={async () => {
            setReleaseToDelete(null);
            alert.info("Deleting...");
            await appReleaseDelete(releaseToDelete);
            alert.success("Release was successfully deleted");
          }}
          cancelAction={() => setReleaseToDelete(null)}
        />
      )}
      {infoNotFilled && (
        <InfoModal
          isOpen={true}
          type={InfoModalTypes.ATTENTION}
          onRequestClose={() => setInfoNotFilled(false)}
          title={STRINGS.attention}
          subTitle={STRINGS.first_fill_application_information}
          content={STRINGS.this_data_is_required_before_release}
          cancelText={STRINGS.cancel}
          submitText={STRINGS.edit_app_info}
          submitAction={() => {
            setCategory(URLS.app_page_tab, STRINGS.url_apptab, STRINGS.info);
          }}
          cancelAction={() => setInfoNotFilled(false)}
        />
      )}
      {qs.isAdding || qs.edittingRelease ? (
        <AppReleaseAdd
          refetchApp={refetchApp}
          currApp={currApp}
          initialValues={currApp?.releases?.find((x) => x.id === qs.edittingRelease)}
        />
      ) : currApp?.releases?.length ? (
        <TableOutline
          title={STRINGS.app_releases}
          actionName={STRINGS.create_release}
          actionAction={() => SetQs({ isAdding: true })}
        >
          <CustomTable {...data} />
        </TableOutline>
      ) : (
        <NoDataCard
          margin="0.4rem 0 2rem"
          title={STRINGS.release_your_first_v}
          icon={ICONS.NoReleaseIcon}
        >
          {STRINGS.you_wrote_app_release}
          <CustomButton
            backgroundColor={COLORS.secondary.ncs}
            margin="3.4rem 0 0"
            width="22rem"
            height="5.6rem"
            onClick={() => {
              if (isAppInfoValid) {
                SetQs({ isAdding: true });
              } else {
                setInfoNotFilled(true);
              }
            }}
          >
            {STRINGS.create_release}
          </CustomButton>
        </NoDataCard>
      )}
    </>
  );
};

export default AppTabRealeses;
