import React from "react";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import CustomButton from "../../components/button/CustomButton";

import useUser from "../../hooks/user";
import { SubTitle, Title, VerifyEmailImg } from "./components";

const VerifyEmail = () => {
  const email = localStorage.getItem("emailToRegister");
  const { resendEmail, resendLoading } = useUser();
  return (
    <OutlineNonAuthorised doubleBackground>
      <VerifyEmailImg />
      <Title style={{ margin: "1.6rem 0" }}>{STRINGS.verify_your_email}</Title>
      <SubTitle style={{ width: "32.2rem" }}>
        {STRINGS.we_have_sent_confirmation} {email}. {STRINGS.please_verfiy_your_account}
      </SubTitle>
      <CustomButton
        disabled={resendLoading}
        borderColor={COLORS.secondary.ncs}
        margin="2.4rem 0"
        onClick={() => resendEmail(email)}
      >
        {STRINGS.resend_email}
      </CustomButton>
      <SubTitle style={{ width: "28rem", fontSize: "1.2rem" }}>
        {STRINGS.you_wont_create_app}
      </SubTitle>
    </OutlineNonAuthorised>
  );
};

export default VerifyEmail;
