import { Field, Form, Formik } from "formik";
import React, { useMemo, useRef } from "react";

import { AppReleaseStatus, InfoModalTypes } from "../../constants/types";
import COLORS from "../../constants/colors";

import InputNote from "../input/InputNote";
import InputText from "../input/InputText";
import InputFile from "../input/InputFile";
import InputOption from "../input/InputOption";
import CustomButton from "../button/CustomButton";
import InputDropdown from "../input/InputDropdown";
import InfoModal from "../info-modal/InfoModal";
import { FormContentWrapper } from "../general/components";
import { LoadingGIF } from "../../pages/app-page/components";
import FixedButtonFooter from "../fixed-button-footer/FixedButtonFooter";

import { createAReleaseSchema } from "../../schemas/applicationRelease";
import { useApplicationRelease, useForm } from "../../hooks";

import {
  DragAndDropWrapper,
  FlexWrapper,
  GeneralError,
  MainSection,
  OrDivider,
  SectionTitle,
  WholeWrapper,
} from "./components";

const emptyPrevious = { label: "-- choose --", value: undefined };

const validateFormik = async (validateForm = () => {}, setErrors = () => {}) => {
  const isValid = await validateForm();
  if (Object.keys(isValid)?.length) {
    setErrors(isValid);
    return false;
  }
  return true;
};

const AppReleaseAdd = ({ currApp, refetchApp, initialValues }) => {
  let formRef = useRef();
  const { hasUnsavedChanges, scrollToFirstError } = useForm(formRef);

  const { appReleaseCreateUpdate, isLoading } = useApplicationRelease({
    refetchApp,
  });
  const webAppPrevious = useMemo(
    () =>
      currApp?.releases
        ?.filter((x) => !!x.frontend && x.frontend.version === x.version)
        .map((rel) => ({
          value: rel.frontend.id,
          label: `${rel.frontend.version} (${rel.status})`,
        })),
    [currApp]
  );
  const garysCodePrevious = useMemo(
    () =>
      currApp?.releases
        ?.filter((x) => !!x.backend && x.backend.version === x.version)
        .map((rel) => ({
          value: rel.backend.id,
          label: `${rel.backend.version} (${rel.status})`,
        })),
    [currApp]
  );

  const hasDraft = useMemo(
    () =>
      currApp?.releases?.find(
        (x) =>
          x.status === AppReleaseStatus.DRAFT && initialValues?.status !== AppReleaseStatus.DRAFT
      ),
    [currApp, initialValues]
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        version: "",
        web_app: null,
        garys_code: undefined,
        frontendId: initialValues?.frontend
          ? {
              value: initialValues.frontend.id,
              label: `${initialValues.frontend.version} (${initialValues.status})`,
            }
          : emptyPrevious,
        backendId: initialValues?.backend
          ? {
              value: initialValues.backend.id,
              label: `${initialValues.backend.version} (${initialValues.status})`,
            }
          : emptyPrevious,
        garys_code_empty: !initialValues || !initialValues?.backend,
        ...initialValues,
      }}
      validationSchema={createAReleaseSchema}
    >
      {({
        setFieldValue,
        errors,
        touched,
        values,
        setErrors,
        setTouched,
        validateForm,
        isSubmitting,
      }) => (
        <Form>
          <FormContentWrapper
            disabled={isSubmitting || isLoading}
            style={{ marginBottom: "3.2rem" }}
          >
            <InfoModal
              isOpen={isSubmitting || isLoading}
              type={InfoModalTypes.ATTENTION}
              title="Uploading…"
              content="We are processing your information. Please be patient, it might take a while."
              noOuterClickClosing
              disabled
              gif={<LoadingGIF />}
            />
            <WholeWrapper>
              <MainSection>
                <SectionTitle>Release</SectionTitle>
                <Field id="version" name="version">
                  {({ field }) => (
                    <InputText
                      width="36.4rem"
                      height="6.4rem"
                      backgroundColor={COLORS.secondary.off_white}
                      placeholder="Release version"
                      onChange={(v) => setFieldValue(field.name, v)}
                      name="Release version"
                      value={field.value}
                      error={touched.all && errors[field.name]}
                      borderColor={COLORS.secondary.water_green}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>
                  * This value will not be shown on marketplace and it purpose is to help you
                  tracking your versions of the app
                </InputNote>
                <FlexWrapper>
                  <div>
                    <SectionTitle>Customer interface web app</SectionTitle>
                    <Field id="web_app" name="web_app">
                      {({ field }) => (
                        <DragAndDropWrapper>
                          <InputFile
                            inputId={field.name}
                            multiple={false}
                            error={
                              touched.all &&
                              (!!errors[field.name] || !!errors?.frontendId) &&
                              "Either upload a new or choose previous version"
                            }
                            errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            onChange={(v) => {
                              setFieldValue(field.name, v?.[0]);
                              setFieldValue("frontendId", emptyPrevious);
                              setTimeout(() => validateFormik(validateForm, setErrors), 200);
                            }}
                          >
                            {field?.value
                              ? field?.value?.name || field?.value?.fileName
                              : "Drag & Drop your zip file"}
                          </InputFile>
                        </DragAndDropWrapper>
                      )}
                    </Field>
                  </div>
                  {!!webAppPrevious?.length && (
                    <>
                      <OrDivider>Or</OrDivider>
                      <div>
                        <SectionTitle>Use Previous Version</SectionTitle>
                        <Field id="frontendId" name="frontendId">
                          {({ field }) => (
                            <InputDropdown
                              options={[emptyPrevious, ...webAppPrevious]}
                              width="36.2rem"
                              height="5.6rem"
                              backgroundColor={COLORS.secondary.off_white}
                              onChange={(v) => {
                                setFieldValue(field.name, v);
                                setFieldValue("web_app", null);
                                validateFormik(validateForm, setErrors);
                                setTimeout(() => validateFormik(validateForm, setErrors), 200);
                              }}
                              value={field.value}
                              borderColor={COLORS.secondary.water_green}
                              borderWidth="1px"
                              padding="0 2rem 0 2.4rem"
                            />
                          )}
                        </Field>
                      </div>
                    </>
                  )}
                </FlexWrapper>
                <InputNote>
                  * URL of the web app interface that customers will use to interact with Gary's
                  code.
                </InputNote>

                <FlexWrapper>
                  <div>
                    <FlexWrapper>
                      <SectionTitle>Gary's Code</SectionTitle>
                      <Field id="garys_code_empty" name="garys_code_empty">
                        {({ field }) => (
                          <InputOption
                            margin="4.2rem 0 1.2rem 3.2rem"
                            isChecked={field.value}
                            setIsChecked={() => setFieldValue(field.name, !field.value)}
                          >
                            Mark as empty
                          </InputOption>
                        )}
                      </Field>
                    </FlexWrapper>
                    <Field id="garys_code" name="garys_code">
                      {({ field }) => (
                        <DragAndDropWrapper hasOpacity={values?.garys_code_empty}>
                          <InputFile
                            inputId={field.name}
                            multiple={false}
                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            onChange={(v) => {
                              setFieldValue(field.name, v?.[0]);
                              setFieldValue("backendId", emptyPrevious);
                              setTimeout(() => validateFormik(validateForm, setErrors), 200);
                            }}
                            disabled={values?.garys_code_empty}
                            error={
                              touched.all &&
                              (!!errors[field.name] || !!errors?.backendId) &&
                              "Either mark as empty, upload a new or choose previous version"
                            }
                            errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                          >
                            {field?.value
                              ? field?.value?.name || field?.value?.fileName
                              : "Drag & Drop your zip file"}
                          </InputFile>
                        </DragAndDropWrapper>
                      )}
                    </Field>
                  </div>
                  {!!garysCodePrevious?.length && (
                    <FlexWrapper disabled={values?.garys_code_empty}>
                      <OrDivider>Or</OrDivider>
                      <div>
                        <SectionTitle>Use Previous Version</SectionTitle>
                        <Field id="backendId" name="backendId">
                          {({ field }) => (
                            <InputDropdown
                              options={[emptyPrevious, ...garysCodePrevious]}
                              width="36.2rem"
                              height="5.6rem"
                              backgroundColor={COLORS.secondary.off_white}
                              onChange={(v) => {
                                setFieldValue(field.name, v);
                                setFieldValue("garys_code", null);
                                setTimeout(() => validateFormik(validateForm, setErrors), 200);
                              }}
                              value={field.value}
                              borderColor={COLORS.secondary.water_green}
                              borderWidth="1px"
                              padding="0 2rem 0 2.4rem"
                            />
                          )}
                        </Field>
                      </div>
                    </FlexWrapper>
                  )}
                </FlexWrapper>
                <InputNote style={{ opacity: values?.garys_code_empty ? 0.4 : 1 }}>
                  Zip file should contain all the code that needs to be run on Gary's body:
                  <br />
                  <br />
                  ● business logic
                  <br />
                  ● hardware/sensors/motors interactions
                  <br />
                  ● APIs for client interface
                  <br />
                  <br />
                  For more information about the Zip file structure and how to build it right,
                  please read this doc
                </InputNote>
                {errors.general && (
                  <GeneralError className="error">{`* ${errors.general}`}</GeneralError>
                )}
              </MainSection>
            </WholeWrapper>
          </FormContentWrapper>
          <FixedButtonFooter>
            <CustomButton
              type="button"
              height="4rem"
              margin="0 2rem"
              borderColor={COLORS.primary.oxford}
              onClick={async () => {
                setTouched({ all: true });
                const isValid = await validateFormik(validateForm, setErrors);
                if (isValid) {
                  appReleaseCreateUpdate(values, false, {
                    setErrors,
                    refetchApp,
                    hasUnsavedChanges,
                  });
                } else {
                  scrollToFirstError();
                }
              }}
              disabled={hasDraft}
            >
              Save as draft
            </CustomButton>
            <CustomButton
              type="button"
              height="4rem"
              backgroundColor={COLORS.secondary.ncs}
              onClick={async () => {
                setTouched({ all: true });
                const isValid = await validateFormik(validateForm, setErrors);
                if (isValid) {
                  appReleaseCreateUpdate(values, true, {
                    setErrors,
                    refetchApp,
                  });
                } else {
                  scrollToFirstError();
                }
              }}
            >
              Submit
            </CustomButton>
          </FixedButtonFooter>
        </Form>
      )}
    </Formik>
  );
};

export default AppReleaseAdd;
