import React, { useContext, useRef } from "react";
import { Field, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import CustomFormSection from "../../components/custom-form-section/CustomFormSection";
import FixedButtonFooter from "../../components/fixed-button-footer/FixedButtonFooter";
import OutlineAuthorised from "../../components/outline-authorised/OutlineAuthorised";
import MediaFilePreview from "../../components/media-file-preview/MediaFilePreview";
import { FormContentWrapper } from "../../components/general/components";
import InputTextArea from "../../components/input/InputTextArea";
import CustomButton from "../../components/button/CustomButton";
import { InputError } from "../../components/input/components";
import InputFile from "../../components/input/InputFile";
import InputNote from "../../components/input/InputNote";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useUtils, useUser, useForm } from "../../hooks";
import { EditUserSchema } from "../../schemas";

import {
  FieldName,
  FieldNote,
  FlexWrapper,
  IconDeleteIcon,
  IconDragNDropWrapper,
  IconReqLabel,
  IconReqVal,
} from "../app-page/components";

const EditProfile = () => {
  let formRef = useRef();
  const { currentUser } = useContext(CurrentUserContext);
  const { lineByLine } = useUtils();
  const { editUser, editUserLoading } = useUser();
  const { hasUnsavedChanges, scrollToFirstError } = useForm(formRef);

  return (
    <OutlineAuthorised pageTitle={STRINGS.edit_profile} paddingTop="5.2rem">
      <Formik
        innerRef={formRef}
        initialValues={{
          firstName: currentUser.firstName || "",
          lastName: currentUser.lastName || "",
          email: currentUser.email,
          phoneNumber: currentUser.phoneNumber || "",
          phonePrefix: currentUser.phonePrefix || "",
          address: currentUser.address || "",
          nickname: currentUser.nickname || "",
          website: currentUser.website || "",
          icon: currentUser.icon || "",
          about: currentUser.about || "",
        }}
        onSubmit={(v) => editUser(v, { hasUnsavedChanges })}
        validationSchema={EditUserSchema}
      >
        {({
          setFieldValue,
          errors,
          touched,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <FormContentWrapper
              disabled={isSubmitting || editUserLoading}
              style={{ marginBottom: "3.2rem" }}
            >
              <CustomFormSection
                title={STRINGS.personal_info_title}
                subtitle={STRINGS.personal_info_subtitle}
              >
                <Field id="firstName" name="firstName">
                  {({ field }) => (
                    <InputText
                      width="36.4rem"
                      height="6.4rem"
                      placeholder={STRINGS.firstname}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.firstname}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.name_note}</InputNote>
                <Field id="lastName" name="lastName">
                  {({ field }) => (
                    <InputText
                      width="36.4rem"
                      height="6.4rem"
                      margin="3.2rem 0 0 0"
                      placeholder={STRINGS.lastname}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.lastname}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.name_note}</InputNote>
                <Field id="email" name="email">
                  {({ field }) => (
                    <InputText
                      disabled
                      width="36.4rem"
                      height="6.4rem"
                      margin="3.2rem 0 0 0"
                      placeholder={STRINGS.email}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.email}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.email_note}</InputNote>
                <Field id="phoneNumber" name="phoneNumber">
                  {({ field }) => (
                    <PhoneInput
                      country={"il"}
                      value={(values.phonePrefix || "") + (field.value || "")}
                      onChange={(v, country) => {
                        const prefixLength =
                          country?.format?.split(" ")?.[0]?.length - 1 || 0;
                        const pNumber = v.slice(prefixLength);
                        setFieldValue(field.name, pNumber);
                        setFieldValue("phonePrefix", `+${country?.dialCode}`);
                      }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.name_note}</InputNote>
                <div style={{ position: "relative" }}>
                  {errors?.phoneNumber && (
                    <InputError className="error" style={{ left: 0, top: 0 }}>
                      * {errors?.phoneNumber}
                    </InputError>
                  )}
                </div>
                <Field id="address" name="address">
                  {({ field }) => (
                    <InputText
                      width="36.4rem"
                      height="6.4rem"
                      margin="3.2rem 0 0 0"
                      placeholder={STRINGS.physical_address}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.physical_address}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.address_note}</InputNote>
              </CustomFormSection>
              <CustomFormSection
                title={STRINGS.developer_page_title}
                subtitle={STRINGS.developer_page_subtitle}
                margin="4.2rem 0 0 0"
              >
                <Field id="nickname" name="nickname">
                  {({ field }) => (
                    <InputText
                      width="36.4rem"
                      height="6.4rem"
                      placeholder={STRINGS.developer_name}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.developer_name}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.nickname_note}</InputNote>
                <Field id="website" name="website">
                  {({ field }) => (
                    <InputText
                      width="36.4rem"
                      height="6.4rem"
                      margin="3.2rem 0 0 0"
                      placeholder={STRINGS.website_address}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.website_address}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.website_note}</InputNote>
                <Field id="icon" name="icon">
                  {({ field }) => (
                    <FlexWrapper style={{ marginTop: "3.2rem" }}>
                      <IconDragNDropWrapper>
                        <InputFile
                          inputId={field.name}
                          multiple={false}
                          value={field.value}
                          accept="image/jpeg, image/png"
                          onChange={(v) => setFieldValue(field.name, v?.[0])}
                          preview={
                            !!field.value && (
                              <MediaFilePreview file={field.value} />
                            )
                          }
                          error={touched[field.name] && errors[field.name]}
                        >
                          {STRINGS.upload}
                        </InputFile>
                        {!!field.value && (
                          <IconDeleteIcon
                            onClick={() => setFieldValue(field.name, undefined)}
                          />
                        )}
                      </IconDragNDropWrapper>
                      <div key="2">
                        <FieldName>{STRINGS.developer_icon}</FieldName>
                        <FieldNote>{lineByLine(STRINGS.icon_note)}</FieldNote>
                        <IconReqLabel>{STRINGS.requirements}:</IconReqLabel>
                        <IconReqVal>
                          {lineByLine(STRINGS.app_icon_requirements)}
                        </IconReqVal>
                      </div>
                    </FlexWrapper>
                  )}
                </Field>
                <Field id="about" name="about">
                  {({ field }) => (
                    <InputTextArea
                      width="59.6rem"
                      height="12.8rem"
                      margin="3.2rem 0 0 0"
                      placeholder={STRINGS.about_yourself}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.about_yourself}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.about_note}</InputNote>
              </CustomFormSection>
            </FormContentWrapper>
            <FixedButtonFooter>
              <CustomButton
                type="submit"
                height="4rem"
                backgroundColor={COLORS.secondary.ncs}
                onClick={scrollToFirstError}
              >
                {STRINGS.save}
              </CustomButton>
            </FixedButtonFooter>
          </Form>
        )}
      </Formik>
    </OutlineAuthorised>
  );
};

export default EditProfile;
