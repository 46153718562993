import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 3.2rem 1.5rem 4.2rem;
  align-items: center;
  background: #fff;
  border-radius: ${({ borderRadius }) => borderRadius || "0"};
  margin: ${({ margin }) => margin || "0"};
  box-shadow: 0px 7px 25px 0px #0093c617;
`;

export const CardTitle = styled.div`
  font-family: ${FONTS.UbuntuMedium};
  font-size: 1.8rem;
  letter-spacing: 0.5px;
  margin-bottom: 0.8rem;
  line-height: 2.4rem;
  color: ${COLORS.secondary.ncs};
`;
export const CardMain = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${COLORS.secondary.morning};
  flex-grow: 1;
`;

export const CardLogo = styled.div`
  min-width: 7.4rem;
  min-height: 7.4rem;
  background-image: url(${({ logo }) => logo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 4rem;
  margin-top: 0.8rem;
`;

export const LinkPart = styled.div`
  min-width: fit-content;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${COLORS.secondary.emerald};
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: ${FONTS.UbuntuMedium};
  margin-left: 4rem;
`;

export const LinkArrow = styled.div`
  min-width: 4.8rem;
  min-height: 4.8rem;
  background-image: url(${ICONS.ArrowRightGreenBox});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 2rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`;
