import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const DashboardCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
  padding: 0.8rem 0;
  justify-content: space-between;
`;

export const RightMostWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FlexWrapper = styled.div`
  display: flex;
  margin-bottom: 3.2rem;
  &:first-child {
    margin-bottom: 6.2rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const IconDragNDropWrapper = styled.div`
  width: 17rem;
  height: 17rem;
  position: relative;
  margin-right: 4.4rem;
`;

export const AppReviewDragNDropWrapper = styled.div`
  width: 43.6rem;
  height: 21rem;
  position: relative;
  margin-right: 4.4rem;
`;

export const ScreenshotsDragNDropWrapper = styled.div`
  min-width: 20rem;
  min-height: 12rem;
  height: 12rem;
  position: relative;
  margin: 4rem 0rem 4rem 1.6rem;
`;

export const ScreenshotsContainer = styled.div`
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  margin: 4rem 0;
  align-items: flex-start;
`;

export const RelativeBox = styled.div`
  position: relative;
  margin-left: 3.2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }
`;

export const ScreenshotWrapper = styled.img`
  max-width: 20rem;
  max-height: 20rem;
  min-width: 12rem;
  min-height: 12rem;
  border: 1px solid ${COLORS.secondary.water_gray};
`;

export const AppReviewWrapper = styled.div`
  width: 20rem;
  height: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const IconDeleteIcon = styled.div`
  position: absolute;
  width: 1.4rem;
  height: 1.4rem;
  background-image: url(${ICONS.DeleteBin});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  right: -1.8rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const FieldName = styled.div`
  color: ${COLORS.secondary.ncs};
  font-size: 1.4rem;
  font-family: ${FONTS.UbuntuMedium};
  line-height: 2.2rem;
  letter-spacing: 0.01;
`;

export const FieldNote = styled.div`
  color: ${COLORS.secondary.feldgrau};
  font-size: 1.2rem;
  font-family: ${FONTS.UbuntuRegular};
  line-height: 1.8rem;
`;

export const IconReqLabel = styled.div`
  color: ${COLORS.secondary.feldgrau};
  font-size: 1.1rem;
  font-family: ${FONTS.UbuntuBold};
  line-height: 1.6rem;
  margin: 2rem 0 0.4rem;
`;

export const IconReqVal = styled.div`
  width: 10rem;
  color: ${COLORS.secondary.feldgrau};
  font-size: 1.1rem;
  font-family: ${FONTS.UbuntuRegular};
  line-height: 1.6rem;
`;

export const LoadingGIF = styled.div`
  width: 20rem;
  height: 20rem;
  background-image: url(${ICONS.LoaderGIF});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 20rem;
`;
