import React from "react";
import { LoaderContainer, LoaderSign, LoaderText } from "./components";

const Loader = ({ fontSize, height, padding, noText }) => {
  return (
    <LoaderContainer style={{ height, padding }}>
      <LoaderSign />
      {!noText && <LoaderText style={{ fontSize }}>Loading, Please, Wait</LoaderText>}
    </LoaderContainer>
  );
};

export default Loader;
