import styled from "styled-components";

export const CustomTableWrapper = styled.div`
  width: 100%;
`;

export const CustomTH = styled.div`
  text-align: left;
  font-family: Ubuntu-Light;
  font-size: 1.4rem;
  line-height: 1.5rem;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme?.color?.secondary?.morning};
  text-transform: uppercase;
  font-weight: bold;
`;

export const CustomTHead = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ grids }) => grids};
  padding: 2rem 5rem;
  box-shadow: 0px 5px 10px 0px #0093c60a;
  border-bottom: 1px solid ${({ theme }) => theme?.color?.secondary?.water_gray};
`;
export const CustomTBody = styled.div`
  width: 100%;
  padding: 1.6rem 3.4rem;
`;

export const CustomTRWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme?.color?.secondary?.water_gray};
  padding: 1.5rem 0;
  &:last-child {
    border: none;
  }
`;

export const CustomTR = styled.div`
  width: 100%;
  display: grid;
  padding: 1.6rem;
  grid-template-columns: ${({ grids }) => grids};
  border-radius: 1.6rem;
  &:hover {
    background: ${({ theme }) => `${theme?.color?.secondary?.aero}4d`};
  }
`;

export const CustomTD = styled.div`
  font-family: Ubuntu-Medium;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.1px;
  text-align: left;
  display: flex;
  align-items: center;
`;

export const CustomTDIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 1.2rem;
`;
