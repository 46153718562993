import React, { useCallback, useEffect, useRef, useState } from "react";

import { InfoModalTypes } from "../../constants/types";
import STRINGS from "../../constants/strings";
import URLS from "../../constants/urls";

import { DeleteAppButton, DeleteAppButtonIcon } from "../../pages/apps/components";
import InfoModal from "../info-modal/InfoModal";
import AppCard from "../app-card/AppCard";

import { useApp, useHistoryCustom } from "../../hooks";
import { AppDropdownWrapper, CurrentAppWrapper } from "./components";
import InputFile from "../input/InputFile";
import MediaFilePreview from "../media-file-preview/MediaFilePreview";

const AppsDropdown = ({ apps, currentApp, refetchApp }) => {
  const { deleteApplication } = useApp();
  const [isOpen, setIsOpen] = useState(false);
  const [appTodelete, setAppToDelete] = useState();
  const { updateAppIcon } = useApp();

  const reff = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        reff.current &&
        !reff.current?.contains(event.target) &&
        !event?.target?.className?.includes("inner")
      ) {
        setIsOpen(false);
      }
    },
    [reff]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [handleClickOutside]);

  const { history, setCategory } = useHistoryCustom();
  return (
    <AppDropdownWrapper ref={reff}>
      {appTodelete && (
        <InfoModal
          isOpen={appTodelete}
          type={InfoModalTypes.WARNING}
          title={STRINGS.delete_app}
          subTitle={`${STRINGS.are_you_sure_delete_app} "${appTodelete.name}" ?`}
          content={STRINGS.delete_app_warning}
          submitText={STRINGS.delete}
          cancelText={STRINGS.cancel}
          submitAction={() => {
            deleteApplication(appTodelete.id);
            history.push(URLS.apps);
          }}
          onRequestClose={() => setAppToDelete(null)}
          cancelAction={() => setAppToDelete(null)}
        />
      )}
      <CurrentAppWrapper onClick={(e) => setIsOpen(true)} isOpen={isOpen}>
        <AppCard
          arrowUp={isOpen}
          {...currentApp}
          arrowAction={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          appIcon={
            <InputFile
              multiple={false}
              accept="image/jpeg, image/png"
              onChange={(v) =>
                updateAppIcon({ id: currentApp.id, icon: v }, { refetchApp: refetchApp })
              }
              preview={<MediaFilePreview file={currentApp.icon} />}
            >
              {STRINGS.upload}
            </InputFile>
          }
        >
          <DeleteAppButton
            className="card-delete"
            onClick={(e) => {
              e.stopPropagation();
              setAppToDelete(currentApp);
            }}
            style={{ right: "10.8rem" }}
          >
            <DeleteAppButtonIcon />
          </DeleteAppButton>
        </AppCard>
      </CurrentAppWrapper>
      {isOpen &&
        apps?.map(
          (app, i) =>
            app.id !== currentApp.id && (
              <AppCard
                key={i}
                {...app}
                onClick={() => {
                  setCategory(URLS.app_page, "id", app.id);
                  setIsOpen(false);
                }}
              />
            )
        )}
    </AppDropdownWrapper>
  );
};

export default AppsDropdown;
