import { Field, Form, Formik } from "formik";
import React, { useState } from "react";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import { FormContentWrapper } from "../../components/general/components";
import InfoModal from "../../components/info-modal/InfoModal";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

import { SetPasswordSchema } from "../../schemas";
import { useUser } from "../../hooks";

import { Title } from "./components";

const SetPassword = () => {
  const [sucessOpen, setSuccessOpen] = useState();
  const { userSetPassword, userSetPasswordLoading } = useUser();

  return (
    <OutlineNonAuthorised>
      <InfoModal {...sucessOpen} noOuterClickClosing okayText={"return to sign in"} />
      <Title style={{ fontSize: "4rem" }}>{STRINGS.set_password}</Title>
      <Formik
        onSubmit={(v) => userSetPassword(v, { setSuccessOpen })}
        initialValues={{
          password: "",
          repassword: "",
        }}
        validationSchema={SetPasswordSchema}
      >
        {({ setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            <FormContentWrapper
              style={{
                width: "36.8rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              disabled={isSubmitting || userSetPasswordLoading}
            >
              <Field id="password" name="password">
                {({ field }) => (
                  <InputText
                    type="password"
                    height="5.6rem"
                    margin="1.8rem 0"
                    placeholder={STRINGS.please_type_your_password}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={"new password"}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.PasswordEye}
                  />
                )}
              </Field>
              <Field id="repassword" name="repassword">
                {({ field }) => (
                  <InputText
                    type="password"
                    height="5.6rem"
                    margin="1.8rem 0"
                    placeholder={STRINGS.please_type_your_password_again}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.repeat_password}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.PasswordEye}
                  />
                )}
              </Field>
              <CustomButton
                type="submit"
                backgroundColor={COLORS.secondary.ncs}
                width="100%"
                padding="0 7.2rem"
                height="5.8rem"
                margin="2rem 0 1.4rem"
                shadow
                capitalize
              >
                {STRINGS.next}
              </CustomButton>
            </FormContentWrapper>
          </Form>
        )}
      </Formik>
    </OutlineNonAuthorised>
  );
};

export default SetPassword;
