import styled from "styled-components";
import ICONS from "../../constants/icons";

export const ActionsDropdown = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  background-image: url(${ICONS.VerticalDots});
  cursor: pointer;
  position: relative;
`;

export const ActionsDropdownMenu = styled.div`
  position: absolute;
  padding: 1.4rem;
  background: #fff;
  box-shadow: 0px 4px 18px 0px #0096c624;
  top: Calc(100% - 0.4rem);
  right: Calc(100% - 1.4rem);
  border-radius: 1.6rem;
`;

export const ActionsDropdownActionIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 2rem;
`;

export const ActionsDropdownAction = styled.div`
  width: 100%;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.01rem;
  margin-top: 0.4rem;
  text-transform: capitalize;
  &:hover {
    background: ${({ theme }) => `${theme?.color?.secondary?.aero}4d`};
  }
  &:active {
    background: transparent;
  }
  &:first-child {
    margin: 0;
  }
`;
