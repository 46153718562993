export const AppStatus = {
  Submitted: "Submitted",
  IN_REVIEW: "IN_REVIEW",
  LIVE: "LIVE",
};

export const AppReleaseStatus = {
  PUBLISHED: "Published",
  SUBMITTED: "Submitted",
  DRAFT: "Draft",
  BLOCKED: "Blocked",
  CANCELLED: "Cancelled",
  ARCHIVED: "Archived",
};

export const InfoModalTypes = {
  SUCCESS: "SUCCESS",
  ATTENTION: "ATTENTION",
  WARNING: "WARNING",
};

export const EnvTypes = {
  LOCAL: "local",
  DEV: "development",
  PROD: "production",
};
