import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";

import CustomFormSection from "../../components/custom-form-section/CustomFormSection";
import FixedButtonFooter from "../../components/fixed-button-footer/FixedButtonFooter";
import MediaFilePreview from "../../components/media-file-preview/MediaFilePreview";
import InfoModal from "../../components/info-modal/InfoModal";
import InputTextArea from "../../components/input/InputTextArea";
import CustomButton from "../../components/button/CustomButton";
import InputNote from "../../components/input/InputNote";
import InputFile from "../../components/input/InputFile";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";

import { updateAnAppSchema } from "../../schemas";
import { useUtils, useApp, useForm } from "../../hooks";

import {
  FieldName,
  FieldNote,
  FlexWrapper,
  IconDeleteIcon,
  IconDragNDropWrapper,
  IconReqLabel,
  IconReqVal,
  RightMostWrapper,
  AppReviewDragNDropWrapper,
  AppReviewWrapper,
  ScreenshotsDragNDropWrapper,
  ScreenshotsContainer,
  ScreenshotWrapper,
  RelativeBox,
  LoadingGIF,
} from "./components";
import { FormContentWrapper } from "../../components/general/components";
import { InfoModalTypes } from "../../constants/types";

const AppTabInfo = ({ currApp, refetchApp }) => {
  let formRef = useRef();
  const { hasUnsavedChanges, scrollToFirstError } = useForm(formRef);

  const { updateCurrApp, isUpdating } = useApp();
  const { lineByLine } = useUtils();
  const [infoModalOpen, setInfoModalOpen] = useState();

  return (
    <>
      <InfoModal
        {...infoModalOpen}
        onRequestClose={() => {
          setInfoModalOpen(null);
          refetchApp();
        }}
        noOuterClickClosing
      />
      <Formik
        innerRef={formRef}
        onSubmit={(v) => updateCurrApp(v, { refetchApp, setInfoModalOpen, hasUnsavedChanges })}
        initialValues={{
          ...currApp,
          icon: currApp?.icon ? { ...currApp?.icon, type: currApp?.icon?.mimetype } : undefined,
          removeScreenshots: [],
          preview_length: 11,
        }}
        validationSchema={updateAnAppSchema}
        enableReinitialize
      >
        {({ setFieldValue, errors, touched, values, isSubmitting }) => (
          <Form>
            <FormContentWrapper disabled={isSubmitting || isUpdating}>
              <InfoModal
                isOpen={isSubmitting || isUpdating}
                type={InfoModalTypes.ATTENTION}
                title="Uploading…"
                content="We are processing your information. Please be patient, it might take a while."
                noOuterClickClosing
                disabled
                gif={<LoadingGIF />}
              />
              <CustomFormSection title={STRINGS.app_registration} margin="4.2rem 0 0 0">
                <Field id="name" name="name">
                  {({ field }) => (
                    <InputText
                      width="36.4rem"
                      height="6.4rem"
                      placeholder={STRINGS.app_Name}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={field.name}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.this_is_how_your_app}</InputNote>

                <Field id="shortDescription" name="shortDescription">
                  {({ field }) => (
                    <InputText
                      width="59.6rem"
                      height="6.4rem"
                      margin="3.2rem 0 0 0"
                      placeholder={STRINGS.short_description}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.short_description}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0, top: "Calc(100% + 2.4rem)" }}
                    />
                  )}
                </Field>
                <InputNote>* {STRINGS.short_description_of_your_app}</InputNote>
                <Field id="description" name="description">
                  {({ field }) => (
                    <InputTextArea
                      width="59.6rem"
                      height="12.8rem"
                      margin="3.2rem 0 0 0"
                      placeholder={STRINGS.full_description}
                      onChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.full_description}
                      value={field.value}
                      error={touched[field.name] && errors[field.name]}
                      style={{ borderWidth: "1px" }}
                      errorStyle={{ left: 0 }}
                    />
                  )}
                </Field>
              </CustomFormSection>
              <CustomFormSection title={STRINGS.graphics} margin="4.2rem 0 3.2rem 0">
                <Field id="icon" name="icon">
                  {({ field }) => (
                    <FlexWrapper>
                      <IconDragNDropWrapper>
                        <InputFile
                          inputId={field.name}
                          multiple={false}
                          value={field.value}
                          accept="image/jpeg, image/png"
                          onChange={(v) => setFieldValue(field.name, v?.[0])}
                          preview={!!field.value && <MediaFilePreview file={field.value} />}
                          error={touched.name && errors[field.name]}
                        >
                          {STRINGS.upload}
                        </InputFile>
                        {!!field.value && (
                          <IconDeleteIcon onClick={() => setFieldValue(field.name, undefined)} />
                        )}
                      </IconDragNDropWrapper>
                      <div key="2">
                        <FieldName>{STRINGS.app_icon}</FieldName>
                        <FieldNote>{lineByLine(STRINGS.your_app_as_its_shown)}</FieldNote>
                        <IconReqLabel>{STRINGS.requirements}:</IconReqLabel>
                        <IconReqVal>{lineByLine(STRINGS.app_icon_requirements)}</IconReqVal>
                      </div>
                    </FlexWrapper>
                  )}
                </Field>
                <Field id="screenshots" name="screenshots">
                  {({ field }) => (
                    <FlexWrapper>
                      <div style={{ width: "22rem", margin: "4rem 0" }}>
                        <FieldName>{STRINGS.app_screenshots}</FieldName>
                        <FieldNote>{lineByLine(STRINGS.app_screenshots_note)}</FieldNote>
                      </div>
                      <ScreenshotsDragNDropWrapper>
                        <InputFile
                          inputId={field.name}
                          accept="image/jpeg, image/png"
                          onChange={(v) =>
                            setFieldValue(field.name, [...(field?.value || []), ...(v || [])])
                          }
                          error={touched[field.name] && errors[field.name]}
                          errorStyle={{ left: "-23.6rem" }}
                          crop={{ x: 9, y: 16 }}
                          multiple
                        >
                          {STRINGS.upload}
                        </InputFile>
                      </ScreenshotsDragNDropWrapper>
                      <ScreenshotsContainer>
                        {field.value?.map((scr, i) => (
                          <RelativeBox key={i}>
                            <ScreenshotWrapper src={scr?.publicUrl || URL.createObjectURL(scr)} />
                            <IconDeleteIcon
                              onClick={() => {
                                if (scr?.id) {
                                  setFieldValue("removeScreenshots", [
                                    ...values?.removeScreenshots,
                                    scr.id,
                                  ]);
                                }
                                setFieldValue(
                                  field.name,
                                  field.value?.filter((x) => x !== scr)
                                );
                              }}
                            />
                          </RelativeBox>
                        ))}
                      </ScreenshotsContainer>
                    </FlexWrapper>
                  )}
                </Field>
                <Field id="preview" name="preview">
                  {({ field }) => (
                    <FlexWrapper>
                      <AppReviewDragNDropWrapper>
                        <InputFile
                          inputId={field.name}
                          multiple={false}
                          isMulti
                          value={field.value}
                          accept="video/mp4"
                          onChange={(v) => {
                            setFieldValue(field.name, v?.[0]);
                          }}
                          preview={
                            !!field.value && (
                              <MediaFilePreview
                                file={field.value}
                                handleDurationChange={(durInSecs) => {
                                  setFieldValue("previewLength", durInSecs);
                                }}
                              />
                            )
                          }
                          error={touched["previewLength"] && errors["previewLength"]}
                        >
                          {STRINGS.upload}
                        </InputFile>
                        {!!field.value && (
                          <IconDeleteIcon
                            onClick={() => {
                              setFieldValue(field.name, undefined);
                              setFieldValue("previewLength", 11);
                            }}
                          />
                        )}
                      </AppReviewDragNDropWrapper>
                      <AppReviewWrapper>
                        <FieldName>{STRINGS.app_preview}</FieldName>
                        <FieldNote>{lineByLine(STRINGS.app_preview_note)}</FieldNote>
                      </AppReviewWrapper>
                    </FlexWrapper>
                  )}
                </Field>
              </CustomFormSection>
            </FormContentWrapper>
            <FixedButtonFooter>
              <RightMostWrapper>
                <CustomButton
                  type="submit"
                  backgroundColor={COLORS.secondary.ncs}
                  width="12rem"
                  height="4rem"
                  capitalize
                  onClick={scrollToFirstError}
                >
                  {STRINGS.save}
                </CustomButton>
              </RightMostWrapper>
            </FixedButtonFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AppTabInfo;
