import React from "react";
import { CardLogo, CardMain, CardTitle, CardWrapper } from "./components";

const NoDataCard = ({ title, icon, children, margin, iconStyle, ...rest }) => {
  return (
    <CardWrapper {...rest} margin={margin}>
      <CardMain>
        <CardTitle>{title}</CardTitle>
        {children}
      </CardMain>
      <CardLogo logo={icon} style={iconStyle} />
    </CardWrapper>
  );
};

export default NoDataCard;
