import { useCallback, useState } from "react";
import { useAlert } from "react-alert";
import { useMutation, useQuery } from "react-query";
import { memberEdit, memberInvite, memberList, memberRemove } from "../APIs";
import STRINGS from "../constants/strings";

const useTeam = () => {
  const [userAdding, setUserAdding] = useState(false);
  const [editMember, setEditMember] = useState(null);
  const [deleteMemberId, setDeleteMemberId] = useState(false);

  const alert = useAlert();

  const {
    data: membersData,
    isLoading: membersLoading,
    refetch: membersRefetch,
  } = useQuery("members", memberList);

  const { mutate: memberInviteMutation } = useMutation(
    "members-invite",
    memberInvite,
    {
      onSuccess: () => {
        alert.success("Invitation sent");
        membersRefetch();
      },
      onError: (err) => {
        alert.error(STRINGS[err?.response?.data?.message] || err.message);
      },
    }
  );

  const { mutate: memberRemoveMutation } = useMutation(
    "members-remove",
    memberRemove,
    {
      onSuccess: () => {
        alert.success("Member removed");
        membersRefetch();
      },
      onError: (err) => {
        alert.error(STRINGS[err?.response?.data?.message] || err.message);
      },
    }
  );

  const { mutate: memberEditMutation } = useMutation(
    "members-edit",
    memberEdit,
    {
      onSuccess: () => {
        alert.success("Member updated");
        membersRefetch();
      },
      onError: (err) => {
        alert.error(STRINGS[err?.response?.data?.message] || err.message);
      },
    }
  );

  const handleMemberInvite = useCallback(
    (vars) => {
      const applicationIds = Object.keys(vars.applicationIds).reduce(
        (acc, curr) => {
          if (vars.applicationIds[curr]) {
            acc.push(curr);
          }

          return acc;
        },
        []
      );

      memberInviteMutation({
        ...vars,
        role: vars.role.value,
        applicationIds,
      });

      setUserAdding(false);
    },
    [memberInviteMutation]
  );

  const handleMemberRemove = useCallback(() => {
    if (!deleteMemberId) {
      alert.error("Select a member to remove");
      return;
    }

    const member = membersData.find((m) => m.id === deleteMemberId);
    memberRemoveMutation({
      memberId: deleteMemberId,
      applicationIds: member?.applications?.map((app) => app.id),
    });

    setDeleteMemberId(false);
  }, [deleteMemberId, membersData, memberRemoveMutation, alert]);

  const handleMemberEdit = useCallback(
    (vars) => {
      const applicationIds = Object.keys(vars.applicationIds).reduce(
        (acc, curr) => {
          if (vars.applicationIds[curr]) {
            acc.push(curr);
          }

          return acc;
        },
        []
      );

      memberEditMutation({
        memberId: vars.memberId,
        name: vars.name,
        applicationIds,
        role: vars.role.value,
      });

      setEditMember(null);
    },
    [memberEditMutation]
  );

  return {
    membersData,
    membersLoading,
    handleMemberInvite,
    userAdding,
    setUserAdding,
    handleMemberRemove,
    deleteMemberId,
    setDeleteMemberId,
    editMember,
    setEditMember,
    handleMemberEdit,
  };
};

export default useTeam;
