import styled from "styled-components";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

export const ModalHeader = styled.div`
  width: 100%;
  padding: 3.2rem 4rem 3.2rem 5.4rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: ${COLORS.secondary.water_gray};
  box-shadow: 0px 5px 10px 0px #0093c60a;
  font-family: Ubuntu-Regular;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  align-items: center;
`;

export const ModalCloseX = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-image: url(${ICONS.CloseGray});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: ${COLORS.secondary.water_gray};
  box-shadow: 0px -12px 10px 0px #0093c60a;
  align-items: center;
`;
