import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../components/button/CustomButton";
import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import COLORS from "../../constants/colors";
import STRINGS from "../../constants/strings";
import URLS from "../../constants/urls";
import { useAddMember } from "../../hooks/addMember";
import { SubTitle, Title, VerifyEmailImg } from "../sign-in/components";

const AddMemberPage = () => {
  const { error, isLoading } = useAddMember();
  return (
    <OutlineNonAuthorised>
      <VerifyEmailImg />
      <Title style={{ margin: "1.6rem 0" }}>Invitation</Title>
      {error ? (
        <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
          The token is invalid or expired
        </SubTitle>
      ) : isLoading ? (
        <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
          {STRINGS.loading}...
        </SubTitle>
      ) : (
        <>
          <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
            You have successfully joined an app
          </SubTitle>
          <Link to={URLS.apps}>
            <CustomButton backgroundColor={COLORS.secondary.ncs}>
              Go to your apps
            </CustomButton>
          </Link>
        </>
      )}
    </OutlineNonAuthorised>
  );
};

export default AddMemberPage;
