import React, { useMemo } from "react";

import OutlineAuthorised from "../../components/outline-authorised/OutlineAuthorised";
import AppsDropdown from "../../components/apps-dropdown/AppsDropdown";
import CustomTabs from "../../components/custom-tabs/CustomTabs";

import STRINGS from "../../constants/strings";
import URLS from "../../constants/urls";

import AppTabDashboard from "./AppTabDashboard";
import AppTabRealeses from "./AppTabRealeses";
import AppTabInfo from "./AppTabInfo";

import useApp from "../../hooks/app";
import Loader from "../../components/loader/Loader";

const AppPage = () => {
  const { coachApps, coachAppsLoading, appById, appByIdLoading, appByIdRefetch } = useApp();

  const isAppInfoValid = useMemo(() => {
    if (!appById?.name) {
      return false;
    }
    if (!appById?.shortDescription) {
      return false;
    }
    if (!appById?.description) {
      return false;
    }
    if (!appById?.icon) {
      return false;
    }
    if (!appById?.screenshots?.length || appById?.screenshots?.length < 2) {
      return false;
    }
    return true;
  }, [appById]);

  const tabs = useMemo(
    () => [
      {
        url: URLS.app_page_dashboard,
        name: STRINGS.dashboard,
        content: <AppTabDashboard currApp={appById} isAppInfoValid={isAppInfoValid} />,
      },
      {
        url: URLS.app_page_info,
        name: STRINGS.info,
        content: <AppTabInfo currApp={appById} refetchApp={appByIdRefetch} />,
      },
      {
        url: URLS.app_page_releases,
        name: STRINGS.releases,
        content: (
          <AppTabRealeses
            currApp={appById}
            refetchApp={appByIdRefetch}
            isAppInfoValid={isAppInfoValid}
          />
        ),
      },
    ],
    [appById, appByIdRefetch, isAppInfoValid]
  );

  return (
    <OutlineAuthorised pageTitle={STRINGS.my_apps}>
      {appByIdLoading || coachAppsLoading ? (
        <Loader />
      ) : (
        <>
          <AppsDropdown apps={coachApps} currentApp={appById} refetchApp={appByIdRefetch} />
          <CustomTabs
            tabs={tabs}
            categoryTabKey={STRINGS.url_apptab}
            urlOutline={URLS.app_page_tab}
            defaultCategory={STRINGS.dashboard}
          />
        </>
      )}
    </OutlineAuthorised>
  );
};

export default AppPage;
