import React, { useContext } from "react";

import ICONS from "../../constants/icons";
import URLS from "../../constants/urls";

import { ResponsiveContext } from "../../contexts/ResponsiveContext";

import { HeaderBurger } from "../outline-authorised/components";
import {
  HeaderLogo,
  HeaderWrapper,
} from "../outline-non-authorised/components";
import {
  SidebarMenuBack,
  SidebarMenuContainer,
  SidebarMenuContent,
} from "./components";

const ResponsiveSidebar = ({ children, isOpen }) => {
  const { toggleRespSidebar } = useContext(ResponsiveContext);

  return (
    <SidebarMenuBack isOpen={isOpen} onClick={toggleRespSidebar}>
      <SidebarMenuContainer
        onClick={(e) => e.stopPropagation()}
        isOpen={isOpen}
      >
        <HeaderWrapper>
          <HeaderBurger
            style={{ marginRight: "1rem" }}
            onClick={toggleRespSidebar}
          />
          <a target="_blank" href={URLS.robotics_page} rel="noreferrer">
            <HeaderLogo src={ICONS.LogoRoundBackground} />
          </a>
        </HeaderWrapper>
        <SidebarMenuContent>{children}</SidebarMenuContent>
      </SidebarMenuContainer>
    </SidebarMenuBack>
  );
};

export default ResponsiveSidebar;
