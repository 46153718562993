import styled from "styled-components";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const WholeWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${ICONS.OutlineBack});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 50% auto;
  background-position-x: ${({ doubleBackground }) =>
    doubleBackground ? "-48%" : "center"};
  ${({ theme }) => theme.beforeDesktop`
    background-size: ${({ doubleBackground }) =>
      doubleBackground ? "64%" : "88%"} auto;
    background-position-x: ${({ doubleBackground }) =>
      doubleBackground ? "-64%" : "center"};
  `}
  @media (max-height: 900px) {
    background-image: none;
  }
`;

const AdditionalBackgroundUrl = ({ doubleBackground }) =>
  doubleBackground ? ICONS.OutlineBack : "none";

export const AdditionalBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${AdditionalBackgroundUrl});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 50% auto;
  background-position-x: 148%;
  ${({ theme }) => theme.beforeDesktop`
    background-size: 64% auto;
    background-position-x: 164%;
  `};
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 9.6rem;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  padding: 0 3.2rem;
  ${({ theme }) => theme.beforeDesktop`
    padding: 0 1.6rem;
    height: 6.4rem;
  `}
`;

export const HeaderLogo = styled.img`
  height: 3.6rem;
  width: auto;
`;

export const HeaderNavigation = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderNavigationTab = styled.div`
  font-family: ${FONTS.UbuntuLight};
  line-height: 1.8rem;
  margin-right: 2.6rem;
  text-transform: capitalize;
  ${({ theme }) => theme.beforeDesktop`
    font-family: ${FONTS.UbuntuRegular};
    margin: 2rem 0;
  `}
`;

export const HeaderTail = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;

export const MainContainer = styled.div`
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.beforeDesktop`
    justify-content: center;
    flex-grow: 1;
    width: 100vw;
    padding-bottom: 12rem;
  `}
`;
