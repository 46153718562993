import { Link } from "react-router-dom";
import React, { useMemo } from "react";

import STRINGS from "../../constants/strings";

import { useUtils } from "../../hooks";
import { CardKind, CardStatusLogo, CardStatusValue } from "../app-card/components";
import {
  AppCard,
  AppCardEdit,
  AppCardEditIcon,
  AppContainer,
  AppLogo,
  AppNameKind,
  AppStatusRow,
} from "./components";

const DashboardAppCard = ({ name, icon, id, category, releases }) => {
  const { statusByReleases } = useUtils();
  const [status, statusString] = useMemo(
    () => statusByReleases(releases),
    [statusByReleases, releases]
  );

  return (
    <Link to={`apps/${id}/info`}>
      <AppContainer>
        <AppCard>
          <AppCardEdit className="show-on-hover">
            <AppCardEditIcon />
          </AppCardEdit>
          <AppLogo appLogo={icon?.publicUrl} />
          <AppStatusRow>
            {STRINGS.status}:{statusString && <CardStatusLogo status={status} />}
            <CardStatusValue status={status}>{statusString || "  N/A"}</CardStatusValue>
          </AppStatusRow>
        </AppCard>
        <AppNameKind>
          {name?.length < 15 ? name : `${name?.slice(0, 12)}...`}
          <CardKind>{category?.name}</CardKind>
        </AppNameKind>
      </AppContainer>
    </Link>
  );
};

export default DashboardAppCard;
