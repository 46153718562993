import React, { useCallback, useContext, useState } from "react";
import { Field, Form, Formik } from "formik";
import GoogleLogin from "react-google-login";
import { useAlert } from "react-alert";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import { FormContentWrapper } from "../../components/general/components";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import URLS from "../../constants/urls";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useHistoryCustom, useUser } from "../../hooks";
import { SignupEmailSchema } from "../../schemas";

import {
  ChoiceDivider,
  GeneralError,
  PageLoaderBackground,
  SubTitle,
  Title,
} from "./components";
import Loader from "../../components/loader/Loader";

const SignUp = () => {
  const { history } = useHistoryCustom();
  const { userCheckEmail, userCheckEmailLoading } = useUser();
  const { userGoogleAuth, userLoading } = useContext(CurrentUserContext);
  const goToDashboard = useCallback(
    () => history.push(URLS.dashboard),
    [history]
  );
  const [googleLoading, setGoogleLoading] = useState(false);
  const alert = useAlert();
  console.log("google id ---> ", process.env.REACT_APP_GOOGLE_AUTH_ID);
  return (
    <OutlineNonAuthorised doubleBackground>
      {googleLoading && (
        <PageLoaderBackground>
          <Loader noText />
        </PageLoaderBackground>
      )}
      <Title>{STRINGS.build_robotic_apps}</Title>
      <SubTitle>{STRINGS.with_raya_you_can_start}</SubTitle>
      <Formik
        onSubmit={userCheckEmail}
        initialValues={{ email: "" }}
        validationSchema={SignupEmailSchema}
      >
        {({ setFieldValue, errors, setErrors, touched, isSubmitting }) => (
          <Form>
            <FormContentWrapper
              style={{
                width: "38rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              disabled={isSubmitting || userCheckEmailLoading || userLoading}
            >
              <Field id="email" name="email">
                {({ field }) => (
                  <InputText
                    height="5.6rem"
                    margin="1.8rem 0"
                    borderColor={COLORS.secondary.water_gray}
                    placeholder={STRINGS.please_type_your_email}
                    onChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.Email}
                  />
                )}
              </Field>
              <CustomButton
                type="submit"
                backgroundColor={COLORS.secondary.ncs}
                padding="0 7.2rem"
                height="5.8rem"
                margin="2rem 0"
                width="28.6rem"
                shadow
              >
                {STRINGS.create_an_account}
              </CustomButton>
              <ChoiceDivider>{STRINGS.or}</ChoiceDivider>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_AUTH_ID}
                buttonText="Login"
                onRequest={() => setGoogleLoading(true)}
                onSuccess={({ profileObj, accessToken }) => {
                  console.log("Google Success --> ", profileObj);
                  const { googleId, email, givenName, familyName, imageUrl } =
                    profileObj;
                  userGoogleAuth(
                    {
                      accessToken,
                      googleId,
                      email,
                      firstName: givenName,
                      lastName: familyName,
                      photo: imageUrl,
                    },
                    { goToDashboard, setErrors }
                  );
                }}
                onFailure={(err) => {
                  alert.error("Something went wrong !");
                  console.log("Google Err", err);
                  setGoogleLoading(false);
                }}
                render={(renderProps) => (
                  <CustomButton
                    borderColor={COLORS.secondary.ncs}
                    textColor={COLORS.primary.oxford}
                    height="5.8rem"
                    margin="2rem 0"
                    width="28.6rem"
                    padding="0"
                    shadow
                    type="button"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    {STRINGS.signup_with_gmail}
                  </CustomButton>
                )}
              />
              {errors?.general && (
                <GeneralError className="error">
                  {`* ${
                    STRINGS[errors?.general] || STRINGS.something_went_wrong
                  }`}
                </GeneralError>
              )}
            </FormContentWrapper>
          </Form>
        )}
      </Formik>
    </OutlineNonAuthorised>
  );
};

export default SignUp;
