import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";

export const WholeWrapper = styled.div`
  width: 100%;
  padding: 0 4.4rem 4.4rem;
  background: #fff;
  border-radius: 1.6rem;
  border: 1px solid #e5eae7;
  box-shadow: 0px 7px 25px 0px #0093c617;
  display: flex;
  align-items: flex-end;
`;

export const MainSection = styled.div`
  width: 100%;
  padding-bottom: 5.6rem;
`;

export const SectionTitle = styled.div`
  font-family: ${FONTS.UbuntuBold};
  font-size: 2rem;
  line-height: 3.4rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.2rem;
  margin-top: 4.2rem;
`;

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  align-items: flex-end;
  width: 100%;
  padding-right: 4rem;
`;

export const DragAndDropWrapper = styled.div`
  width: 36.2rem;
  height: 17rem;
  opacity: ${({ hasOpacity }) => (hasOpacity ? 0.4 : 1)};
`;

export const GeneralError = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  min-height: 2rem;
  color: ${COLORS.secondary.rosso};
  font-family: ${FONTS.UbuntuBold};
  margin-top: 4rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  & * {
    pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  }
`;

export const OrDivider = styled.div`
  position: relative;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin: 0 4.8rem;
  text-align: center;
  margin-top: 8.8rem;
  &::before {
    content: "";
    position: absolute;
    left: -3rem;
    width: 2rem;
    height: 2px;
    background: ${COLORS.secondary.aero};
  }
  &::after {
    content: "";
    position: absolute;
    right: -3rem;
    width: 2rem;
    height: 2px;
    background: ${COLORS.secondary.aero};
  }
`;
