const COLORS = {
  primary: {
    oxford: "#08183f",
    black: "#000",
    white: "#fff",
  },
  secondary: {
    ncs: "#0094c6",
    ncsDark: "#026C8E",
    aero: "#caf0f8",
    emerald: "#33ca7f",
    rosso: "#d00000",
    cambridge: "#a0baa4",
    morning: "#7e9586",
    feldgrau: "#7b81a3",
    water_gray: "#e5eae7",
    water_green: "#cbd5cf",
    main_bg: "#faf9f9",
    grayscale: "#14142b",
    off_white: "#fcfcfc",
  },
};

export default COLORS;
