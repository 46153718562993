import styled from "styled-components";
import COLORS from "../../constants/colors";

export const AppDropdownWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 1.6rem;
  margin-bottom: 2rem;
  & > .card-wrapper {
    cursor: pointer;
    &:hover {
      background: ${COLORS.secondary.aero}4d;
    }
  }
`;

export const CurrentAppWrapper = styled.div`
  position: relative;
  & > .card-wrapper {
    cursor: pointer;
    background: ${({ isOpen }) => (isOpen ? `${COLORS.secondary.aero}4d` : "transparent")};
    &:hover {
      background: ${COLORS.secondary.aero}4d;
      & > .card-delete {
        display: flex;
      }
    }
  }
`;
