import React from "react";
import VerifyLogo from "../../assets/VerifyLogo.svg";
import STRINGS from "../../constants/strings";
import {
  VerifyTitle,
  VerifyImage,
  WelcomeWrapper,
  VerifySubText,
  VerifyPageWrapper,
  GoBackText,
} from "./Components";

const VerifyPage = () => {
  return (
    <VerifyPageWrapper>
      <VerifyImage src={VerifyLogo} />
      <WelcomeWrapper>
        <VerifyTitle>{STRINGS.welcome_aboard}</VerifyTitle>
        <VerifySubText>{STRINGS.unity_binding_success}</VerifySubText>
        <GoBackText>{STRINGS.go_back_to_simulator}</GoBackText>
      </WelcomeWrapper>
    </VerifyPageWrapper>
  );
};

export default VerifyPage;
