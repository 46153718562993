import styled from "styled-components";
import COLORS from "../../constants/colors";

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
`;

export const InputNote = styled.div`
  width: 30.6rem;
  font-family: Ubuntu-Medium;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.01rem;
  color: ${COLORS.secondary.morning};
  margin-left: 3.2rem;
`;

export const BoldText = styled.span`
  font-family: Ubuntu-Medium;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.04rem;
  margin-right: 2.4rem;
`;

export const PageNote = styled.div`
  width: 42.4rem;
  font-family: Ubuntu-Medium;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${COLORS.secondary.morning};
  & > a {
    cursor: pointer;
    font-family: Ubuntu-Medium;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-decoration: underline;
  }
`;

export const PageFooter = styled.div`
  width: 84.8rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2rem 0;
`;
