import React from "react";
import {
  CustomTableWrapper,
  CustomTH,
  CustomTHead,
  CustomTRWrapper,
  CustomTR,
  CustomTBody,
  CustomTD,
  CustomTDIcon,
} from "./components";
import { LoadingGIF } from "../../pages/app-page/components";
import STRINGS from "../../constants/strings";
import VerticalDotsDropdown from "../vertical-dots-dropdown/VerticalDotsDropdown";
import ICONS from "../../constants/icons";

const Actions = ({ openDeleteMemberModal, openEditMemberModal }) => {
  return (
    <>
      <VerticalDotsDropdown
        actions={[
          {
            name: STRINGS.edit,
            icon: ICONS.GrayEdit,
            onClick: openEditMemberModal,
          },
          {
            name: STRINGS.delete,
            icon: ICONS.GrayTrash,
            onClick: openDeleteMemberModal,
          },
        ]}
      />
    </>
  );
};

const CustomTable = ({
  headers,
  rows,
  order,
  grids,
  iconGetters,
  textGetters,
  loading,
  setDeleteMemberId,
  setEditMember,
}) => {
  return (
    <CustomTableWrapper>
      <CustomTHead grids={grids}>
        {headers?.map((header, i) => (
          <CustomTH key={i}>{header}</CustomTH>
        ))}
      </CustomTHead>
      <CustomTBody>
        {loading ? (
          <CustomTRWrapper>
            <CustomTR style={{ justifyContent: "center" }}>
              <LoadingGIF />
            </CustomTR>
          </CustomTRWrapper>
        ) : rows?.length ? (
          rows?.map((row, i) => (
            <CustomTRWrapper key={i}>
              <CustomTR grids={grids}>
                {order?.map((cellName, i) => (
                  <CustomTD key={i}>
                    {typeof iconGetters?.[cellName] === "function" &&
                      iconGetters?.[cellName](row) && (
                        <CustomTDIcon icon={iconGetters?.[cellName](row)} />
                      )}
                    {typeof textGetters?.[cellName] === "function"
                      ? textGetters?.[cellName](row)
                      : row?.[cellName]}
                    {cellName === "access" &&
                      row.applications.map((app) => app.name).join(", ")}

                    {cellName === "actions" && (
                      <Actions
                        openDeleteMemberModal={() => setDeleteMemberId(row.id)}
                        openEditMemberModal={() => setEditMember(row)}
                      />
                    )}
                  </CustomTD>
                ))}
              </CustomTR>
            </CustomTRWrapper>
          ))
        ) : (
          <CustomTRWrapper>
            <CustomTR grids={grids}>You don't have any members yet...</CustomTR>
          </CustomTRWrapper>
        )}
      </CustomTBody>
    </CustomTableWrapper>
  );
};

export default CustomTable;
