import React, { useCallback, useEffect, useRef, useState } from "react";
import { ActionsDropdown, ActionsDropdownMenu, ActionsDropdownAction, ActionsDropdownActionIcon } from "./components";

const VerticalDotsDropdown = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const reff = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (reff.current && !reff.current?.contains(event.target) && !event?.target?.className?.includes("inner")) {
        setIsOpen(false);
      }
    },
    [reff]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return (
    <ActionsDropdown onClick={() => setIsOpen((curr) => !curr)} ref={reff}>
      {isOpen && (
        <ActionsDropdownMenu>
          {actions?.map((act, i) => (
            <ActionsDropdownAction onClick={act.onClick} key={i}>
              <ActionsDropdownActionIcon icon={act.icon} />
              {act.name}
            </ActionsDropdownAction>
          ))}
        </ActionsDropdownMenu>
      )}
    </ActionsDropdown>
  );
};

export default VerticalDotsDropdown;
