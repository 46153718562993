import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useHistoryCustom = () => {
  const history = useHistory();
  const location = useLocation();

  const generateQs = () => {
    const res = {};
    const boolVals = {
      true: true,
      false: false,
    };
    const parts = location.search?.replace("?", "")?.split("&");
    parts.map((part) => {
      const [key, val] = part?.split("=");
      res[key] = boolVals[val] || val;
      return 1;
    });
    return res;
  };

  let qs = useMemo(generateQs, [location]);

  const SetQs = (args, eraseOthers) => {
    const currQs = { ...(eraseOthers ? [] : qs), ...args };
    const qsArr = Object.keys(currQs)
      ?.map((k) => k && `${k}=${currQs[k]}`)
      .filter((x) => !!x);
    history.push({
      pathname: history?.location?.pathname,
      search: `?${qsArr.join("&")}`,
    });
  };

  const findCategoryIndex = (urlOutline, key) => {
    const resArr = urlOutline?.split("/").map((tab) => (tab === `:${key}` && 1) || 0);
    return resArr.indexOf(1);
  };

  const setCategory = (urlOutline, key, value) => {
    const urlParts = history.location?.pathname?.split("?")?.[0]?.split("/");

    const index = findCategoryIndex(urlOutline, key);
    urlParts[index] = value;

    history.push(urlParts.join("/"));
  };

  const getCategory = (urlOutline, key, pathname) => {
    const urlParts = (pathname || history.location?.pathname)?.split("?")?.[0]?.split("/");

    const index = findCategoryIndex(urlOutline, key);
    return urlParts[index];
  };

  const createUrl = (urlOutline, varCats) => {
    let newUrl = urlOutline;

    varCats.map((cat) => (newUrl = newUrl.replace(`:${cat.key}`, cat.val)));
    return newUrl;
  };

  return { history, setCategory, getCategory, createUrl, qs, SetQs };
};

export default useHistoryCustom;
