import React from "react";

import { InfoModalTypes } from "../../constants/types";
import STRINGS from "../../constants/strings";

import OutlineAuthorised from "../../components/outline-authorised/OutlineAuthorised";
import InviteUsersModal from "../../components/invite-users-modal/InviteUsersModal";
import TableOutline from "../../components/table-outline/TableOutline";
import CustomTable from "../../components/custom-table/CustomTable";
import InfoModal from "../../components/info-modal/InfoModal";
import useTeam from "../../hooks/team";

const tableOptions = {
  headers: [
    STRINGS.teams_th_email,
    STRINGS.teams_th_name,
    STRINGS.teams_th_role,
    STRINGS.teams_th_status,
    STRINGS.teams_th_access,
    "",
  ],
  order: ["email", "name", "role", "status", "access", "actions"],
  grids: "25% 20% 20% 15% 15% 5%",
};

const Teams = () => {
  const {
    membersData,
    membersLoading,
    handleMemberInvite,
    userAdding,
    setUserAdding,
    handleMemberRemove,
    deleteMemberId,
    setDeleteMemberId,
    editMember,
    setEditMember,
    handleMemberEdit,
  } = useTeam();

  return (
    <OutlineAuthorised pageTitle={STRINGS.teams_n_testers}>
      {userAdding && (
        <InviteUsersModal
          isOpen={userAdding}
          onRequestClose={() => setUserAdding(false)}
          handleSubmit={handleMemberInvite}
        />
      )}
      {editMember && (
        <InviteUsersModal
          isOpen={!!editMember}
          onRequestClose={() => setEditMember(null)}
          handleSubmit={handleMemberEdit}
          data={editMember}
        />
      )}
      {deleteMemberId && (
        <InfoModal
          isOpen={true}
          type={InfoModalTypes.WARNING}
          onRequestClose={() => setDeleteMemberId(null)}
          title={STRINGS.warning}
          subTitle={STRINGS.you_are_about_to_delete_user}
          content={STRINGS.are_you_sure_delete_user}
          cancelText={STRINGS.cancel}
          submitText={STRINGS.yes_delete}
          submitAction={handleMemberRemove}
          cancelAction={() => setDeleteMemberId(null)}
        />
      )}
      <TableOutline
        title={STRINGS.teams_n_testers}
        subTitle="Explenation on what is teams and testerspage is and what you see on this page and some more bla bla copy."
        actionName={STRINGS.invite_new_user}
        actionAction={() => setUserAdding(true)}
      >
        <CustomTable
          {...tableOptions}
          rows={membersData}
          loading={membersLoading}
          setDeleteMemberId={setDeleteMemberId}
          setEditMember={setEditMember}
        />
      </TableOutline>
    </OutlineAuthorised>
  );
};

export default Teams;
