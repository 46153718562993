import * as Yup from "yup";
import STRINGS from "../constants/strings";

const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "File", "Icon"];

const MIN_PASS_LENGTH = 6;

const firstName = Yup.string().trim().required(STRINGS.required).min(2, STRINGS.too_short);
const lastName = Yup.string().trim().required(STRINGS.required).min(2, STRINGS.too_short);
const email = Yup.string().trim().required(STRINGS.required).email(STRINGS.invalid_email_format);
const password = Yup.string().trim().required(STRINGS.required);
const repassword = Yup.string()
  .required(STRINGS.required)
  .oneOf([Yup.ref(STRINGS.password), null], STRINGS.no_match);
const address = Yup.string().required(STRINGS.required);
const nickname = Yup.string().required(STRINGS.required).min(2, STRINGS.too_short);
const website = Yup.string().matches(
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  "Enter correct url!"
);
const icon = Yup.mixed().test(
  "fileType",
  "Invalid format",
  (value) => !value || SUPPORTED_FORMATS.includes(value?.type)
);
const about = Yup.string().required(STRINGS.required).min(20, STRINGS.too_short);
const phoneNumber = Yup.number().required(STRINGS.required);

export const SetPasswordSchema = Yup.object().shape({
  password,
  repassword,
});

export const SignupSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password: password.min(MIN_PASS_LENGTH, STRINGS.too_short),
});

export const EditUserSchema = Yup.object().shape({
  firstName,
  lastName,
  address,
  nickname,
  website,
  icon,
  about,
  phoneNumber,
});

export const SigninSchema = Yup.object().shape({
  email,
  password,
});

export const SignupEmailSchema = Yup.object().shape({
  email,
});
