import styled from "styled-components";
import COLORS from "../../constants/colors";

export const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${COLORS.secondary.water_gray};
  background-image: url(${({ media }) => media});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: inherit;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
  border: 1px solid ${COLORS.secondary.water_gray};
`;
