import { Field, Form, Formik } from "formik";
import React from "react";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import { FormContentWrapper } from "../../components/general/components";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

import { useUser } from "../../hooks";
import { SignupSchema } from "../../schemas";

import { Title, SubTitle } from "./components";

const SignUpPassword = () => {
  const { userSignUp, userSignUpLoading } = useUser();
  return (
    <OutlineNonAuthorised>
      <Title>{STRINGS.ray_a}</Title>
      <SubTitle>{STRINGS.ur_dev_platform}</SubTitle>
      <Formik
        onSubmit={userSignUp}
        initialValues={{
          email: localStorage.getItem("emailToRegister"),
          firstName: "",
          lastName: "",
          password: "",
        }}
        validationSchema={SignupSchema}
      >
        {({ setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            <FormContentWrapper
              style={{
                width: "36.8rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              disabled={isSubmitting || userSignUpLoading}
            >
              <Field id="firstName" name="firstName">
                {({ field }) => (
                  <InputText
                    type="text"
                    height="5.6rem"
                    margin="2rem 0 0"
                    placeholder={STRINGS.please_type_your_firstname}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.firstname}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={"https://icon-library.com/images/37473a569d.svg.svg"}
                  />
                )}
              </Field>
              <Field id="lastName" name="lastName">
                {({ field }) => (
                  <InputText
                    type="text"
                    height="5.6rem"
                    margin="2rem 0 0"
                    placeholder={STRINGS.please_type_your_lastname}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.lastname}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={
                      "https://www.pngkey.com/png/full/0-793_family-icon-png-family-movie-icon.png"
                    }
                  />
                )}
              </Field>
              <Field id="password" name="password">
                {({ field }) => (
                  <InputText
                    type="password"
                    height="5.6rem"
                    margin="2rem 0 0"
                    placeholder={STRINGS.please_type_your_password}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={field.name}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.PasswordEye}
                  />
                )}
              </Field>
              {/* <Field id="repassword" name="repassword">
                {({ field }) => (
                  <InputText
                    type="password"
                    height="5.6rem"
                    margin="2rem 0 0"
                    placeholder={STRINGS.please_type_your_password_again}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.repeat_password}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.PasswordEye}
                  />
                )}
              </Field> */}
              <CustomButton
                type="submit"
                backgroundColor={COLORS.secondary.ncs}
                width="100%"
                padding="0 7.2rem"
                height="5.8rem"
                margin="2rem 0 1.4rem"
                shadow
                capitalize
              >
                {STRINGS.next}
              </CustomButton>
            </FormContentWrapper>
          </Form>
        )}
      </Formik>
    </OutlineNonAuthorised>
  );
};

export default SignUpPassword;
