import * as Yup from "yup";
import STRINGS from "../constants/strings";

const SUPPORTED_FORMATS = [
  "zip",
  "application/octet-stream",
  "application/zip",
  "application/x-zip",
  "application/x-zip-compressed",
];

const version = Yup.string().trim().required(STRINGS.required);
const web_app = Yup.mixed().test(
  "fileType",
  "Invalid format",
  (value) => SUPPORTED_FORMATS.includes(value?.type) || !value
);

const frontendId = Yup.object().when("web_app", (value, schema) => {
  if (!!value) {
    return schema;
  } else {
    return schema.shape({
      value: Yup.string().required("req"),
    });
  }
});

const garys_code = Yup.mixed().when(["garys_code_empty", "backendId"], {
  is: (garys_code_empty, backendId) => !garys_code_empty && !backendId?.value,
  then: Yup.mixed()
    .required(STRINGS.required)
    .test("fileType", "Your Error Message", (v) => SUPPORTED_FORMATS.includes(v?.type)),
  otherwise: Yup.mixed().nullable(),
});

export const createAReleaseSchema = Yup.object().shape({
  version,
  web_app,
  garys_code,
  frontendId,
});
