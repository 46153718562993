import { useMutation, useQuery } from "react-query";
import { useAlert } from "react-alert";
import { useMemo } from "react";

import {
  createApp,
  getAppById,
  getCoachApps,
  updateApp,
  deleteApp,
} from "../APIs";

import { InfoModalTypes } from "../constants/types";
import STRINGS from "../constants/strings";
import URLS from "../constants/urls";

import useHistoryCustom from "./historyCustom";
import mixpanel from "mixpanel-browser";
import { TRACK_EVENTS } from "../constants/mixpanel";

const useApp = () => {
  const alert = useAlert();
  const { history, createUrl, getCategory } = useHistoryCustom();

  const AppId = useMemo(
    () => getCategory(URLS.app_page, STRINGS.url_id),
    [getCategory]
  );

  const {
    data: coachApps,
    isLoading: coachAppsLoading,
    refetch: refetchCoachApps,
  } = useQuery("coach-apps", getCoachApps);

  const {
    data: appById,
    isLoading: appByIdLoading,
    refetch: appByIdRefetch,
  } = useQuery(["app-by-id", { id: AppId }], getAppById, { enabled: !!AppId });

  const { mutate: addAppMutation } = useMutation("create-app", createApp);

  const addApp = async (vars) => {
    const { name, type } = vars;

    return addAppMutation( {name, categoryId: type?.value}, {
      onSuccess: ({ data }) => {
        mixpanel.track(TRACK_EVENTS.appCreated, {
          category: { id: type?.value, name: type?.name },
        });

        history.push(
          createUrl(URLS.app_page, [{ key: STRINGS.url_id, val: data.id }])
        );
      },
    });
  };

  const { mutate: deleteAppMutation } = useMutation("delete-app", deleteApp);

  const deleteApplication = (id) => {
    deleteAppMutation(id, {
      onSuccess: ({ data }) => {
        alert.success("Successfully Deleted !");
        refetchCoachApps();
      },
      onError: () => alert.error("Something Went Wrong !"),
    });
  };

  const { mutate: updateAppMutation, isLoading: isUpdating } = useMutation(
    "update-app",
    updateApp
  );

  const updateAppIcon = async ({ id, icon }, { refetchApp }) => {
    const data = new FormData();
    data.append("icon", icon);
    data.append("screenshots", []);
    updateAppMutation(
      { id, data },
      {
        onSuccess: () => {
          alert.success("Icon has been updated !");
          refetchApp();
        },
        onError: ({ err }) => {
          alert.error("Icon couldn't be updated !");
          console.log("\n\n---error---   \n\n", err);
        },
      }
    );
  };

  const updateCurrApp = async (
    vars,
    { setInfoModalOpen, hasUnsavedChanges }
  ) => {
    if (!hasUnsavedChanges()) {
      alert.info("No changes to save !");
      return;
    }

    const {
      id,
      name,
      shortDescription,
      description,
      icon,
      preview,
      screenshots,
      removeScreenshots,
    } = vars;

    const screenshotsToUpload = screenshots.filter((x) => !x.id);

    const data = new FormData();
    data.append("name", name);
    data.append("shortDescription", shortDescription);
    data.append("description", description);
    data.append("icon", icon);
    data.append("preview", preview);
    for (const scr of screenshotsToUpload) {
      data.append("screenshots", scr);
    }
    data.append("removeScreenshots", removeScreenshots.join(","));

    updateAppMutation(
      { id, data },
      {
        onSuccess: ({ data }) => {
          console.log("\n\n--success--   \n\n", data);
          setInfoModalOpen({
            type: InfoModalTypes.SUCCESS,
            isOpen: true,
            title: "Yeah!",
            subTitle: "Your app was successfully updated.",
            content:
              "Some subtitle to explain what will happen next in the process.",
          });
        },
        onError: ({ err }) => console.log("\n\n---error---   \n\n", err),
      }
    );
  };

  return {
    coachApps,
    coachAppsLoading,
    appById,
    appByIdLoading,
    appByIdRefetch,
    addApp,
    deleteApplication,
    updateCurrApp,
    isUpdating,
    updateAppIcon,
  };
};

export default useApp;
