import React, { useMemo } from "react";
import { useHistoryCustom } from "../../hooks";
import { TabsContainer, CustomTab } from "./components";

const CustomTabs = ({ tabs, categoryTabKey, urlOutline, defaultCategory }) => {
  const history = useHistoryCustom();

  const activeCategory = useMemo(
    () => history.getCategory(urlOutline, categoryTabKey) || defaultCategory || tabs?.[0]?.name,
    [categoryTabKey, urlOutline, history, defaultCategory, tabs]
  );

  const activeComponent = useMemo(() => tabs?.find((x) => x.name === activeCategory)?.content, [activeCategory, tabs]);

  return (
    <>
      <TabsContainer>
        {tabs?.map((tab, i) => (
          <CustomTab
            key={i}
            isActive={activeCategory === tab.name}
            onClick={() => history?.setCategory(urlOutline, categoryTabKey, tab?.categoryVal || tab?.name)}
          >
            {tab?.title || tab?.name}
          </CustomTab>
        ))}
      </TabsContainer>
      {activeComponent}
    </>
  );
};

export default CustomTabs;
