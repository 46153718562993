import { Field, Form, Formik } from "formik";
import React, { useState } from "react";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import { FormContentWrapper } from "../../components/general/components";
import InfoModal from "../../components/info-modal/InfoModal";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

import { useUser } from "../../hooks";
import { SignupEmailSchema } from "../../schemas";

import { ForgotPasswordImg, SubTitle, Title } from "./components";

const ForgotPassword = () => {
  const [formOpen, setFormOpen] = useState();
  const [sucessOpen, setSuccessOpen] = useState();

  const { userResetPassword, userResetPasswordLoading } = useUser();

  return (
    <OutlineNonAuthorised doubleBackground>
      <InfoModal {...sucessOpen} noOuterClickClosing okayText={"return to sign in"} />

      <ForgotPasswordImg />

      {formOpen ? (
        <>
          <Title style={{ margin: "1.6rem 0" }}>{STRINGS.forgot_your_password}?</Title>
          <Formik
            onSubmit={(v) => userResetPassword(v, { setSuccessOpen })}
            validationSchema={SignupEmailSchema}
            initialValues={{
              email: "",
            }}
          >
            {({ setFieldValue, errors, touched, isSubmitting }) => (
              <Form>
                <FormContentWrapper
                  style={{
                    width: "36.8rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  disabled={isSubmitting || userResetPasswordLoading}
                >
                  <Field id="email" name="email">
                    {({ field }) => (
                      <InputText
                        height="5.6rem"
                        margin="1.8rem 0"
                        borderColor={COLORS.secondary.water_gray}
                        placeholder={STRINGS.please_type_your_email}
                        onChange={(v) => setFieldValue(field.name, v)}
                        value={field.value}
                        error={touched[field.name] && errors[field.name]}
                        icon={ICONS.Email}
                      />
                    )}
                  </Field>
                  <CustomButton
                    type="submit"
                    backgroundColor={COLORS.secondary.ncs}
                    width="100%"
                    padding="0 7.2rem"
                    height="5.8rem"
                    margin="2rem 0 1.4rem"
                    shadow
                    capitalize
                  >
                    {STRINGS.recover_password}
                  </CustomButton>
                </FormContentWrapper>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Title style={{ margin: "1.6rem 0" }}>{STRINGS.forgot_your_password}?</Title>
          <SubTitle style={{ width: "32.2rem" }}>{STRINGS.thats_okay_happens}</SubTitle>
          <CustomButton
            backgroundColor={COLORS.primary.oxford}
            height="5.8rem"
            margin="3.2rem 0"
            width="28.6rem"
            padding="0"
            shadow
            onClick={() => setFormOpen(true)}
          >
            {STRINGS.reset_your_pass}
          </CustomButton>
        </>
      )}
    </OutlineNonAuthorised>
  );
};

export default ForgotPassword;
