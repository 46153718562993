import { Formik, Field, Form } from "formik";
import React, { useRef } from "react";

import ModalOutline from "../modal-outline/ModalOutline";
import InputDropdown from "../input/InputDropdown";
import InputOption from "../input/InputOption";
import InputText from "../input/InputText";
import { useApp, useForm } from "../../hooks";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

import {
  AppPermisions,
  ContentWrapper,
  AppPermisionsTitle,
} from "./components";
import { inviteMemberSchema } from "../../schemas/member";

const InviteUsersModal = ({
  isOpen = true,
  onRequestClose,
  handleSubmit,
  data,
}) => {
  const formRef = useRef();
  const { submitForm } = useForm(formRef);
  const { coachApps } = useApp();

  return (
    <ModalOutline
      isOpen={isOpen}
      heading={STRINGS.invite_new_users}
      onRequestClose={onRequestClose}
      buttonText={STRINGS.send_invitation}
      onSubmit={submitForm}
    >
      <ContentWrapper>
        <Formik
          innerRef={formRef}
          onSubmit={handleSubmit}
          initialValues={{
            memberId: data?.id,
            email: data?.email || "",
            name: data?.name || "",
            role: data?.role ? { label: data.role, value: data.role } : "",
            applicationIds: data
              ? data.applications?.reduce((acc, app) => {
                  acc[app.id] = true;
                  return acc;
                }, {})
              : [],
          }}
          validationSchema={inviteMemberSchema}
        >
          {({ setFieldValue, errors, touched }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <Field id="email" name="email">
                {({ field }) => (
                  <InputText
                    width="45.8rem"
                    height="5.6rem"
                    placeholder={STRINGS.please_type_your_email}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.email}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.Email}
                    borderColor={COLORS.secondary.water_green}
                    margin="1.8rem 0"
                    disabled={!!data}
                  />
                )}
              </Field>
              <Field id="name" name="name">
                {({ field }) => (
                  <InputText
                    width="45.8rem"
                    height="5.6rem"
                    backgroundColor={COLORS.secondary.off_white}
                    placeholder={STRINGS.name}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={field.name}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.UserProfile}
                    borderColor={COLORS.secondary.water_green}
                    margin="1.8rem 0"
                  />
                )}
              </Field>
              <Field id="role" name="role">
                {({ field }) => (
                  <InputDropdown
                    options={[
                      { label: "Developer", value: "Developer" },
                      { label: "Marketing", value: "Marketing" },
                    ]}
                    width="45.8rem"
                    height="5.6rem"
                    backgroundColor={COLORS.secondary.off_white}
                    placeholder={STRINGS.select_role}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.role}
                    value={field.value}
                    borderColor={COLORS.secondary.water_green}
                    margin="1.8rem 0"
                    padding="0 2rem 0 2.4rem"
                    error={touched[field.name] && errors[field.name]}
                  />
                )}
              </Field>
              <Field id="applicationIds" name="applicationIds">
                {({ field }) => (
                  <>
                    <AppPermisionsTitle>
                      {STRINGS.app_permissions}
                    </AppPermisionsTitle>
                    <AppPermisions>
                      {(coachApps || []).map((app, i) => (
                        <InputOption
                          key={i}
                          setIsChecked={() =>
                            setFieldValue(field.name, {
                              ...field.value,
                              [app.id]: !field.value?.[app.id],
                            })
                          }
                          isChecked={field.value?.[app.id]}
                          padding="0.8rem 0"
                          error={touched[field.name] && errors[field.name]}
                        >
                          {app.name}
                        </InputOption>
                      ))}
                    </AppPermisions>
                  </>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </ContentWrapper>
    </ModalOutline>
  );
};

export default InviteUsersModal;
