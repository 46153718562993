export const TRACK_EVENTS = {
  logIn: "log-in",
  appCreationStarted: "app creation started",
  appCreationCanceled: "app creation canceled",
  appCreated: "app created",
  knowledgeCenter: "knowledge center clicked",
  intercom: "intercom clicked",
  examplesShow: "show examples clicked",
  profileVisited: "profile visited",
  gmailSignup: "signed up with gmail",
  registrationStarted: "started registration flow",
  emailVerified: "verified email",
  detailsFilled: "filled details",
};

export const TRACK_VALUES = {
  [TRACK_EVENTS.appCreationStarted]: {
    apps: "apps",
    dashboard: "dashboard",
  },
  [TRACK_EVENTS.profileVisited]: {
    menu: "menu",
    dashboard: "dashboard",
  },
};
