import React, { createContext, useMemo, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

const BEFORE_DESKTOP_WIDTH = 1024;

export const ResponsiveContext = createContext(null);

export const ResponsiveProvider = ({ children }) => {
  const [isBeforeDesktop, setisBeforeDesktop] = useState(
    window.innerWidth <= BEFORE_DESKTOP_WIDTH
  );
  const [respSidebarOpen, setRespSidebarOpen] = useState(false);

  const toggleRespSidebar = useCallback(
    () => setRespSidebarOpen((curr) => !curr),
    [setRespSidebarOpen]
  );

  useEffect(() => {
    const resize = (e) =>
      setisBeforeDesktop(e.target.innerWidth <= BEFORE_DESKTOP_WIDTH);
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  });

  const contextValue = useMemo(
    () => ({
      isBeforeDesktop,
      respSidebarOpen,
      toggleRespSidebar,
    }),
    [isBeforeDesktop, respSidebarOpen, toggleRespSidebar]
  );

  return (
    <ResponsiveContext.Provider value={contextValue}>
      {children}
    </ResponsiveContext.Provider>
  );
};
