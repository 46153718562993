import STRINGS from "../constants/strings";
import { AppStatus } from "../constants/types";

const useUtils = () => {
  const lineByLine = (arg) => {
    return arg?.split("\n")?.map((x) => (
      <>
        {x}
        <br />
      </>
    ));
  };

  const cropImage = async (imgFile, crop, onload) => {
    const localURL = URL.createObjectURL(imgFile);
    const inputImage = new Image();

    inputImage.onload = () => {
      const outputImage = document.createElement("canvas");

      const originalWidth = inputImage.naturalWidth;
      const originalHeight = inputImage.naturalHeight;

      const croppedRatio = originalWidth < originalHeight ? crop.x / crop.y : crop.y / crop.x;

      const [croppedWidth, croppedHeight, ox, oy] =
        originalWidth / originalHeight > croppedRatio
          ? [
              originalHeight * croppedRatio,
              originalHeight,
              (originalWidth - originalHeight * croppedRatio) / 2,
              0,
            ]
          : [
              originalWidth,
              originalWidth / croppedRatio,
              0,
              (originalHeight - originalWidth / croppedRatio) / 2,
            ];

      outputImage.width = croppedWidth;
      outputImage.height = croppedHeight;

      const ctx = outputImage.getContext("2d");
      ctx.drawImage(
        inputImage,
        ox,
        oy,
        croppedWidth,
        croppedHeight,
        0,
        0,
        croppedWidth,
        croppedHeight
      );

      outputImage.toBlob((blob) => {
        const file = new File([blob], `screenshot/${performance?.now()}`, { type: blob.type });
        onload(file);
      });
    };

    inputImage.src = localURL;
  };

  const statusByReleases = (releases) => {
    const status = releases?.[0]?.status;
    const statusString =
      status === AppStatus.Submitted
        ? STRINGS.app_status_pending_review
        : status === AppStatus.IN_REVIEW
        ? STRINGS.app_status_in_review
        : status === AppStatus.LIVE
        ? STRINGS.app_status_live
        : status;
    return [status, statusString];
  };

  return { lineByLine, cropImage, statusByReleases };
};

export default useUtils;
