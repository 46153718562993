import React from "react";
import { Link } from "react-router-dom";

import { CardLogo, CardMain, CardMore, CardTitle, CardWrapper, LinkArrow } from "./components";

const DashboardLinkCard = ({
  title,
  icon,
  children,
  iconStyle,
  to,
  moreText,
  onLinkClick,
  ...rest
}) => {
  return (
    <Link to={to} target="_blank" rel="noreferrer" onClick={onLinkClick}>
      <CardWrapper {...rest}>
        <CardLogo logo={icon} style={iconStyle} />
        <CardMain>
          <CardTitle>{title}</CardTitle>
          {children}
          <CardMore>{moreText}</CardMore>
        </CardMain>
        <LinkArrow />
      </CardWrapper>
    </Link>
  );
};

export default DashboardLinkCard;
