import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3.6rem 7rem 4rem 4.2rem;
  align-items: flex-start;
  background: #fff;
  border-radius: ${({ borderRadius }) => borderRadius || "2.5rem"};
  margin: ${({ margin }) => margin || "0"};
  box-shadow: 0px 7px 25px 0px #0093c617;
`;

export const CardTitle = styled.div`
  font-family: ${FONTS.UbuntuBold};
  font-size: 2rem;
  letter-spacing: 0.5px;
  margin-bottom: 0.8rem;
  line-height: 2.8rem;
`;
export const CardMain = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${COLORS.secondary.morning};
  padding-right: 8rem;
`;

export const CardLogo = styled.div`
  min-width: 15rem;
  min-height: 15rem;
  background-image: url(${({ logo }) => logo || ICONS.AppDefaultLogo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
