import styled from "styled-components";

export const DashboardCardIcon = styled.div`
  width: 4rem;
  height: 4rem;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-size: 18px auto;
  background-position: center;
  border: 2px solid #33ca7f;
  border-radius: 50%;
`;

export const DashboardCardTitle = styled.div`
  font-family: Ubuntu-Bold;
  font-size: 1.6rem;
  line-height: 34px;
  letter-spacing: 0.05rem;
  text-align: center;
  color: #08183f;
  text-transform: capitalize;
  margin: 0.4rem;
  text-align: center;
`;

export const DashboardCardContent = styled.div`
  font-family: Ubuntu-Regular;
  font-size: 1.3rem;
  letter-spacing: 0;
  text-align: center;
  max-width: 30rem;
  color: #5c6f68;
`;

export const DashboardCardLink = styled.div`
  font-family: Ubuntu-Medium;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.075rem;
  text-decoration: underline;
  margin-top: 2rem;
  cursor: pointer;
`;
