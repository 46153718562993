import React, { useState } from "react";
import {
  InputTextWrapper,
  InputContainer,
  InputName,
  TextAreaInput,
  InputError,
} from "./components";

const InputTextArea = ({
  height,
  width,
  backgroundColor,
  borderColor,
  textColor,
  padding,
  margin,
  icon,
  name,
  placeholder,
  value,
  onChange,
  type = "text",
  error,
  errorStyle,
  noBorder,
  ...rest
}) => {
  const [isFocused, setISFocused] = useState(false);
  return (
    <InputTextWrapper
      height="fit-content"
      width={width}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      textColor={textColor}
      padding="0.4rem 2.4rem"
      margin={margin}
      isFocused={isFocused || !!value}
      borderFocus={isFocused && !noBorder}
      {...rest}
    >
      {error && (
        <InputError className="error" style={errorStyle}>
          * {error}
        </InputError>
      )}
      <InputContainer>
        <InputName className="when-focused_hidden">{name}</InputName>
        <TextAreaInput
          placeholder={isFocused ? "" : placeholder}
          height={height}
          maxWidth={`Calc(${width} - 4.8rem)`}
          value={value}
          onChange={(v) => onChange(v.target?.value)}
          type={type}
          onFocus={() => setISFocused(true)}
          onBlur={() => setISFocused(false)}
        />
      </InputContainer>
    </InputTextWrapper>
  );
};

export default InputTextArea;
