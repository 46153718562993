import styled from "styled-components";

export const ContentWrapper = styled.div`
  width: 100%;
  min-width: 56.2rem;
  padding: 2.2rem 2.4rem 1.2rem;
`;

export const AppPermisionsTitle = styled.div`
  width: 100%;
  font-family: Ubuntu-Medium;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding: 1.6rem 0 0.8rem 4rem;
  text-transform: capitalize;
`;

export const AppPermisions = styled.div`
  width: 100%;
  padding: 0 0 0 4rem;
  overflow-y: auto;
  max-height: 15.6rem;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 4px;
  }
`;
