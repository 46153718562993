import styled from "styled-components";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

export const DashboardHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  align-items: center;
  border: 1px solid ${COLORS.secondary.water_gray};
  box-shadow: 0px 7px 25px 0px #0093c617;
  border-radius: 2.4rem 2.4rem 0 0;
  padding: 3.2rem 4.4rem;
  margin-top: 0.1rem;
  color: ${COLORS.secondary.water_green};
  font-family: Ubuntu-Medium;
  font-size: 2.4rem;
  line-height: 3.4rem;
  letter-spacing: 0.5px;
  margin-right: 1.2rem;
  text-transform: capitalize;
`;

export const DashboardHeaderLabel = styled.div`
  font-family: Ubuntu-Medium;
  font-size: 2.4rem;
  line-height: 3.4rem;
  letter-spacing: 0.5px;
  margin-right: 1.2rem;
  text-transform: capitalize;
  color: ${({ opacity }) => (opacity ? COLORS.secondary.water_green : COLORS.primary.oxford)};
`;

export const DashboardValue = styled.div`
  font-family: Ubuntu-Bold;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: 0.5px;
  color: ${({ opacity }) => (opacity ? COLORS.secondary.water_green : COLORS.primary.oxford)};
`;

export const DashboardCardPlaceholder = styled.div`
  font-family: Ubuntu-Bold;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: 0.5px;
  color: ${COLORS.secondary.water_green};
`;

export const DashboardCardWrapper = styled.div`
  border: 1px solid #e5eae7;
  box-shadow: 0px 7px 25px 0px #0093c617;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  position: relative;
`;

export const DashboardCardInfo = styled.div`
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  left: 1.4rem;
  top: 1.6rem;
  background-image: url(${ICONS.Info});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  &:hover {
    & > * {
      display: block;
    }
  }
`;
export const DashboardCardInfoTooltip = styled.div`
  position: absolute;
  left: 1.4rem;
  top: 2.2rem;
  border: 1px solid #e5eae7;
  box-shadow: 0px 7px 25px 0px #0093c617;
  padding: 1.6rem;
  align-items: center;
  background: #fff;
  color: ${COLORS.secondary.water_green};
  font-family: Ubuntu-Regular;
  font-size: 1.4rem;
  line-height: 1.8rem;
  width: 26rem;
  border-radius: 0 1.6rem 1.6rem 1.6rem;
  display: none;
`;

export const DashboardCardIcon = styled.div`
  width: 2rem;
  height: 2rem;
  margin-bottom: 1.6rem;
  border-radius: 50%;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const DashboardCardLabel = styled.div`
  font-family: Ubuntu-Regular;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: 1.6rem;
  color: ${COLORS.secondary.morning};
  text-transform: capitalize;
`;
