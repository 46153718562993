import styled from "styled-components";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

export const AppsContainer = styled.div`
  width: 100%;
  padding: 0.4rem 5rem;
  box-shadow: 0px 5px 10px 0px #0093c60a inset;
  border-top: 1px solid ${({ theme }) => theme?.color?.secondary?.water_gray};
`;

export const DeleteAppButton = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  background: #fff;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 3.7rem;
  border-radius: 0.8rem;
  display: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.06);
  }
`;

export const DeleteAppButtonIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(${ICONS.DeleteBin});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const CenteredFlex = styled.div`
  width: 100%;
  padding: 10rem 5rem;
  box-shadow: 0px 5px 10px 0px #0093c60a inset;
  border-top: 1px solid ${({ theme }) => theme?.color?.secondary?.water_gray};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AppWrapper = styled.div`
  width: 100%;
  padding: 1.6rem 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .card-wrapper {
    cursor: pointer;
    &:hover {
      background: ${COLORS.secondary.aero}4d;
      & > .card-delete {
        display: flex;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: Calc(100% - 2rem);
    bottom: 0;
    left: 1rem;
    border-bottom: 1px solid;
    border-color: ${COLORS.secondary.water_gray};
  }
  &:last-child::after {
    content: none;
  }
`;
