import React from "react";

import {
  DashboardCardIcon,
  DashboardCardInfo,
  DashboardCardLabel,
  DashboardCardWrapper,
  DashboardValue,
  DashboardCardInfoTooltip,
  DashboardCardPlaceholder,
} from "./components";

const DashboardCard = ({ icon, label, value, info, placeholder }) => {
  return (
    <DashboardCardWrapper>
      <DashboardCardInfo>
        <DashboardCardInfoTooltip>{info}</DashboardCardInfoTooltip>
      </DashboardCardInfo>
      <DashboardCardIcon icon={icon} />
      {value ? (
        <DashboardValue>{value}</DashboardValue>
      ) : (
        <DashboardCardPlaceholder>{placeholder}</DashboardCardPlaceholder>
      )}
      <DashboardCardLabel>{label}</DashboardCardLabel>
    </DashboardCardWrapper>
  );
};

export default DashboardCard;
