import { useMutation } from "react-query";
import { campaignDocsClicked } from "../APIs";

const useActiveCampaign = () => {
  const { mutate: handleDocsClick } = useMutation(
    "docs-clicked",
    campaignDocsClicked
  );

  return { handleDocsClick };
};

export default useActiveCampaign;
