import React from "react";
import Select, { components } from "react-select";
import COLORS from "../../constants/colors";

import {
  InputDropdownWrapper,
  InputDropdownIndicator,
  InputError,
} from "./components";

const customStyles = ({ padding }) => ({
  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%",
    border: "none",
  }),
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "1.4rem",
    alignItems: "center",
    border: "none",
    outline: "none",
    background: "transparent",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "1.4rem",
    padding,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "1.4rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "1.4rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "1.4rem",
    display: "flex",
    alignItems: "center",
    minHeight: "100%",
    overflow: "show",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    background: state.isSelected ? `${COLORS.secondary.ncs}60` : "white",
    cursor: "pointer",
    fontSize: "1.4rem",
    padding: "1rem 2.4rem",
    color: COLORS.primary.oxford,
    "&:hover": {
      background: state.isSelected
        ? `${COLORS.secondary.ncs}70`
        : `${COLORS.secondary.ncs}20`,
    },
  }),
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <InputDropdownIndicator />
    </components.DropdownIndicator>
  );
};

const InputDropdown = ({
  height,
  width,
  backgroundColor,
  borderColor,
  textColor,
  padding,
  margin,
  icon,
  name,
  placeholder,
  options,
  value,
  onChange,
  isLoading = false,
  error,
  errorStyle,
  ...rest
}) => {
  return (
    <InputDropdownWrapper
      {...rest}
      height={height}
      width={width}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      textColor={textColor}
      margin={margin}
    >
      <Select
        isLoading={isLoading}
        options={options}
        classPrefix="react-select"
        removeSelected={false}
        hideSelectedOptions={false}
        styles={customStyles({ padding })}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        components={{
          ClearIndicator: false,
          IndicatorSeparator: false,
          DropdownIndicator,
          LoadingIndicator: false,
        }}
      />
      {error && (
        <InputError className="error" style={errorStyle}>
          * {error?.value || error}
        </InputError>
      )}
    </InputDropdownWrapper>
  );
};

export default InputDropdown;
