import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";

import URLS from "../constants/urls";
import { CurrentUserContext } from "../contexts/CurrentUserContext";

const useRouting = () => {
  const { currentUser, isLoading } = useContext(CurrentUserContext);
  const PrivateRoute = ({ component: Component, ...rest }) => {
    const authed = !!localStorage.getItem("token") && currentUser;
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoading ? (
            <Loader height="100vh" />
          ) : !authed ? (
            <Redirect to={{ pathname: URLS.sign_in }} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  };

  const PublicRoute = ({ component: Component, ...rest }) => {
    const authed = !!localStorage.getItem("token") && currentUser;
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoading ? (
            <Loader height="100vh" />
          ) : !authed ? (
            <Component {...props} />
          ) : (
            <Redirect to={URLS.dashboard} />
          )
        }
      />
    );
  };

  return { PrivateRoute, PublicRoute };
};

export default useRouting;
