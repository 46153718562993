import styled from "styled-components";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

const types = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
};

const alertIcon = ({ type }) => {
  switch (type) {
    case types.ERROR:
      return ICONS.AppReleaseCancelled;
    case types.SUCCESS:
      return ICONS.AppReleaseLive;
    case types.INFO:
      return ICONS.AppReleaseInReview;
    default:
      return "";
  }
};

export const Wrapper = styled.div`
  display: flex;
  pointer-events: all;
  justify-content: space-between;
  align-items: center;
  width: 36rem;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px #d0d0c8;
  border: 1px solid #e5e5e5;
  padding: 1.6rem;
`;

export const Icon = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1.6rem;
  background-image: url(${alertIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Message = styled.div`
  font-size: 1.6rem;
  color: #050000cc;
  font-family: ${FONTS.UbuntuRegular};
  flex-grow: 1;
  text-align: center;
`;

export const CloseBtn = styled.div`
  font-size: 2rem;
  padding: 0.4rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: ${FONTS.UbuntuMedium};
  cursor: pointer;
  margin-left: 1.6rem;
`;
