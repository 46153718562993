import { useQuery } from "react-query";

import { getCategories } from "../APIs";

const useCategory = () => {
  const { data: categories, isLoading: categoriesLoading } = useQuery("get-categories", getCategories);

  return { categories, categoriesLoading };
};

export default useCategory;
