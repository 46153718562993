import { useMutation } from "react-query";
import mixpanel from "mixpanel-browser";
import { useAlert } from "react-alert";
import { useContext, useEffect } from "react";

import {
  signUp,
  verifyEmail,
  checkEmail,
  resetPassword,
  setPassword,
  editUserPersonal,
  resendVerificationEmail,
} from "../APIs";
import STRINGS from "../constants/strings";
import { InfoModalTypes } from "../constants/types";
import URLS from "../constants/urls";
import useHistoryCustom from "./historyCustom";
import { CurrentUserContext } from "../contexts/CurrentUserContext";
import { TRACK_EVENTS } from "../constants/mixpanel";

const useUser = ({ isEmailVerification } = {}) => {
  const { history, qs } = useHistoryCustom();
  const isMember = history.location.pathname === URLS.sign_up_member;
  const alert = useAlert();
  const { refetchCurrentUser } = useContext(CurrentUserContext);

  const {
    mutateAsync: userResetPasswordMutation,
    isLoading: userResetPasswordLoading,
  } = useMutation("reset-password", resetPassword);

  const userResetPassword = async (vars, { setSuccessOpen }) => {
    userResetPasswordMutation(vars, {
      onSuccess: (res) => {
        if (res?.data?.error) {
          alert.error("There is no account with that email");
        } else {
          setSuccessOpen({
            type: InfoModalTypes.SUCCESS,
            isOpen: true,
            title: "Yeah!",
            subTitle: "Check your email.",
            content: "Reset password link is sent",
            onRequestClose: () => {
              history?.push(URLS.sign_in);
            },
          });
        }
      },
    });
  };

  const {
    mutateAsync: userCheckEmailMutation,
    isLoading: userCheckEmailLoading,
  } = useMutation("check-email", checkEmail);

  const userCheckEmail = async (vars, { setErrors }) => {
    userCheckEmailMutation(vars, {
      onSuccess: (a) => {
        if (a?.data?.user_already_exists) {
          setErrors({ email: STRINGS.email_already_exists });
        } else {
          localStorage.setItem("emailToRegister", vars.email);
          mixpanel.track(TRACK_EVENTS.registrationStarted);
          history.push(URLS.signup_password);
        }
      },
    });
  };

  const {
    mutateAsync: resendVerificationEmailMutation,
    isLoading: resendLoading,
  } = useMutation("resend-verification-email", resendVerificationEmail);

  const resendEmail = async (email) => {
    resendVerificationEmailMutation(email, {
      onSuccess: () => alert.success("Email Sent !"),
      onError: () => alert.error("Something went wrong !"),
    });
  };

  const { mutate: userSignUpMutation, isLoading: userSignUpLoading } =
    useMutation("sign-up", signUp);

  const userSignUp = async (vars) => {
    const { email, firstName, lastName, password } = vars;

    const data = { firstName, lastName, password };

    if (isMember) {
      data.invitationToken = qs.token;
    } else {
      data.email = email;
    }

    await userSignUpMutation(data, {
      onSuccess: () => {
        mixpanel.track(TRACK_EVENTS.detailsFilled);
        history.push(isMember ? URLS.sign_in : URLS.verify_email);
      },
      onError: () => {
        history.push(URLS.sign_up);
      },
    });
  };

  const { mutate: editUserPersonalMutation, isLoading: editUserLoading } =
    useMutation("edit-user-personal", editUserPersonal);

  const editUser = async (vars, { hasUnsavedChanges }) => {
    if (!hasUnsavedChanges()) {
      alert.info("No changes to save !");
      return;
    }

    const {
      firstName,
      lastName,
      phonePrefix,
      phoneNumber,
      address,
      nickname,
      website,
      icon,
      about,
    } = vars;

    const data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("phonePrefix", phonePrefix);
    data.append("phoneNumber", phoneNumber);
    data.append("address", address);
    data.append("nickname", nickname);
    data.append("website", website);
    data.append("about", about);
    data.append("icon", icon);

    await editUserPersonalMutation(data, {
      onSuccess: () => {
        refetchCurrentUser();
        console.log("Success");
        alert.success("Successfully Updated !");
      },
      onError: (err) => {
        refetchCurrentUser();
        alert.error("Something Whet Wrong !");
        console.log("Error", err);
      },
    });
  };

  const {
    mutate: handleUserVerification,
    data: userVerification,
    error: userVerificationError,
    isLoading: userVerificationLoading,
  } = useMutation("verify-email", verifyEmail);

  useEffect(() => {
    if (isEmailVerification && qs?.token) {
      handleUserVerification(qs.token, {
        onSuccess: (data) => {
          if (!data.error) {
            mixpanel.track(TRACK_EVENTS.emailVerified);
          }
        },
      });
    }
  }, [handleUserVerification, qs, isEmailVerification]);

  const { mutate: userSetPasswordMutation, isLoading: userSetPasswordLoading } =
    useMutation("set-password", setPassword);

  const userSetPassword = async (vars, { setSuccessOpen }) => {
    const { password } = vars;
    await userSetPasswordMutation(
      { password, token: qs?.token },
      {
        onSuccess: (res) => {
          if (res?.data?.error) {
            alert.error(res.data?.error);
          } else {
            setSuccessOpen({
              type: InfoModalTypes.SUCCESS,
              isOpen: true,
              title: "Yeah!",
              subTitle: "Password has been changed",
              content: "You can sign in with the new password",
              onRequestClose: () => {
                history?.push(URLS.sign_in);
              },
            });
          }
        },
        onError: (err) => {
          alert.error(err?.message);
        },
      }
    );
  };

  return {
    userResetPassword,
    userResetPasswordLoading,
    userCheckEmail,
    userCheckEmailLoading,
    userSignUp,
    userSignUpLoading,
    userVerification,
    userVerificationError:
      userVerificationError || (userVerification?.error && userVerification),
    userVerificationLoading,
    userSetPassword,
    userSetPasswordLoading,
    editUser,
    editUserLoading,
    resendEmail,
    resendLoading,
  };
};

export default useUser;
