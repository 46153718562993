import React from "react";
import { useUtils } from "../../hooks";

import {
  FileInputInput,
  FileInputLabel,
  InputError,
  InputFileContent,
  InputFileIcon,
  InputFileText,
  InputFileWrapper,
} from "./components";

const InputFile = ({
  error,
  errorStyle,
  children,
  onChange,
  disabled,
  accept,
  multiple,
  preview,
  inputId = "file",
  crop,
}) => {
  const { cropImage } = useUtils();

  const handleChange = async (imgFiles) => {
    if (!crop || !imgFiles?.length) {
      return onChange(imgFiles);
    }
    Promise.all(
      [...imgFiles].map(
        (imgf) =>
          new Promise((resolve, reject) =>
            cropImage(imgf, crop, (img) => {
              resolve(img);
            })
          )
      )
    ).then((v) => onChange(v));
  };
  return (
    <>
      <InputFileWrapper>
        <FileInputInput
          type="file"
          onChange={(e) => {
            if (e?.target?.files?.length) {
              handleChange(e?.target?.files);
            }
          }}
          id={inputId}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
        />
        <FileInputLabel
          htmlFor={inputId}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            handleChange(e?.dataTransfer?.files);
          }}
        >
          {!!preview ? (
            preview
          ) : (
            <>
              <InputFileContent>
                <InputFileIcon />
                {children && <InputFileText>{children}</InputFileText>}
              </InputFileContent>
            </>
          )}
        </FileInputLabel>
        {error && (
          <InputError className="error" style={errorStyle}>
            * {error}
          </InputError>
        )}
      </InputFileWrapper>
    </>
  );
};

export default InputFile;
