import { AppReleaseStatus } from "./types";

const STRINGS = {
  // url vars
  url_id: "id",
  url_apptab: "apptab",
  url_release_state: "release_state",
  // errors
  something_went_wrong: "Something went wrong",
  invalid_credentials: "Invalid Credentials",
  required: "Required",
  too_short: "Too Short",
  invalid: "Invalid",
  invalid_email_format: "Invalid Email Format",
  no_match: "NO match",
  user_is_not_verified: "Please, verify your email first",
  email_already_exists: "Email Already Exists",
  version_is_not_valid: "Version is not valid",
  // header
  header_learning_center: "Learning Center",
  header_community: "Community",
  header_FAQ: "FAQ",
  header_waiting_list: "Waiting List",
  header_podcast: "Podcast",
  header_contact_us: "Contact Us",
  // sidebar
  sidebar_dashboard: "dashboard",
  sidebar_my_apps: "my apps",
  sidebar_settings: "settings",
  sidebar_team_n_testers: "team & testers",
  // general
  sign_up: "sign up",
  log_in: "log in",
  unity_binding_popup_info:
    "If popup does not open please click the button below",
  unity_binding_success: "You were succesfully logged in to the system",
  go_back_to_simulator:
    "Please go back to the simulator and continue your journey",
  cancel: "Cancel",
  okay: "okay",
  status: "status",
  free: "free",
  paid: "paid",
  name: "Name",
  email: "Email",
  role: "role",
  not_applicable: "N/A",
  or: "or",
  password: "password",
  firstname: "Firstname",
  lastname: "Lastname",
  next: "next",
  recover_password: "recover password",
  edit: "edit",
  delete: "Delete",
  warning: "Warning!",
  save: "Save",
  skip: "skip",
  loading: "loading",
  draft: "draft",
  requirements: "requirements",
  upload: "Upload",
  attention: "attention",
  and: " and ",
  // placeholders
  please_type_your_email: "Please type your email",
  please_type_your_firstname: "Please type your firstname",
  please_type_your_lastname: "Please type your lastname",
  please_type_your_password: "Please type your password",
  please_type_your_password_again: "Please type your password again",
  select_role: "Select Role",
  app_name: "App name",
  app_Name: "App Name",
  application_type: "Application type",
  short_description: "Short Description",
  full_description: "Full Description",
  type_your_pass: "Type your password",
  physical_address: "Physical address",
  developer_name: "Developer name",
  website_address: "Website address",
  about_yourself: "About yourself",
  // authorizations
  forgot_password: "Forgot password",
  reset_your_pass: "Reset your password",
  forgot_your_password: "Forgot your password",
  thats_okay_happens:
    "Thats okay, it happens! Click on the button below to reset your password.",
  create_an_account: "Create an account",
  build_robotic_apps: "Build robotic apps to shape the future",
  signup_with_gmail: "Signup with Gmail Account",
  Login_with_gmail: "Log in with Gmail Account",
  ray_a: "Ra-Ya",
  set_password: "Set Password",
  repeat_password: "repeat password",
  ur_dev_platform: "Unlimited Robotics Developers Platform",
  verify_your_email: "Verify Your email",
  we_have_sent_confirmation: "We have sent you a confirmation email to",
  please_verfiy_your_account:
    "please verify your account by tapping on the button inside the email",
  you_wont_create_app:
    "You wont be able to create an app until your email has been verified",
  with_raya_you_can_start: `With Ra-Ya, you can start building apps on Gary, our service robot, in a simple process using our emulator.`,
  user_verification: "User Verification",
  resend_email: "Resend Email",
  // app status
  app_status_pending_review: "pending review",
  app_status_in_review: "in review",
  app_status_live: "live",
  app_status_blocked: "blocked",
  app_status_cancelled: "cancelled",
  // invite users modal
  invite_new_users: "invite new users",
  send_invitation: "send invitation",
  app_permissions: "app permisions",
  join_app: "Join App",
  // page titles
  create_an_app: "create an app",
  app_registration: "app registration",
  app_releases: "app releases",
  welcome_to_ur: "welcome to UR developers center",
  welcome_aboard: "Welcome aboard!",
  // dashboard
  complete_your_profile_info: "complete your profile info",
  create_your_first_app: "create your first app",
  knowledge_center: "knowledge center",
  explore_our_demos: "explore our demos",
  edit_profile: "Edit profile",
  lets_start: "Lets start !",
  read_more: "Read More",
  view_demos: "View Demos",
  my_apps: "My Apps",
  add_new_app: "Add New App",
  explore_demos_subtitle:
    "Get inspired and obtain ideas on how to develop your next app by taking a look at our examples",
  knowledge_center_subtitle:
    "It may sound complicated at first, but this is why we created the developers’ help center with the all-you-need-to-know documentation",
  // apps
  create_new_app: "create new app",
  apps_subtitle:
    "View all of the apps you have access to in your developer account.",
  it_seems_you_didnt_create: `It seems you didn’t create any app yet.
    Tap on “Create New App” and start building your first application!`,
  delete_app: "Delete Application",
  are_you_sure_delete_app: "Are you sure you want to delete ",
  delete_app_warning:
    "This action cannot be undone and will permanently remove your app from the marketplace.",
  // app tabs
  dashboard: "dashboard",
  info: "info",
  credentials: "credentials",
  releases: "releases",
  analytics: "analytics",
  // app tab dashboard
  impressions: "impressions",
  installs: "installs",
  sales: "sales",
  rating: "rating",
  not_applicable_release: "N/A Release",
  release: "Release",
  get_Started_setting_up_app: "Get Started setting up your app",
  provide_info_about_app:
    "Provide information about your app and set up your store look & feel",
  let_us_know_about_content:
    "Let us know about the content of your app, and manage how it is presented on the marketplace",
  release_your_app: "Release Your App",
  publish_your_app_to_public:
    "Publish your app to real users in the marketplace by releasing it to your production track",
  edit_app_info: "Edit app info",
  create_an_app_release: "Create an app release",
  // app tab info
  short_description_of_your_app: "A short description of your app",
  graphics: "graphics",
  app_icon: "App icon",
  your_app_as_its_shown: `Your app icon as it's shown on the marketplace.
  Don’t use badges or text that suggest store
  ranking or price`,
  app_icon_requirements: `JPEG or 32-bit PNG
  512 px by 512 px
  Up to 1 MB`,
  app_preview: "App preview",
  app_screenshots_note: `Upload 2-8 phone screenshots
  PNG or JPEG
  16:9 or 9:16 aspect ratio.
  Each side between 320px and 3,840px.
  Up to 8MB per screenshot`,
  app_screenshots: "App screenshots",
  app_preview_note: `Upload mp4 video format
  9:16 aspect ratio
  Up to 30 MB
  Between 10 and 30 seconds length`,
  // app tab releases
  release_version: "release version",
  release_status: "release status",
  last_updated: "last updated",
  number_of_installs: "no of installs",
  create_release: "Create Release",
  waiting_for_review: "Waiting for review",
  available_on_marketplace: "Available on marketplace",
  expired: "Expired",
  release_your_first_v: "Release your first version of the app!",
  you_wrote_app_release:
    "You wrote your app code, tested it and now you are ready to release it to the marketplace! Please tap the button below to create your first app release and submit it for review. Good luck!",
  // app release types
  [AppReleaseStatus.PUBLISHED]: "Published",
  [AppReleaseStatus.SUBMITTED]: "Submitted",
  [AppReleaseStatus.DRAFT]: "Draft",
  [AppReleaseStatus.BLOCKED]: "Blocked",
  [AppReleaseStatus.CANCELED]: "Canceled",
  [AppReleaseStatus.ARCHIVED]: "Archived",
  // add app
  create_app: "create app",
  free_or_paid: "Free or Paid",
  this_is_how_your_app:
    "This is how your app will appear on UR marketplace. It should be clear and not include any price or rank",
  you_can_change: "You can change it at any moment later",
  confirm_app_meets: "Confirm that your app meets our terms of use",
  you_are_confirming_app_meets: `You are confirming that your application meets our `,
  terms_of_use: "Terms of Use",
  privacy_policy: "Privacy Policy",
  data_protection: "Data Protection",
  please_check_out_our_terms:
    " Please check out our terms to avoid some common reasons for app suspension.",
  // teams
  teams_th_email: "EMAIL ADDRESS",
  teams_th_name: "NAME",
  teams_th_role: "ROLE",
  teams_th_status: "STATUS",
  teams_th_access: "ACCESS",
  teams_n_testers: "Teams & Testers",
  invite_new_user: "Invite New User",
  you_are_about_to_delete_user: "You are about to delete a user",
  you_are_about_to_delete_release: "You are about to delete a release",
  are_you_sure_delete_user: "Are you sure you want to delete this user?",
  are_you_sure_delete_release: "Are you sure you want to delete this release?",
  yes_delete: "Yes, Delete",
  first_fill_application_information:
    "First fill info about application please",
  this_data_is_required_before_release:
    "Application details, like description and icon, are required before creating release.",
  min_preview_length: "Min preview length is 10 secs",
  max_preview_length: "Max preview length is 30 secs",
  // profile
  personal_info_title: "Personal information",
  personal_info_subtitle: "Manage and edit your personal information",
  developer_page_title: "Developer page",
  developer_page_subtitle:
    "Manage the information show on your developer page on martkerplace",
  developer_icon: "Developer Icon",
  name_note: "It should be clear and not include any price or rank",
  email_note: "Contact Administration to edit email",
  address_note:
    "Please provide a  current, valid physical address if you sell paid apps or in-app products.",
  nickname_note:
    "This is how your developer name will appear on Martketplace. ",
  website_note: "Enter the URL of your official website if you have one",
  about_note: "Please tell us about yourself",
  icon_note: `Developer icon as it's shown on the marketplace.
  Don’t use badges or text that suggest store
  ranking or price`,
  // profile incomplete
  profile_incomplete: "Profile incomplete",
  finish_profile: "Please, finish setting up your profile",
  you_can_not_invite_yourself: "You can't invite yourself",
};

export default STRINGS;
