import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const InputTextWrapper = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "5.6rem")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "1.6rem"};
  border: ${({ borderColor, disabled, noBorder, borderFocus, border }) =>
    border
      ? border
      : borderFocus
      ? `2px solid ${COLORS.secondary.ncs}`
      : disabled
      ? "2px solid #E5EAE7"
      : borderColor
      ? `2px solid ${borderColor}`
      : noBorder
      ? `none`
      : "2px solid #E5EAE7"};
  background: ${({ backgroundColor, disabled }) =>
    disabled ? "#FCFCFC" : backgroundColor ? backgroundColor : "transparent"};
  font-family: Ubuntu-Bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.6rem")};
  color: ${({ textColor, borderColor }) =>
    textColor ? textColor : borderColor ? borderColor : "#fff"};
  padding: ${({ padding }) => (padding ? padding : "0 2.4rem")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  & *.when-focused {
    display: ${({ isFocused }) => (isFocused ? "block" : "none")};
  }
  & *.when-focused_hidden {
    opacity: ${({ isFocused }) => (isFocused ? "1" : "0")};
  }
  position: relative;
`;

export const InputIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.6rem;
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputName = styled.div`
  line-height: 2.2rem;
  font-size: 1.2rem;
  font-family: Ubuntu-Medium;
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.secondary.morning};
`;

export const InputInput = styled.input`
  height: 3.2rem;
  font-size: 1.4rem;
  font-family: Ubuntu-Regular;
  color: ${({ theme }) => theme.color.secondary.grayscale};
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
`;

export const TextAreaInput = styled.textarea`
  width: 100%;
  min-width: ${({ maxWidth }) => maxWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  font-size: 1.4rem;
  font-family: Ubuntu-Regular;
  color: ${({ theme }) => theme.color.secondary.grayscale};
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  margin: 1rem 0;
`;

export const InputDropdownWrapper = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "5.6rem")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "1.6rem"};
  border: ${({ borderColor, borderWidth }) =>
    borderColor ? `${borderWidth || "2px"} solid ${borderColor}` : `none`};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  font-family: Ubuntu-Bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.6rem")};
  color: ${({ textColor, borderColor }) =>
    textColor ? textColor : borderColor ? borderColor : "#fff"};
  padding: ${({ padding }) => (padding ? padding : "0")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  cursor: pointer;
  position: relative;
`;

export const InputDropdownIndicator = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 1.6rem;
  background-image: url(${ICONS.DropdownIndicator});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

// option

export const OptionWrapper = styled.div`
  font-family: Ubuntu-Medium;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.04rem;
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || 0};
  margin: ${({ margin }) => margin || 0};
  color: ${({ isChecked }) =>
    isChecked ? COLORS.secondary.ncs : COLORS.secondary.morning};
  cursor: pointer;
  user-select: none;
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "all")};
  position: relative;
  & > .checkmark {
    background: ${({ isChecked, isDisabled }) =>
      isDisabled && isChecked
        ? `${COLORS.secondary.ncs}7f`
        : isChecked
        ? COLORS.secondary.ncs
        : COLORS.secondary.water_gray};
  }
  &:hover > .checkmark {
    background: ${({ isChecked }) =>
      isChecked ? COLORS.secondary.ncsDark : COLORS.secondary.water_green};
  }
  &:active > .checkmark {
    box-shadow: 0 0 0 8px
      ${({ isChecked }) => (isChecked ? "#DED3FF" : "#EFF0F6")};
  }
  text-transform: capitalize;
`;

export const OptionCheck = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  margin-right: 1rem;
  position: relative;
  border: ${({ error }) =>
    error ? `1px solid ${COLORS.secondary.rosso}` : "none"};
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${({ isChecked, icon }) =>
      isChecked ? icon || ICONS.CheckWhite : ""});
    background-position: center;
    background-size: 40% auto;
    background-repeat: no-repeat;
  }
`;

export const InputNoteContainer = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  margin-top: 1rem;
  color: ${COLORS.secondary.morning};
`;

export const InputError = styled.div`
  font-family: ${FONTS.UbuntuBold};
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: ${COLORS.secondary.rosso};
  position: absolute;
  top: Calc(100% + 0.6rem);
`;

// input file

export const FileInputInput = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
`;

export const FileInputLabel = styled.label`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  border-radius: 1.5rem;
`;

export const InputFileWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const InputFileContent = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${ICONS.DragAndDrop});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    & > * {
      transform: scale(1.05);
    }
  }
`;

export const InputFileIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(${ICONS.UploadFileCloud});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const InputFileText = styled.div`
  width: 10.8rem;
  text-align: center;
  color: ${COLORS.secondary.ncs};
  font-family: ${FONTS.UbuntuMedium};
  line-height: 1.6rem;
  font-size: 1.2rem;
  padding: 0.4rem;
`;
