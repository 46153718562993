import React from "react";
import { SectionContent, SectionTitle, SectionSubtitle, WholeWrapper } from "./components";

const CustomFormSection = ({ title, subtitle, margin, children }) => {
  return (
    <WholeWrapper margin={margin}>
      {title && <SectionTitle>{title}</SectionTitle>}
      {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
      <SectionContent>{children}</SectionContent>
    </WholeWrapper>
  );
};

export default CustomFormSection;
