import React from "react";
import Modal from "react-modal";
import COLORS from "../../constants/colors";
import { ModalCloseX, ModalHeader, ModalFooter } from "./components";
import CustomButton from "../button/CustomButton";

const customStyles = {
  overlay: {
    backgroundColor: `${COLORS.primary.oxford}80`,
    zIndex: "111",
  },
  content: {
    zIndex: "111",
    minWidth: "320px",
    maxWidth: "Calc(100% - 6.4rem)",
    height: "fit-content",
    top: "50%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "1rem",
    border: "none",
    padding: "0 ",
  },
};

const ModalOutline = ({
  isOpen,
  heading,
  buttonText,
  onRequestClose = () => {},
  onSubmit,
  children,
}) => {
  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      <ModalHeader>
        {heading}
        <ModalCloseX onClick={onRequestClose} />
      </ModalHeader>
      {children}
      <ModalFooter>
        <div />
        <CustomButton
          backgroundColor={COLORS.secondary.ncs}
          type="submit"
          capitalize
          onClick={onSubmit}
        >
          {buttonText}
        </CustomButton>
      </ModalFooter>
    </Modal>
  );
};

export default ModalOutline;
