import { Link } from "react-router-dom";
import React from "react";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import URLS from "../../constants/urls";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import CustomButton from "../../components/button/CustomButton";

import { useUser } from "../../hooks";

import { SubTitle, Title, VerifyEmailImg } from "./components";

const UserVerification = () => {
  const { userVerificationError, userVerificationLoading } = useUser({ isEmailVerification: true });

  return (
    <OutlineNonAuthorised doubleBackground>
      <VerifyEmailImg />
      <Title style={{ margin: "1.6rem 0" }}>{STRINGS.user_verification}</Title>
      {userVerificationError ? (
        <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
          Something went wrong <br /> <br /> {userVerificationError.message}
        </SubTitle>
      ) : userVerificationLoading ? (
        <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>{STRINGS.loading}...</SubTitle>
      ) : (
        <>
          <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>You have successfully verified email</SubTitle>
          <Link to={URLS.sign_in}>
            <CustomButton backgroundColor={COLORS.secondary.ncs}>Go to Sign in</CustomButton>
          </Link>
        </>
      )}
    </OutlineNonAuthorised>
  );
};

export default UserVerification;
