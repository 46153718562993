import styled from "styled-components";

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CustomTab = styled.div`
  font-family: Ubuntu-Regular;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme?.color?.primary?.oxford};
  text-transform: uppercase;
  padding: 2.5rem 2rem;
  margin-right: 3.2rem;
  cursor: pointer;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.47)};
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: Calc(100% - 3.6rem);
    height: 0.8rem;
    border-radius: 0.4rem;
    bottom: 0;
    left: 1.8rem;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    background: ${({ theme }) => theme?.color?.secondary?.ncs};
  }
`;
