import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";
import { AppStatus } from "../../constants/types";

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.4rem;
  align-items: center;
  border-radius: 1.6rem;
  background: #fff;
  margin: ${({ margin }) => margin || "0"};
`;

export const CardMain = styled.div`
  display: flex;
  align-items: center;
`;

export const CardLogo = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 1.6rem;
  background-image: url(${({ logo }) => logo || ICONS.AppDefaultLogo});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 3.2rem;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 8rem;
`;

export const CardName = styled.div`
  font-family: ${FONTS.UbuntuBold};
  font-size: 2rem;
  line-height: 3.4rem;
  letter-spacing: 0.5px;
`;

export const CardKind = styled.span`
  font-family: ${FONTS.UbuntuMedium};
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${COLORS.secondary.feldgrau};
`;

export const CardPaid = styled.span`
  font-family: Ubuntu-Medium;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #33ca7f;
  text-transform: capitalize;
`;

export const CardStatusRow = styled.span`
  display: flex;
  align-items: center;
`;

export const CardStatusLabel = styled.span`
  font-family: Ubuntu-Medium;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-transform: capitalize;
`;

const statusLogo = ({ status }) => {
  switch (status) {
    case AppStatus.Submitted:
      return ICONS.AppStatusPending;
    case AppStatus.IN_REVIEW:
      return ICONS.AppStatusInReview;
    case AppStatus.LIVE:
      return ICONS.AppStatusLive;
    default:
      return ICONS.AppStatusDraft;
  }
};
const statusColor = ({ status }) => {
  switch (status) {
    case AppStatus.Submitted:
      return "#F2994A";
    case AppStatus.IN_REVIEW:
      return "#0096C6";
    case AppStatus.LIVE:
      return "#33CA7F";
    default:
      return " #A0BAA4";
  }
};

export const CardStatusLogo = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-image: url(${statusLogo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 0.4rem 0 0.8rem;
`;

export const CardStatusValue = styled.div`
  font-family: Ubuntu-Medium;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${statusColor};
  text-transform: capitalize;
  margin-left: 0.4rem;
`;

export const CardArrow = styled.span`
  width: 4.8rem;
  height: 4.8rem;
  background-image: url(${ICONS.AppCardDropdown});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 2.8rem;
  cursor: pointer;
  transform: ${({ arrowUp }) => (arrowUp ? "rotate(180deg)" : "none")};
`;
