import * as Yup from "yup";
import STRINGS from "../constants/strings";

const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "Screenshot", "File"];

const name = Yup.string()
  .trim()
  .required(STRINGS.required)
  .min(2, STRINGS.too_short);
const paid = Yup.boolean().required();
const confirmed = Yup.boolean().required().oneOf([true], "required");

const shortDescription = Yup.string()
  .trim()
  .required(STRINGS.required)
  .min(2, STRINGS.too_short);
const description = Yup.string()
  .trim()
  .required(STRINGS.required)
  .min(20, STRINGS.too_short);

const image = Yup.mixed()
  .required(STRINGS.required)
  .test("fileType", "Invalid format", (value) =>
    SUPPORTED_FORMATS.includes(value?.type)
  );

const screenshots = Yup.array()
  .of(image)
  .required(STRINGS.required)
  .min(2, "Upload at least 2 screenshots")
  .max(8, "You can upload maximum 8 screenshots");

const type = Yup.object()
  .required(STRINGS.required)
  .shape({
    value: Yup.string().required(STRINGS.required),
  });

export const createAnAppSchema = Yup.object().shape({
  name,
  type,
  paid,
  confirmed,
});

export const updateAnAppSchema = Yup.object().shape({
  name,
  shortDescription,
  description,
  icon: image,
  screenshots,
  previewLength: Yup.number()
    .min(10, STRINGS.min_preview_length)
    .max(31, STRINGS.max_preview_length),
});
