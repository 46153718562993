import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const Title = styled.div`
  font-family: ${FONTS.UbuntuLight};
  letter-spacing: 1%;
  line-height: 1.8rem;
  margin-bottom: 1.8rem;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const AppAddCard = styled.div`
  width: 19.4rem;
  height: 20.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.2rem 1.6rem;
  box-shadow: 0px 8px 48px 0px #08183f0a;
  background: #ffffff;
  border-radius: 0.4rem;
  border: 1px dashed ${COLORS.secondary.ncs}68;
`;

export const AppAddCardIcon = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  background-image: url(${ICONS.AddNewApp});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const AppAddCardText = styled.div`
  font-size: 1.2rem;
  letter-spacing: 0.75px;
  margin-top: 1.4rem;
`;

export const AppContainer = styled.div`
  width: 19.4rem;
  height: 20.4rem;
  display: flex;
  margin-right: 2.4rem;
  flex-direction: column;
  &:hover {
    transform: scale(1.016);
    transition: ease-in-out;
  }
`;

export const AppCard = styled.div`
  width: 100%;
  height: 16.8rem;
  box-shadow: 0px 8px 48px 0px #08183f0a;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;
  &:hover *.show-on-hover {
    display: flex;
  }
`;

export const AppCardEdit = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  background: #00000020;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  &:hover * {
    transform: scale(1.1);
    transform-origin: center;
  }
`;

export const AppCardEditIcon = styled.div`
  width: 1.84rem;
  height: 1.84rem;
  background-image: url(${ICONS.EditSimpleWhite});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const AppLogo = styled.div`
  width: 100%;
  flex-grow: 1;
  background-image: url(${({ appLogo }) => appLogo || ICONS.AppDefaultLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const AppStatusRow = styled.div`
  width: 100%;
  height: 2.2rem;
  background: #ffffff;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 1%;
  padding: 0.4rem 1.48rem;
  display: flex;
  align-items: center;
`;

export const AppNameKind = styled.div`
  width: 100%;
  height: 2.2rem;
  padding: 1.6rem 0.2rem 2rem 0.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.5px;
  font-family: ${FONTS.UbuntuBold};
  display: flex;
  align-items: center;
  justify-content: space-between;
  & * span {
    font-size: 1.2rem;
  }
`;
