import React from "react";
import { StyledButton } from "./components";

const CustomButton = ({
  children,
  height,
  width,
  backgroundColor,
  borderColor,
  shadow,
  textColor,
  fontSize,
  padding,
  margin,
  fontFamily,
  capitalize,
  disabled,
  borderRadius,
  ...rest
}) => {
  return (
    <StyledButton
      height={height}
      width={width}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      shadow={shadow}
      textColor={textColor}
      padding={padding}
      margin={margin}
      fontSize={fontSize}
      fontFamily={fontFamily}
      capitalize={capitalize}
      disabled={disabled}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
