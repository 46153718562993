import styled from "styled-components";

export const TableWrapper = styled.div`
  background: #fff;
  border-radius: 1.6rem;
  box-shadow: 0px 7px 25px 0px #0093c617;
  border: 1px solid ${({ theme }) => theme?.color?.secondary?.water_gray};
  padding: 4rem 0;
  width: 100%;
`;

export const TableWrapperHeader = styled.div`
  width: 100%;
  padding: 0 4rem 4.2rem 5rem;
  display: flex;
  justify-content: space-between;
`;

export const TableWrapperTitle = styled.div`
  font-family: Ubuntu-Bold;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.5px;
  margin: 1rem 0;
  text-transform: capitalize;
`;

export const TableWrapperText = styled.div`
  font-family: Ubuntu-Regular;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme?.color?.secondary?.morning};
  width: 43.2rem;
`;
