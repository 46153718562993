import React from "react";
import { Content, WholeWrapper } from "./components";

const FixedButtonFooter = ({ children }) => {
  return (
    <WholeWrapper>
      <Content>{children}</Content>
    </WholeWrapper>
  );
};

export default FixedButtonFooter;
