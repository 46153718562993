import styled from "styled-components";

export const WholeWrapper = styled.div`
  width: 100%;
  height: 5.6rem;
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 5;
`;
export const Content = styled.div`
  background: white;
  position: absolute;
  width: Calc(100% + 18rem);
  height: 9.6rem;
  left: -9rem;
  top: 0;
  border-left: 9rem solid white;
  border-right: 9rem solid white;
  box-shadow: 2rem -1rem 1.7rem 0 rgba(8, 24, 63, 0.08);
  padding: 0.8rem 0 4.8rem;
  display: flex;
  justify-content: flex-end;
`;
