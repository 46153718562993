import styled from "styled-components";
import COLORS from "../../constants/colors";

export const FormContentWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: ${({ leftAlign }) => leftAlign ? "flex-start" : "center"};
  padding: 0 2rem;
  & * {
    pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  }

  & *.react-tel-input {
    height: 6.4rem;
    margin-top: 3.2rem;
  }
  & *.flag-dropdown {
    height: 6.2rem;
    width: 6.2rem;
    margin: 1px;
    border: none;
    border-radius: 1.6rem 0 0 1.6rem;
  }
  & *.selected-flag {
    border-radius: 1.6rem 0 0 1.6rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-right: 1rem;
  }
  & *.flag-dropdown.open {
    background: transparent;
  }
  & *.react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 1.6rem 0 0 1.6rem;
  }

  & * input.form-control {
    border: 1px solid #e5eae7;
    border-radius: 1.6rem;
    height: 6.4rem;
    width: 36.4rem;
    padding: 2.4rem 1.5rem 0 7.8rem;
  }
  & * input.form-control:focus {
    border-color: ${COLORS.secondary.ncs};
  }
  & *.special-label {
    display: block;
    background: transparent;
    left: 7.8rem;
    top: 0.4rem;
    line-height: 2.2rem;
    font-size: 1.2rem;
    font-family: Ubuntu-Medium;
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.secondary.morning};
  }
`;

export const HorizontalDivider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: #00000010;
  margin: 1.6rem 0;
`;
