import React, { useCallback, useContext, useState } from "react";
import GoogleLogin from "react-google-login";
import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import { FormContentWrapper } from "../../components/general/components";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";
import Loader from "../../components/loader/Loader";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import URLS from "../../constants/urls";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { SigninSchema } from "../../schemas";
import { useHistoryCustom } from "../../hooks";

import {
  Title,
  ForgotPassword,
  GeneralError,
  ChoiceDivider,
  PageLoaderBackground,
} from "./components";

const SignIn = () => {
  const { history } = useHistoryCustom();
  const { userSignIn, userLoading, userGoogleAuth } =
    useContext(CurrentUserContext);
  const [googleLoading, setGoogleLoading] = useState(false);

  const goToDashboard = useCallback(
    () => history.push(URLS.dashboard),
    [history]
  );

  return (
    <OutlineNonAuthorised>
      {googleLoading && (
        <PageLoaderBackground>
          <Loader noText />
        </PageLoaderBackground>
      )}
      <Title>Ra-Ya - Login</Title>
      <Formik
        onSubmit={(vars, { setErrors }) =>
          userSignIn(vars, { setErrors, goToDashboard })
        }
        initialValues={{ email: "", password: "" }}
        validationSchema={SigninSchema}
      >
        {({ setFieldValue, errors, touched, setErrors }) => (
          <Form>
            <FormContentWrapper
              style={{
                width: "40.8rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              disabled={userLoading}
            >
              <Field id="email" name="email">
                {({ field }) => (
                  <InputText
                    height="5.6rem"
                    margin="1.8rem 0"
                    placeholder={STRINGS.please_type_your_email}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.email}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.Email}
                  />
                )}
              </Field>
              <Field id="password" name="password">
                {({ field }) => (
                  <InputText
                    type="password"
                    height="5.6rem"
                    margin="1.8rem 0"
                    placeholder={STRINGS.type_your_pass}
                    onChange={(v) => setFieldValue(field.name, v)}
                    name={STRINGS.password}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.PasswordEye}
                  />
                )}
              </Field>
              {errors?.general && (
                <GeneralError className="error">
                  {`* ${
                    STRINGS[errors?.general] || STRINGS.something_went_wrong
                  }
                  `}
                </GeneralError>
              )}
              <CustomButton
                type="submit"
                backgroundColor={COLORS.secondary.ncs}
                width="100%"
                padding="0 7.2rem"
                height="5.8rem"
                margin="1rem 0 1.4rem"
                shadow
              >
                {STRINGS.log_in}
              </CustomButton>
              <Link style={{ height: "5rem" }} to={URLS.forgot_password}>
                <ForgotPassword>{STRINGS.forgot_password}</ForgotPassword>
              </Link>
              <ChoiceDivider>{STRINGS.or}</ChoiceDivider>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_AUTH_ID}
                buttonText="Login"
                onRequest={() => setGoogleLoading(true)}
                onSuccess={({ profileObj, accessToken }) => {
                  console.log("Google Success --> ", profileObj);
                  const { googleId, email, givenName, familyName, imageUrl } =
                    profileObj;
                  userGoogleAuth(
                    {
                      accessToken,
                      googleId,
                      email,
                      firstName: givenName,
                      lastName: familyName,
                      photo: imageUrl,
                    },
                    { goToDashboard, setErrors }
                  );
                }}
                onFailure={(err) => {
                  alert.error("Something went wrong !");
                  console.log("Google Err", err);
                  setGoogleLoading(false);
                }}
                render={(renderProps) => (
                  <CustomButton
                    borderColor={COLORS.secondary.ncs}
                    textColor={COLORS.primary.oxford}
                    height="5.8rem"
                    margin="2rem 0"
                    width="28.6rem"
                    padding="0"
                    shadow
                    type="button"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    {STRINGS.Login_with_gmail}
                  </CustomButton>
                )}
              />
            </FormContentWrapper>
          </Form>
        )}
      </Formik>
    </OutlineNonAuthorised>
  );
};

export default SignIn;
