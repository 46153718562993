import React, { useState, useEffect, useContext } from "react";
import GoogleLogin, { useGoogleLogin } from "react-google-login";
import { useLocation } from "react-router-dom";
import CustomButton from "../../components/button/CustomButton";
import Loader from "../../components/loader/Loader";
import { PageTitle } from "../../components/outline-authorised/components";
import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import COLORS from "../../constants/colors";
import STRINGS from "../../constants/strings";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import VerifyPage from "../verifypage/VerifyPage";
import { PageLoaderBackground } from "./components";

const UnityGoogleSignIn = () => {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [popupBlocked, setPopupBlocked] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const { userGoogleAuthUnity } = useContext(CurrentUserContext);
  const { search } = useLocation();
  const { signIn, loaded } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_AUTH_ID,
    onRequest: () => {
      setGoogleLoading(true);
    },
    onFailure: (err) => {
      console.log("Google err", err);
      setPopupBlocked(true);
      setGoogleLoading(false);
    },
    onSuccess: ({ profileObj, accessToken }) => {
      console.log("Google Success --> ", profileObj);
      const { googleId, email, givenName, familyName, imageUrl } = profileObj;
      userGoogleAuthUnity(
        {
          accessToken,
          googleId,
          email,
          firstName: givenName,
          lastName: familyName,
          photo: imageUrl,
          unityId: new URLSearchParams(search).get("id"),
        },
        { endAuthorization }
      );
    },
    uxMode: "popup",
  });

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if (id && loaded && !popupBlocked) {
      signIn();
    }
  }, [loaded, signIn, search, popupBlocked]);

  const endAuthorization = () => {
    setSignedIn(true);
  };

  return (
    <OutlineNonAuthorised>
      {googleLoading ? (
        signedIn ? (
          <VerifyPage />
        ) : (
          <PageLoaderBackground>
            <Loader noText />
          </PageLoaderBackground>
        )
      ) : (
        <React.Fragment>
          <PageTitle>{STRINGS.unity_binding_popup_info}</PageTitle>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_AUTH_ID}
            buttonText="Login"
            onRequest={() => setGoogleLoading(true)}
            onSuccess={({ profileObj, accessToken }) => {
              console.log("Google Success --> ", profileObj);
              const { googleId, email, givenName, familyName, imageUrl } =
                profileObj;
              userGoogleAuthUnity(
                {
                  accessToken,
                  googleId,
                  email,
                  firstName: givenName,
                  lastName: familyName,
                  photo: imageUrl,
                  unityId: new URLSearchParams(search).get("id"),
                },
                { endAuthorization }
              );
            }}
            onFailure={(err) => {
              alert.error("Something went wrong !");
              console.log("Google Err", err);
              setGoogleLoading(false);
            }}
            render={(renderProps) => (
              <CustomButton
                borderColor={COLORS.secondary.ncs}
                textColor={COLORS.primary.oxford}
                height="5.8rem"
                margin="2rem 0"
                width="28.6rem"
                padding="0"
                shadow
                type="button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                {STRINGS.Login_with_gmail}
              </CustomButton>
            )}
          />
        </React.Fragment>
      )}
    </OutlineNonAuthorised>
  );
};

export default UnityGoogleSignIn;
