import styled from "styled-components";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import FONTS from "../../constants/fonts";

export const WholeWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

export const Sidebar = styled.div`
  min-height: 100%;
  width: ${({ isOpen }) => (isOpen ? "26.8rem" : "12.2rem")};
  min-width: ${({ isOpen }) => (isOpen ? "26.8rem" : "12.2rem")};
  max-width: ${({ isOpen }) => (isOpen ? "26.8rem" : "12.2rem")};
  display: flex;
  flex-direction: column;
`;

export const SidebarTab = styled.div`
  width: 100%;
  padding: 0 2.2rem;
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    z-index: 7;
    right: ${({ isOpen }) => (isOpen ? "-0.8rem" : 0)};
    height: 100%;
    width: 0.8rem;
    background: ${({ theme, active }) => (active ? theme.color.secondary.ncs : "transparent")};
    border-radius: 0.4rem;
  }
  & > div {
    background: ${({ theme, active }) =>
      active ? `${theme.color.secondary.aero}4d` : "transparent"};
    width: ${({ isOpen }) => (isOpen ? "100%" : "fit-content")};
  }
  &:hover {
    opacity: 1;
    & span {
      display: ${({ isOpen }) => (isOpen ? "none" : "block")};
      font-family: ${({ active }) => (active ? FONTS.UbuntuMedium : FONTS.UbuntuRegular)};
    }
  }
`;

export const LinkHoverTooltip = styled.span`
  background: ${COLORS.secondary.main_bg};
  position: absolute;
  padding: 0.6rem 1.2rem;
  color: ${COLORS.primary.oxford};
  font-size: 1.2rem;
  border-radius: 1.2rem;
  z-index: 1000;
  left: Calc(100% + 1rem);
  display: none;
  white-space: nowrap;
  text-transform: capitalize;
  box-shadow: inset 0.3rem 0.3rem 1.7rem 0 rgba(8, 24, 63, 0.08);
`;

export const SidebarNavItem = styled.div`
  width: 100%;
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  padding: 1.6rem;
`;

export const SidebarNavIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${({ icon }) => icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 2rem;
`;

export const SidebarNavText = styled.div`
  margin-left: 2.2rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-transform: capitalize;
`;

export const RightContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 9.6rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 4rem 0 8.8rem;
  justify-content: space-between;
`;

export const PageTitle = styled.div`
  font-family: Ubuntu-Regular;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-transform: capitalize;
`;

export const SidebarHeader = styled.div`
  width: 100%;
  height: 9.6rem;
  padding-left: 4.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 4.2rem;
`;

export const HeaderBurger = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${ICONS.BurgerMenu});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  margin-right: 2rem;
`;

export const HeaderLogout = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${ICONS.LogOut});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

export const HeaderLogo = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  background-image: url(${ICONS.LogoRoundBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

export const MainContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: ${COLORS.secondary?.main_bg};
  box-shadow: inset 0.3rem 0.3rem 1.7rem 0 rgba(8, 24, 63, 0.08);
  border-radius: 4.2rem 0 0 0;
  padding: 8rem 9rem 4rem;
  padding-top: ${({ paddingTop }) => paddingTop || "8rem"};
`;

export const SidebarFooter = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  & > * a {
    display: inline;
    color: #08183f7a;
    line-height: 1.8rem;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;
