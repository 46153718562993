import { Route, Switch, BrowserRouter } from "react-router-dom";
import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";

import { TRACK_EVENTS } from "./constants/mixpanel";
import URLS from "./constants/urls";

import OutlineNonAuthorised from "./components/outline-non-authorised/OutlineNonAuthorised";
import UserVerification from "./pages/sign-in/UserVerification";
import ForgotPassword from "./pages/sign-in/ForgotPassword";
import SignUpPassword from "./pages/sign-in/SignUpPassword";
import EditProfile from "./pages/profile/EditProfile";
import VerifyEmail from "./pages/sign-in/VerifyEmail";
import SetPassword from "./pages/sign-in/SetPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import AppPage from "./pages/app-page/AppPage";
import SignIn from "./pages/sign-in/SignIn";
import SignUp from "./pages/sign-in/SignUp";
import AddApp from "./pages/add-app/AddApp";
import Teams from "./pages/teams/Teams";
import AddMember from "./pages/teams/AddMember";
import Apps from "./pages/apps/Apps";

import { useRouting } from "./hooks";
import UnityGoogleSignIn from "./pages/sign-in/UnityGoogleSignIn";

function App() {
  const { PublicRoute, PrivateRoute } = useRouting();

  useEffect(() => {
    setTimeout(() => {
      const intercom = document.getElementsByClassName("intercom-launcher")[0];

      if (intercom) {
        intercom.addEventListener("click", () => {
          mixpanel.track(TRACK_EVENTS.intercom);
        });
      }
    }, 2000);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path={[URLS.sign_in, "/"]} component={SignIn} />
        <Route
          exact
          path={[URLS.unity_google_signin, "/"]}
          component={UnityGoogleSignIn}
        />
        <PublicRoute exact path={URLS.sign_up} component={SignUp} />
        <PublicRoute
          exact
          path={URLS.sign_up_member}
          component={SignUpPassword}
        />
        <PublicRoute
          exact
          path={URLS.forgot_password}
          component={ForgotPassword}
        />
        <PublicRoute exact path={URLS.verify_email} component={VerifyEmail} />
        <PublicRoute
          path={URLS.user_verification}
          component={UserVerification}
        />
        <PublicRoute
          exact
          path={URLS.signup_password}
          component={SignUpPassword}
        />
        <PublicRoute exact path={URLS.set_password} component={SetPassword} />
        <Route exact path={URLS.team_add_member} component={AddMember} />
        <PrivateRoute exact path={URLS.dashboard} component={Dashboard} />
        <PrivateRoute exact path={URLS.apps} component={Apps} />
        <PrivateRoute exact path={URLS.add_app} component={AddApp} />
        <PrivateRoute path={URLS.app_page} component={AppPage} />
        <PrivateRoute exact path={URLS.team} component={Teams} />
        <PrivateRoute exact path={URLS.profile} component={EditProfile} />

        <Route
          component={() => (
            <OutlineNonAuthorised>not found</OutlineNonAuthorised>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
