import React from "react";
import { CardLogo, CardMain, CardTitle, CardWrapper, LinkArrow, LinkPart } from "./components";

const NoDataCard = ({ title, icon, children, margin, iconStyle, linkText, redirectFunc, ...rest }) => {
  return (
    <CardWrapper {...rest} margin={margin}>
      <CardLogo logo={icon} style={iconStyle} />
      <CardMain>
        <CardTitle>{title}</CardTitle>
        {children}
      </CardMain>
      <LinkPart>
        {linkText}
        <LinkArrow onClick={redirectFunc} />
      </LinkPart>
    </CardWrapper>
  );
};

export default NoDataCard;
