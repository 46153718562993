import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";

export const WholeWrapper = styled.div`
  width: 100%;
  margin: ${({ margin }) => margin || "0"};
`;

export const SectionTitle = styled.div`
  font-family: ${FONTS.UbuntuBold};
  font-size: 2rem;
  line-height: 3.4rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0 0.6rem;
`;

export const SectionSubtitle = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 2rem;
  color: ${COLORS.secondary.morning};
  padding: 0 0.6rem;
`;

export const SectionContent = styled.div`
  margin-top: 1.2rem;
  width: 100%;
  padding: 5.6rem 4.4rem 4.4rem;
  background: #fff;
  border-radius: 1.6rem;
  border: 1px solid #e5eae7;
  box-shadow: 0px 7px 25px 0px #0093c617;
`;
