import { Link } from "react-router-dom";
import React from "react";

import STRINGS from "../../constants/strings";

import {
  ArrowIcon,
  ContentSubtitle,
  ContentTitle,
  ContentWrapper,
  WarningLogo,
  WholeWrapper,
} from "./components";
import mixpanel from "mixpanel-browser";
import { TRACK_EVENTS, TRACK_VALUES } from "../../constants/mixpanel";

const ProfileIncomplete = () => {
  return (
    <Link
      to="/profile"
      onClick={() => {
        mixpanel.track(TRACK_EVENTS.profileVisited, {
          origin: TRACK_VALUES[TRACK_EVENTS.profileVisited].dashboard,
        });
      }}
    >
      <WholeWrapper>
        <WarningLogo />
        <ContentWrapper>
          <ContentTitle>{STRINGS.profile_incomplete}!</ContentTitle>
          <ContentSubtitle>{STRINGS.finish_profile}</ContentSubtitle>
        </ContentWrapper>
        <ArrowIcon />
      </WholeWrapper>
    </Link>
  );
};

export default ProfileIncomplete;
