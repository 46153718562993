import React, { useMemo } from "react";

import NoDataCard from "../../components/no-data-card/NoDataCard";
import DashboardCard from "../../components/dashboard/DashboardCard";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import RedirectLinkCard from "../../components/redirect-link-card/RedirectLinkCard";

import { AppReleaseStatus } from "../../constants/types";
import STRINGS from "../../constants/strings";
import ICONS from "../../constants/icons";
import URLS from "../../constants/urls";

import { useHistoryCustom } from "../../hooks";

import { DashboardCards } from "./components";

const AppTabDashboard = ({ currApp, isAppInfoValid }) => {
  const { setCategory } = useHistoryCustom();

  const [releaseStatus, releaseVersion] = useMemo(() => {
    let published = null;
    let submitted = null;
    let draft = null;
    let blocked = null;
    let canceled = null;

    currApp?.releases?.map((rel) => {
      if (!published && rel?.status === AppReleaseStatus.PUBLISHED) {
        published = rel;
      } else if (!submitted && rel?.status === AppReleaseStatus.SUBMITTED) {
        submitted = rel;
      } else if (!draft && rel?.status === AppReleaseStatus.DRAFT) {
        draft = rel;
      } else if (!blocked && rel?.status === AppReleaseStatus.BLOCKED) {
        blocked = rel;
      } else if (!canceled && rel?.status === AppReleaseStatus.CANCELED) {
        canceled = rel;
      }
      return 1;
    });

    const relevantRelease = published || submitted || draft || blocked || canceled;

    return [relevantRelease?.status, relevantRelease?.version];
  }, [currApp]);

  return (
    <>
      {!currApp?.releases?.length ? (
        <>
          <NoDataCard
            margin="0.4rem 0 1rem"
            borderRadius="2.5rem 2.5rem 0 0"
            title={STRINGS.get_Started_setting_up_app}
            icon={ICONS.NoDashboardIcon}
            iconStyle={{ width: "13.3rem", height: "13.3rem", minWidth: "13.3rem", minHeight: "13.3rem" }}
          >
            While you're getting set up, the Dashboard shows you what you need to do to get your app up and running.
            This includes recommendations on how to manage, test, and promote your app. Once you've completed a task,
            come back here to explore what else you can do
          </NoDataCard>
          {!isAppInfoValid && (
            <RedirectLinkCard
              title={STRINGS.provide_info_about_app}
              icon={ICONS.RedirectIconEditApp}
              linkText={STRINGS.edit_app_info}
              redirectFunc={() => setCategory(URLS.app_page_tab, STRINGS.url_apptab, STRINGS.info)}
            >
              {STRINGS.let_us_know_about_content}
            </RedirectLinkCard>
          )}
          <RedirectLinkCard
            margin="1rem 0 2.4rem"
            borderRadius=" 0 0 2.5rem 2.5rem"
            title={STRINGS.release_your_app}
            icon={ICONS.RedirectIconCreateRelease}
            linkText={STRINGS.create_an_app_release}
            redirectFunc={() => setCategory(URLS.app_page_tab, STRINGS.url_apptab, STRINGS.releases)}
          >
            {STRINGS.publish_your_app_to_public}
          </RedirectLinkCard>
        </>
      ) : (
        <>
          <DashboardHeader
            placeholder={STRINGS.not_applicable_release}
            label={`${STRINGS?.[releaseStatus]} ${STRINGS.release}:`}
            value={releaseVersion}
            opacity={releaseStatus === AppReleaseStatus.DRAFT}
          />
          <DashboardCards>
            <DashboardCard
              icon={ICONS.Impressions}
              // value={stats?.[AppId]?.impressions}
              label={STRINGS.impressions}
              info="Some description about the block"
              placeholder={STRINGS.not_applicable}
            />
            <DashboardCard
              icon={ICONS.Installs}
              // value={stats?.[AppId]?.installs}
              label={STRINGS.installs}
              info="Some description about the block"
              placeholder={STRINGS.not_applicable}
            />
            <DashboardCard
              icon={ICONS.Sales}
              // value={stats?.[AppId]?.sales}
              label={STRINGS.sales}
              info="Some description about the block"
              placeholder={STRINGS.not_applicable}
            />
            <DashboardCard
              icon={ICONS.Rating}
              // value={stats?.[AppId]?.rating}
              label={STRINGS.rating}
              info="Some description about the block"
              placeholder={STRINGS.not_applicable}
            />
          </DashboardCards>
        </>
      )}
    </>
  );
};

export default AppTabDashboard;
