import React, { useMemo } from "react";
import { ImageContainer, PreviewWrapper } from "./components";

const MediaFilePreview = ({ file, handleDurationChange }) => {
  const [fileType, fileUrl] = useMemo(
    () =>
      file?.id
        ? [
            file?.mimetype?.split("/")?.[0] || file?.type,
            `${file?.publicUrl}${(file?.authUrl && `?${performance?.now()}`) || ""}`,
          ]
        : file?.type
        ? [file?.type?.split("/")?.[0], URL.createObjectURL(file)]
        : [],
    [file]
  );
  return (
    <PreviewWrapper>
      {fileType === "image" || fileType === "Icon" ? (
        <ImageContainer media={fileUrl} />
      ) : fileType === "video" ? (
        <video
          width="100%"
          height="100%"
          controls
          onDurationChange={(e) => handleDurationChange(e.target.duration)}
        >
          <source src={fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <></>
      )}
    </PreviewWrapper>
  );
};

export default MediaFilePreview;

/**
  <iframe
    title={fileUrl}
    width="100%"
    height="100%"
    src={fileUrl}
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
 */
