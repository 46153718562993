import STRINGS from "./strings";

const URLS = {
  sign_up: "/sign_up",
  sign_up_member: "/register-member",
  sign_in: "/sign_in",
  set_password: "/set-password",
  signup_password: "/signup_password",
  forgot_password: "/forgot_password",
  verify_email: "/verify_email",
  user_verification: "/verify-email",
  unity_google_signin: "/unity-google-sign-in",
  home: "/home",
  dashboard: "/dashboard",

  // -- non authorised links -- //
  robotics_page: "https://www.unlimited-robotics.com",
  learning_center: "https://documentation.unlimited-robotics.com",
  community: "https://discord.gg/Db7hrrePhn",
  contact_us: "https://www.unlimited-robotics.com/contact",
  faq: "https://www.unlimited-robotics.com/faq",
  waiting_list: "https://www.unlimited-robotics.com/join",
  podcast: "https://open.spotify.com/show/46gnX1APnNCyp6EDLfb0lR",
  terms_of_use: "https://www.unlimited-robotics.com/terms-of-use",
  privacy_police: "https://www.unlimited-robotics.com/privacy-policy",
  data_protection: "https://www.unlimited-robotics.com/data-protection",

  // -- app --
  apps: "/apps",
  app_page: `/apps/:${STRINGS.url_id}`,
  app_page_tab: `/apps/:${STRINGS.url_id}/:${STRINGS.url_apptab}`,
  app_page_dashboard: `/apps/:${STRINGS.url_id}/dashboard`,
  app_page_info: `/apps/:${STRINGS.url_id}/info`,
  app_page_credentials: `/apps/:${STRINGS.url_id}/credentials`,
  app_page_releases: `/apps/:${STRINGS.url_id}/releases`,
  app_page_add_release: `/apps/:${STRINGS.url_id}/releases/:releaseState`,
  app_page_analytics: `/apps/:${STRINGS.url_id}/analytics`,
  add_app: "/apps/new",

  // -- teams & testers --
  team: "/team",
  team_add_member: "/add-member",

  // -- -- -- --
  settings: "/settings",
  profile: "/profile",
};

export default URLS;
