import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useContext } from "react";

import STRINGS from "../../constants/strings";
import ICONS from "../../constants/icons";
import URLS from "../../constants/urls";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";

import {
  WholeWrapper,
  HeaderWrapper,
  MainContainer,
  Sidebar,
  RightContainer,
  SidebarHeader,
  HeaderBurger,
  HeaderLogo,
  PageTitle,
  HeaderLogout,
  SidebarTab,
  SidebarNavItem,
  SidebarNavIcon,
  SidebarNavText,
  LinkHoverTooltip,
  SidebarFooter,
} from "./components";
import mixpanel from "mixpanel-browser";
import { TRACK_EVENTS, TRACK_VALUES } from "../../constants/mixpanel";
import { EnvTypes } from "../../constants/types";

const OutlineAuthorised = ({ children, pageTitle, paddingTop }) => {
  const { logOut, sidebarOpen, setSidebarOpen } =
    useContext(CurrentUserContext);
  const location = useLocation();
  const history = useHistory();
  return (
    <WholeWrapper>
      <Sidebar isOpen={sidebarOpen}>
        <SidebarHeader>
          <HeaderBurger onClick={() => setSidebarOpen((curr) => !curr)} />
          <HeaderLogo />
        </SidebarHeader>
        {[
          {
            icon: ICONS.NavDashboard,
            to: URLS.dashboard,
            title: STRINGS.sidebar_dashboard,
          },
          {
            icon: ICONS.NavApps,
            to: URLS.apps,
            title: STRINGS.sidebar_my_apps,
          },
          {
            icon: ICONS.NavTeam,
            to: URLS.team,
            title: STRINGS.sidebar_team_n_testers,
            hidden: process.env.REACT_APP_ENV === EnvTypes.PROD,
          },
          {
            icon: ICONS.NavProfile,
            to: URLS.profile,
            title: STRINGS.edit_profile,
            onClick: () => {
              mixpanel.track(TRACK_EVENTS.profileVisited, {
                origin: TRACK_VALUES[TRACK_EVENTS.profileVisited].menu,
              });
            },
          },
        ].map((item, i) =>
          item.hidden ? null : (
            <Link to={item.to} key={i} onClick={item.onClick}>
              <SidebarTab
                active={`/${location.pathname?.split("/")?.[1]}` === item.to}
                isOpen={sidebarOpen}
              >
                <LinkHoverTooltip>{item.title}</LinkHoverTooltip>
                <SidebarNavItem>
                  <SidebarNavIcon icon={item.icon} />
                  {sidebarOpen && <SidebarNavText>{item.title}</SidebarNavText>}
                </SidebarNavItem>
              </SidebarTab>
            </Link>
          )
        )}
        <SidebarFooter>
          <div>
            <a href={URLS.terms_of_use} target="_blank" rel="noreferrer">
              {`${STRINGS.terms_of_use} - `}
            </a>
            <a href={URLS.privacy_police} target="_blank" rel="noreferrer">
              {STRINGS.privacy_policy}
            </a>
          </div>
          <div>
            <a href={URLS.data_protection} target="_blank" rel="noreferrer">
              {STRINGS.data_protection}
            </a>
          </div>
        </SidebarFooter>
      </Sidebar>

      <RightContainer>
        <HeaderWrapper>
          <PageTitle>{pageTitle}</PageTitle>
          <HeaderLogout
            onClick={() => {
              logOut();
              history.push(URLS.sign_in);
            }}
          />
        </HeaderWrapper>
        <MainContainer paddingTop={paddingTop}>{children}</MainContainer>
      </RightContainer>
    </WholeWrapper>
  );
};

export default OutlineAuthorised;
