import { positions, Provider as AlertProvider } from "react-alert";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import ReactDOM from "react-dom";
import React from "react";
import axios from "axios";
import mixpanel from "mixpanel-browser";

import "./index.css";
import App from "./App";
import Theme from "./theme";

import AlertSnack from "./components/alert-snack/AlertSnack";

import { CurrentUserProvider } from "./contexts/CurrentUserContext";
import { ResponsiveProvider } from "./contexts/ResponsiveContext";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 30000;
axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error.response)
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const AlertOptions = {
  timeout: 2500,
  position: positions.BOTTOM_CENTER,
  containerStyle: {
    zIndex: 222222222,
  },
};

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <ResponsiveProvider>
      <AlertProvider template={AlertSnack} {...AlertOptions}>
        <QueryClientProvider client={queryClient}>
          <CurrentUserProvider>
            <App />
          </CurrentUserProvider>
        </QueryClientProvider>
      </AlertProvider>
      </ResponsiveProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
