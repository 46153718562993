import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { memberAdd } from "../APIs";
import useHistoryCustom from "./historyCustom";

export const useAddMember = () => {
  const { qs } = useHistoryCustom();
  const [sent, setSent] = useState(false);

  const {
    mutate: handleMemberAddMutation,
    data,
    error,
    isLoading,
  } = useMutation("member-add", memberAdd);

  useEffect(() => {
    if (!sent) {
      handleMemberAddMutation(qs.token);
      setSent(true);
    }
  }, [handleMemberAddMutation, qs.token, sent]);

  return { data, error, isLoading };
};
