import styled from "styled-components";

import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 1.6rem 2.4rem 1.6rem 3.2rem;
  align-items: center;
  background: #fff;
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  box-shadow: 0px 7px 25px 0px #0093c617;
`;

export const CardTitle = styled.div`
  font-family: ${FONTS.UbuntuBold};
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  color: ${COLORS.primary.oxford};
  padding-bottom: 0.4rem;
  text-transform: capitalize;
`;

export const CardMain = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.2rem;
  color: ${COLORS.secondary.morning};
  flex-grow: 1;
  line-height: 1.6rem;
`;

export const CardMore = styled.div`
  padding-top: 0.4rem;
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1rem;
  text-decoration: underline;
`;

export const CardLogo = styled.div`
  min-width: 5.4rem;
  min-height: 5.4rem;
  background-image: url(${({ logo }) => logo});
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 3.2rem;
`;

export const LinkArrow = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-image: url(${ICONS.ArrowRight});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 0.8rem;
`;
