import { useCallback } from "react";

const useForm = (formRef) => {
  const hasUnsavedChanges = useCallback(() => {
    if (!formRef?.current) {
      return false;
    }
    const before = JSON.stringify(formRef?.current.initialValues);
    const after = JSON.stringify(formRef?.current.values);
    return before !== after;
  }, [formRef]);

  const scrollToFirstError = useCallback(() => {
    if (formRef?.current?.isValid) {
      return;
    }
    setTimeout(() => {
      const errorFields = document.getElementsByClassName("error");
      errorFields?.[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 1000);
  }, [formRef]);

  const submitForm = useCallback(() => {
    if (formRef?.current?.isValid) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  return { hasUnsavedChanges, scrollToFirstError, submitForm };
};

export default useForm;
