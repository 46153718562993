import axios from "axios";

const ApplicationReleaseEdit = async ({ queryKey }) => {
  console.log(queryKey);
  const res = await axios.put(`/application-release/${queryKey?.releaseId}`, queryKey?.data);
  console.log(res);
  return res;
};

export default ApplicationReleaseEdit;
