import React, { useState } from "react";
import mixpanel from "mixpanel-browser";

import { InfoModalTypes } from "../../constants/types";
import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import URLS from "../../constants/urls";

import OutlineAuthorised from "../../components/outline-authorised/OutlineAuthorised";
import TableOutline from "../../components/table-outline/TableOutline";
import AppCard from "../../components/app-card/AppCard";
import Loader from "../../components/loader/Loader";
import InfoModal from "../../components/info-modal/InfoModal";

import { useHistoryCustom, useApp, useUtils } from "../../hooks";

import {
  AppsContainer,
  AppWrapper,
  CenteredFlex,
  DeleteAppButton,
  DeleteAppButtonIcon,
} from "./components";
import {
  DashboardCardContent,
  DashboardCardIcon,
  DashboardCardTitle,
} from "../dashboard/components";
import { TRACK_EVENTS, TRACK_VALUES } from "../../constants/mixpanel";

const Apps = () => {
  const history = useHistoryCustom();
  const [appTodelete, setAppToDelete] = useState();
  const { lineByLine } = useUtils();
  const { coachApps, coachAppsLoading, deleteApplication } = useApp();

  return (
    <OutlineAuthorised pageTitle={STRINGS.my_apps}>
      <TableOutline
        title={STRINGS.my_apps}
        subTitle={STRINGS.apps_subtitle}
        actionName={STRINGS.create_new_app}
        actionAction={() => {
          mixpanel.track(TRACK_EVENTS.appCreationStarted, {
            origin: TRACK_VALUES[TRACK_EVENTS.appCreationStarted].apps,
          });
          history.history.push(URLS.add_app);
        }}
      >
        {coachAppsLoading ? (
          <Loader />
        ) : coachApps?.length ? (
          <AppsContainer>
            {appTodelete && (
              <InfoModal
                isOpen={appTodelete}
                type={InfoModalTypes.WARNING}
                title={STRINGS.delete_app}
                subTitle={`${STRINGS.are_you_sure_delete_app} "${appTodelete.name}" ?`}
                content={STRINGS.delete_app_warning}
                submitText={STRINGS.delete}
                cancelText={STRINGS.cancel}
                submitAction={() => deleteApplication(appTodelete.id)}
                onRequestClose={() => setAppToDelete(null)}
                cancelAction={() => setAppToDelete(null)}
              />
            )}
            {coachApps?.map((app, i) => (
              <AppWrapper key={i}>
                <AppCard
                  {...app}
                  onClick={() =>
                    history?.setCategory(URLS.app_page, STRINGS.url_id, app.id)
                  }
                >
                  <DeleteAppButton
                    className="card-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setAppToDelete(app);
                    }}
                  >
                    <DeleteAppButtonIcon />
                  </DeleteAppButton>
                </AppCard>
              </AppWrapper>
            ))}
          </AppsContainer>
        ) : (
          <CenteredFlex>
            <DashboardCardIcon
              icon={ICONS.DashboardCardApp}
              style={{
                width: "8rem",
                height: "8rem",
                backgroundSize: "40% auto",
              }}
            />
            <DashboardCardTitle style={{ fontSize: "1.8rem", margin: "1rem" }}>
              {STRINGS.create_your_first_app}
            </DashboardCardTitle>
            <DashboardCardContent
              style={{
                fontSize: "1.4rem",
                lineHeight: "1.8rem",
                color: COLORS.secondary.morning,
              }}
            >
              {lineByLine(STRINGS.it_seems_you_didnt_create)}
            </DashboardCardContent>
          </CenteredFlex>
        )}
      </TableOutline>
    </OutlineAuthorised>
  );
};

export default Apps;
