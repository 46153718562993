import * as Yup from "yup";
import STRINGS from "../constants/strings";

const email = Yup.string().trim().required().email();

const name = Yup.string()
  .trim()
  .required(STRINGS.required)
  .min(2, STRINGS.too_short);

// const role = Yup.string().required().oneOf(["Developer", "Marketing"]);

// const applicationIds = Yup.array().of(Yup.string().uuid()).min(1);

export const inviteMemberSchema = Yup.object().shape({
  email,
  name,
  // role,
  // applicationIds,
});
