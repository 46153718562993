import styled from "styled-components";

export const VerifyPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 61px;
`;

export const VerifyImage = styled.img``;
export const WelcomeWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const VerifyTitle = styled.h1`
  font-family: Ubuntu-Light;
  font-size: 60px;
  line-height: 47.2px;
  text-align: center;
  color: #08183f;
  font-weight: 300;
`;
export const VerifySubText = styled.p`
  font-family: Ubuntu-Regular;
  font-size: 18px;
  line-height: 24.22px;
  text-align: center;
  font-weight: 400;
  color: #5c6f68;
  margin-top: 15px;
`;

export const GoBackText = styled.p`
  margin-top: 100px;
  font-family: Ubuntu-Bold;
  font-size: 18px;
  text-align: center;
  width: 394px;
  line-height: 31.14px;
  color: rgba(8, 24, 63, 1);
  padding: 0 10px;
`;
