import { Link, useLocation } from "react-router-dom";
import React, { useContext } from "react";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import URLS from "../../constants/urls";

import ResponsiveSidebar from "../responsive-sidebar/ResponsiveSidebar";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import CustomButton from "../button/CustomButton";

import { HeaderBurger } from "../outline-authorised/components";
import {
  AdditionalBackground,
  WholeWrapper,
  HeaderWrapper,
  HeaderLogo,
  HeaderNavigation,
  HeaderNavigationTab,
  MainContainer,
  HeaderTail,
} from "./components";

const NavItems = () => (
  <>
    <a target="_blank" href={URLS.learning_center} rel="noreferrer">
      <HeaderNavigationTab>
        {STRINGS.header_learning_center}
      </HeaderNavigationTab>
    </a>
    <a target="_blank" href={URLS.community} rel="noreferrer">
      <HeaderNavigationTab>{STRINGS.header_community}</HeaderNavigationTab>
    </a>
    <a target="_blank" href={URLS.faq} rel="noreferrer">
      <HeaderNavigationTab>{STRINGS.header_FAQ}</HeaderNavigationTab>
    </a>
    <a target="_blank" href={URLS.waiting_list} rel="noreferrer">
      <HeaderNavigationTab>{STRINGS.header_waiting_list}</HeaderNavigationTab>
    </a>
    <a target="_blank" href={URLS.podcast} rel="noreferrer">
      <HeaderNavigationTab>{STRINGS.header_podcast}</HeaderNavigationTab>
    </a>
    <a target="_blank" href={URLS.contact_us} rel="noreferrer">
      <HeaderNavigationTab>{STRINGS.header_contact_us}</HeaderNavigationTab>
    </a>
  </>
);

const OutlineNonAuthorised = ({ children, doubleBackground }) => {
  const location = useLocation();
  const { isBeforeDesktop, respSidebarOpen, toggleRespSidebar } =
    useContext(ResponsiveContext);
  return (
    <WholeWrapper doubleBackground={doubleBackground}>
      <AdditionalBackground doubleBackground={doubleBackground}>
        <HeaderWrapper>
          {isBeforeDesktop && (
            <HeaderBurger
              style={{ marginRight: "1rem" }}
              onClick={toggleRespSidebar}
            />
          )}
          <a target="_blank" href={URLS.robotics_page} rel="noreferrer">
            <HeaderLogo
              src={
                isBeforeDesktop
                  ? ICONS.LogoRoundBackground
                  : ICONS.LogoHorizontal
              }
              style={isBeforeDesktop ? {} : { marginRight: "6.4rem" }}
            />
          </a>
          {isBeforeDesktop ? (
            <ResponsiveSidebar isOpen={respSidebarOpen}>
              <NavItems />
            </ResponsiveSidebar>
          ) : (
            <HeaderNavigation>
              <NavItems />
            </HeaderNavigation>
          )}
          <HeaderTail>
            {location?.pathname === URLS.sign_in ||
            location?.pathname === "/" ? (
              <Link to={URLS.sign_up}>
                <CustomButton
                  borderColor={COLORS.secondary.ncs}
                  capitalize
                  height={isBeforeDesktop ? "3.6rem" : ""}
                  margin={isBeforeDesktop ? "0" : "0 1rem"}
                  padding={isBeforeDesktop ? "0 2.4rem" : ""}
                >
                  {STRINGS.sign_up}
                </CustomButton>
              </Link>
            ) : (
              <Link to={URLS.sign_in}>
                <CustomButton
                  borderColor={COLORS.secondary.ncs}
                  capitalize
                  height={isBeforeDesktop ? "3.6rem" : ""}
                  margin={isBeforeDesktop ? "0" : "0 1rem"}
                  padding={isBeforeDesktop ? "0 2.4rem" : ""}
                >
                  {STRINGS.log_in}
                </CustomButton>
              </Link>
            )}
          </HeaderTail>
        </HeaderWrapper>
        <MainContainer>{children}</MainContainer>
      </AdditionalBackground>
    </WholeWrapper>
  );
};

export default OutlineNonAuthorised;
