import React from "react";
import { OptionWrapper, OptionCheck } from "./components";

const InputOption = ({
  isChecked,
  isDisabled,
  setIsChecked,
  children,
  padding,
  margin,
  icon,
  error,
  ...rest
}) => {
  return (
    <OptionWrapper
      padding={padding}
      margin={margin}
      isChecked={isChecked}
      onClick={setIsChecked}
      isDisabled={isDisabled}
      {...rest}
    >
      <OptionCheck
        error={error}
        className="checkmark"
        isChecked={isChecked}
        icon={icon}
      />{" "}
      {children}
    </OptionWrapper>
  );
};

export default InputOption;
