import React from "react";
import Modal from "react-modal";
import COLORS from "../../constants/colors";
import STRINGS from "../../constants/strings";
import { InfoModalTypes } from "../../constants/types";
import CustomButton from "../button/CustomButton";
import {
  ContentWrapper,
  ModalClose,
  ModalContent,
  ModalHeader,
  ModalHeaderIcon,
  ModalSubTitle,
  ModalTitle,
  WholeWrapper,
  ModalFooter,
  OkayLink,
} from "./components";

const customStyles = {
  overlay: {
    backgroundColor: `${COLORS.primary.oxford}80`,
    zIndex: "111",
  },
  content: {
    zIndex: "111",
    minWidth: "320px",
    maxWidth: "Calc(100% - 6.4rem)",
    height: "fit-content",
    top: "50%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "3.2rem",
    border: "none",
    padding: "0 ",
  },
};

const InfoModal = ({
  isOpen,
  onRequestClose,
  type,
  title,
  subTitle,
  content,
  okayText,
  cancelText,
  cancelAction,
  submitText,
  submitAction,
  noOuterClickClosing,
  disabled,
  gif,
}) => {
  return (
    <Modal
      onRequestClose={!noOuterClickClosing ? onRequestClose : undefined}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader type={type}>
          <ModalHeaderIcon type={type} />
          {!disabled && <ModalClose onClick={onRequestClose} />}
        </ModalHeader>
        <ContentWrapper>
          <ModalTitle>{title}</ModalTitle>
          <ModalSubTitle>{subTitle}</ModalSubTitle>
          {gif}
          <ModalContent>{content}</ModalContent>
          {!disabled && (
            <ModalFooter>
              {type === InfoModalTypes.SUCCESS ? (
                <OkayLink onClick={onRequestClose}>{okayText || STRINGS.okay}</OkayLink>
              ) : (
                <>
                  <CustomButton
                    borderColor={COLORS.secondary.morning}
                    margin="0 1rem"
                    padding="0 3.2rem"
                    onClick={cancelAction}
                  >
                    {cancelText}
                  </CustomButton>
                  <CustomButton
                    backgroundColor={COLORS.secondary.ncs}
                    shadow
                    margin="0 1rem"
                    padding="0 3rem"
                    onClick={submitAction}
                  >
                    {submitText}
                  </CustomButton>
                </>
              )}
            </ModalFooter>
          )}
        </ContentWrapper>
      </WholeWrapper>
    </Modal>
  );
};

export default InfoModal;
