import React, { useContext, useMemo } from "react";

import STRINGS from "../../constants/strings";
import ICONS from "../../constants/icons";

import DashboardLinkCard from "../../components/dashboard-link-card/DashboardLinkCard";
import OutlineAuthorised from "../../components/outline-authorised/OutlineAuthorised";
import ProfileIncomplete from "../../components/profile-incomplete/ProfileIncomplete";
import DashboardApps from "../../components/dashboard-apps/DashboardApps";
import { HorizontalDivider } from "../../components/general/components";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import useActiveCampaign from "../../hooks/actoveCampaign";
import mixpanel from "mixpanel-browser";
import { TRACK_EVENTS } from "../../constants/mixpanel";

const Dashboard = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const isProfileComplete = useMemo(
    () =>
      currentUser.firstName &&
      currentUser.lastName &&
      currentUser.email &&
      currentUser.phoneNumber &&
      currentUser.address &&
      currentUser.nickname &&
      currentUser.about,
    [currentUser]
  );

  const { handleDocsClick } = useActiveCampaign();

  return (
    <OutlineAuthorised pageTitle={STRINGS.welcome_to_ur} paddingTop="4.2rem">
      {!isProfileComplete && (
        <>
          <ProfileIncomplete />
          <HorizontalDivider />
        </>
      )}
      <DashboardApps />
      <HorizontalDivider />
      <DashboardLinkCard
        title={STRINGS.knowledge_center}
        icon={ICONS.KnowledgeCenter}
        moreText={STRINGS.read_more}
        to={{
          pathname: "https://documentation.unlimited-robotics.com/",
        }}
        onLinkClick={() => {
          handleDocsClick();
          mixpanel.track(TRACK_EVENTS.knowledgeCenter);
        }}
      >
        {STRINGS.knowledge_center_subtitle}
      </DashboardLinkCard>
      <DashboardLinkCard
        title={STRINGS.explore_our_demos}
        icon={ICONS.ExploreDemos}
        moreText={STRINGS.view_demos}
        to={{
          pathname:
            "https://github.com/Unlimited-Robotics/raya_python_examples",
        }}
        onLinkClick={() => {
          handleDocsClick();
          mixpanel.track(TRACK_EVENTS.examplesShow);
        }}
      >
        {STRINGS.explore_demos_subtitle}
      </DashboardLinkCard>
    </OutlineAuthorised>
  );
};

export default Dashboard;
