import styled from "styled-components";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const WholeWrapper = styled.div`
  width: 100%;
  border-radius: 0.4rem;
  background: #ffffff;
  box-shadow: 3px 3px 17px 0px #08183f14;
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  position: relative;
  height: 5.4rem;
`;

export const WarningLogo = styled.div`
  width: 4rem;
  height: 4rem;
  background: #ffa90310;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4rem;
    height: 4rem;
    background-image: url(${ICONS.AppReleaseInReview});
    background-repeat: no-repeat;
    background-size: 55% auto;
    background-position: center;
  }
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  margin: 0 2.4rem;
`;

export const ContentTitle = styled.div`
  font-family: ${FONTS.UbuntuBold};
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  margin-bottom: 0.8rem;
`;

export const ContentSubtitle = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: 0.5px;
  color: #0000003d;
`;

export const ArrowIcon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-image: url(${ICONS.ArrowRight});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 0.8rem;
`;
