import { Link } from "react-router-dom";
import React from "react";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import URLS from "../../constants/urls";

import DashboardAppCard from "./DashboardAppCard";
import CustomButton from "../button/CustomButton";

import { useApp } from "../../hooks";
import {
  Title,
  FlexBox,
  AppAddCard,
  AppAddCardIcon,
  AppAddCardText,
} from "./components";
import mixpanel from "mixpanel-browser";
import { TRACK_EVENTS, TRACK_VALUES } from "../../constants/mixpanel";

const DashboardApps = () => {
  const { coachApps } = useApp();

  return (
    <div>
      <Title>{STRINGS.my_apps}</Title>
      <FlexBox>
        {coachApps?.slice(0, 2)?.map((app) => (
          <DashboardAppCard key={app.id} {...app} />
        ))}
        <Link
          to={URLS.add_app}
          onClick={() =>
            mixpanel.track(TRACK_EVENTS.appCreationStarted, {
              origin: TRACK_VALUES[TRACK_EVENTS.appCreationStarted].dashboard,
            })
          }
        >
          <AppAddCard>
            <AppAddCardIcon />
            <AppAddCardText>+{STRINGS.add_new_app}</AppAddCardText>
            <CustomButton
              backgroundColor={COLORS.secondary.ncs}
              borderRadius="8px"
              height="4rem"
              margin="2.2rem 0 0 0"
              fontSize="1.2rem"
              fontFamily={FONTS.UbuntuMedium}
            >
              {STRINGS.lets_start}
            </CustomButton>
          </AppAddCard>
        </Link>
      </FlexBox>
    </div>
  );
};

export default DashboardApps;
