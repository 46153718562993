import styled from "styled-components";

export const SidebarMenuBack = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  background: #00000040;
  width: 100vw;
  left: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
  z-index: 4;
`;

export const SidebarMenuContainer = styled.div`
  width: 24rem;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 5;
  left: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
  transition: left 0.2s;
`;

export const SidebarMenuContent = styled.div`
  padding: 3.2rem 2rem;
`;
