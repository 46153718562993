import OutlineBack from "../assets/OutlineBack.svg";
import LogoHorizontal from "../assets/LogoHorizontal.svg";
import Search from "../assets/Search.svg";
import LogoRoundBackground from "../assets/LogoRoundBackground.svg";
import BurgerMenu from "../assets/BurgerMenu.svg";
import LogOut from "../assets/LogOut.svg";
import NavDashboard from "../assets/NavDashboard.svg";
import NavApps from "../assets/NavApps.svg";
import NavSettings from "../assets/NavSettings.svg";
import NavTeam from "../assets/NavTeam.svg";
import NavProfile from "../assets/NavProfile.svg";
import Email from "../assets/Email.svg";
import PasswordEye from "../assets/PasswordEye.svg";
import ForgotPasswordImg from "../assets/ForgotPasswordImg.svg";
import VerifyEmailImg from "../assets/VerifyEmailImg.svg";
import DashboardCardProfile from "../assets/DashboardCardProfile.svg";
import DashboardCardKnowledge from "../assets/DashboardCardKnowledge.svg";
import DashboardCardApp from "../assets/DashboardCardApp.svg";
import DashboardCardDemo from "../assets/DashboardCardDemo.svg";
import VerticalDots from "../assets/VerticalDots.svg";
import GrayTrash from "../assets/GrayTrash.svg";
import GrayEdit from "../assets/GrayEdit.svg";
import CloseGray from "../assets/CloseGray.svg";
import UserProfile from "../assets/UserProfile.svg";
import DropdownIndicator from "../assets/DropdownIndicator.svg";
import CheckWhite from "../assets/CheckWhite.svg";
import CircleWhite from "../assets/CircleWhite.svg";
import AppDefaultLogo from "../assets/AppDefaultLogo.png";
import AppCardDropdown from "../assets/AppCardDropdown.svg";
import AppStatusPending from "../assets/AppStatusPending.svg";
import AppStatusInReview from "../assets/AppStatusInReview.svg";
import AppStatusLive from "../assets/AppStatusLive.svg";
import AppStatusDraft from "../assets/AppStatusDraft.svg";
import Impressions from "../assets/Impressions.svg";
import Installs from "../assets/Installs.svg";
import Sales from "../assets/Sales.svg";
import Rating from "../assets/Rating.svg";
import Info from "../assets/Info.svg";
import WarningModalIcon from "../assets/WarningModalIcon.svg";
import AttentionModalIcon from "../assets/AttentionModalIcon.svg";
import SuccessModalIcon from "../assets/SuccessModalIcon.svg";
import CloseWhite from "../assets/CloseWhite.svg";
import AppReleaseInReview from "../assets/AppWaitingReview.svg";
import AppReleaseCancelled from "../assets/AppReleaseCancelled.svg";
import AppReleaseLive from "../assets/AppReleased.svg";
import NoReleaseIcon from "../assets/NoReleaseIcon.svg";
import NoDashboardIcon from "../assets/NoDashboardIcon.svg";
import ArrowRightGreenBox from "../assets/ArrowRightGreenBox.svg";
import RedirectIconEditApp from "../assets/RedirectIconEditApp.svg";
import RedirectIconCreateRelease from "../assets/RedirectIconCreateRelease.svg";
import DragAndDrop from "../assets/DragAndDrop.svg";
import UploadFileCloud from "../assets/UploadFileCloud.svg";
import DeleteBin from "../assets/DeleteBin.svg";
import Loader from "../assets/Loader.svg";
import LoaderGIF from "../assets/loading.gif";
import ArrowRight from "../assets/ArrowRight.svg";
import KnowledgeCenter from "../assets/KnowledgeCenter.svg";
import ExploreDemos from "../assets/ExploreDemos.svg";
import EditSimpleWhite from "../assets/EditSimpleWhite.svg";
import AddNewApp from "../assets/AddNewApp.svg";

const ICONS = {
  OutlineBack,
  LogoHorizontal,
  Search,
  LogoRoundBackground,
  BurgerMenu,
  LogOut,
  NavDashboard,
  NavApps,
  NavSettings,
  NavTeam,
  NavProfile,
  Email,
  PasswordEye,
  ForgotPasswordImg,
  VerifyEmailImg,
  DashboardCardProfile,
  DashboardCardKnowledge,
  DashboardCardApp,
  DashboardCardDemo,
  VerticalDots,
  GrayTrash,
  GrayEdit,
  CloseGray,
  UserProfile,
  DropdownIndicator,
  CheckWhite,
  CircleWhite,
  AppDefaultLogo,
  AppCardDropdown,
  AppStatusPending,
  AppStatusInReview,
  AppStatusLive,
  AppStatusDraft,
  Impressions,
  Installs,
  Sales,
  Rating,
  Info,
  WarningModalIcon,
  AttentionModalIcon,
  SuccessModalIcon,
  CloseWhite,
  AppReleaseCancelled,
  AppReleaseInReview,
  AppReleaseLive,
  NoReleaseIcon,
  NoDashboardIcon,
  ArrowRightGreenBox,
  RedirectIconEditApp,
  RedirectIconCreateRelease,
  DragAndDrop,
  UploadFileCloud,
  DeleteBin,
  Loader,
  LoaderGIF,
  ArrowRight,
  KnowledgeCenter,
  ExploreDemos,
  EditSimpleWhite,
  AddNewApp,
};

export default ICONS;
