import React, { useMemo } from "react";

import STRINGS from "../../constants/strings";

import { useUtils } from "../../hooks";

import {
  CardInfo,
  CardLogo,
  CardMain,
  CardWrapper,
  CardName,
  CardKind,
  CardPaid,
  CardStatusLabel,
  CardArrow,
  CardStatusLogo,
  CardStatusRow,
  CardStatusValue,
} from "./components";

const AppCard = ({
  name,
  paid,
  icon,
  id,
  margin,
  arrowUp,
  arrowAction,
  releases,
  category,
  children,
  appIcon,
  ...rest
}) => {
  const { statusByReleases } = useUtils();
  const [status, statusString] = useMemo(
    () => statusByReleases(releases),
    [statusByReleases, releases]
  );

  const cardLogo = useMemo(() => {
    const appLogo = icon?.publicUrl ? `${icon?.publicUrl}?${performance?.now()}` : "";
    return appLogo;
  }, [icon]);

  return (
    <CardWrapper margin={margin} className="card-wrapper" {...rest}>
      <CardMain>
        <CardLogo onClick={(e) => e.stopPropagation()} logo={cardLogo}>
          {appIcon || <></>}
        </CardLogo>
        <CardInfo>
          <CardName>{name}</CardName>
          <div>
            <CardKind>{category?.name}</CardKind>
            {!paid && (
              <>
                <CardKind>{" - "}</CardKind>
                <CardPaid>{STRINGS.free}</CardPaid>
              </>
            )}
          </div>

          <CardStatusRow>
            <CardStatusLabel>{STRINGS.status}:</CardStatusLabel>
            {statusString && <CardStatusLogo status={status} />}
            <CardStatusValue status={status}>{statusString || "  N/A"}</CardStatusValue>
          </CardStatusRow>
        </CardInfo>
      </CardMain>
      {arrowAction && <CardArrow arrowUp={arrowUp} onClick={arrowAction} />}
      {children}
    </CardWrapper>
  );
};

export default AppCard;
