import { useMutation } from "react-query";
import { useAlert } from "react-alert";

import STRINGS from "../constants/strings";
import URLS from "../constants/urls";

import {
  ApplicationReleaseCreate,
  ApplicationReleaseDelete,
  ApplicationReleaseEdit,
} from "../APIs";
import useHistoryCustom from "./historyCustom";

const useApplicationRelease = ({ refetchApp } = {}) => {
  const alert = useAlert();

  const { getCategory, SetQs } = useHistoryCustom();

  const applicationId = getCategory(URLS.app_page_releases, STRINGS.url_id);

  const { mutate: appReleaseCreateMutation, isLoading: appReleaseCreateLoading } = useMutation(
    "create-application-release",
    ApplicationReleaseCreate
  );
  const { mutate: appReleaseEditMutation, isLoading: appReleaseEditLoading } = useMutation(
    "create-application-edit",
    ApplicationReleaseEdit,
    {
      onSuccess: () => {
        SetQs({}, { eraseOthers: true });
        refetchApp();
      },
    }
  );
  const { mutate: appReleaseDeleteMutation } = useMutation(
    "delete-application-release",
    ApplicationReleaseDelete,
    {
      onSuccess: () => {
        refetchApp();
      },
    }
  );

  const appReleaseDelete = async (idToDelete) => {
    return appReleaseDeleteMutation({
      queryKey: idToDelete,
    });
  };

  const appReleaseEdit = async (vars, sub) => {
    const { version, web_app, garys_code, backendId, frontendId, garys_code_empty, id } = vars;
    const data = new FormData();
    data.append("version", version);
    data.append("status", sub ? "Submitted" : "Draft");
    web_app && data.append("frontend", web_app);
    frontendId?.value && data.append("frontendId", frontendId?.value);
    if (!garys_code_empty) {
      backendId?.value && data.append("backendId", backendId?.value);
      garys_code && data.append("backend", garys_code);
    }
    return await appReleaseEditMutation({
      queryKey: { releaseId: id, data },
    });
  };

  const appReleaseCreate = (vars, sub, { setErrors, refetchApp }) => {
    const { version, web_app, garys_code, backendId, frontendId, garys_code_empty } = vars;

    const data = new FormData();
    data.append("version", version);
    data.append("applicationId", applicationId);
    data.append("submit", sub);
    web_app && data.append("frontend", web_app);
    frontendId?.value && data.append("frontendId", frontendId?.value);
    if (!garys_code_empty) {
      backendId?.value && data.append("backendId", backendId?.value);
      garys_code && data.append("backend", garys_code);
    }

    return appReleaseCreateMutation(data, {
      onSuccess: (res) => {
        if (res?.data?.error) {
          if (res.data.message === "version_is_not_valid") {
            setErrors({ version: STRINGS.version_is_not_valid });
          } else {
            setErrors({
              general: STRINGS[res.data.message] || STRINGS.something_went_wrong,
            });
          }
        } else {
          SetQs({}, { eraseOthers: true });
          refetchApp();
        }
      },
      onError: (error) => {
        if (error.response.data.message === "version_is_not_valid") {
          setErrors({ version: STRINGS.version_is_not_valid });
        } else {
          setErrors({
            general: STRINGS[error.response.data.message] || STRINGS.something_went_wrong,
          });
        }
      },
    });
  };

  const appReleaseCreateUpdate = (vars, sub, { setErrors, refetchApp, hasUnsavedChanges }) => {
    if (hasUnsavedChanges instanceof Function && !hasUnsavedChanges()) {
      alert.info("No changes to save !");
      return;
    }
    if (vars?.id) {
      appReleaseEdit(vars, sub, { setErrors, refetchApp });
    } else {
      appReleaseCreate(vars, sub, { setErrors, refetchApp });
    }
  };

  return {
    appReleaseCreateUpdate,
    appReleaseDelete,
    isLoading: appReleaseCreateLoading || appReleaseEditLoading,
  };
};

export default useApplicationRelease;
