import React from "react";
import CustomButton from "../../components/button/CustomButton";
import COLORS from "../../constants/colors";
import { TableWrapper, TableWrapperHeader, TableWrapperTitle, TableWrapperText } from "./components";

const TableOutline = ({ title, subTitle, actionName, actionAction, children }) => {
  return (
    <TableWrapper>
      <TableWrapperHeader>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <TableWrapperTitle>{title}</TableWrapperTitle>
          {subTitle && <TableWrapperText>{subTitle} </TableWrapperText>}
        </div>
        <div>
          <CustomButton
            backgroundColor={COLORS.secondary.ncs}
            onClick={actionAction}
            shadow
            height="5.6rem"
            padding="0 5rem"
            capitalize
          >
            {actionName}
          </CustomButton>
        </div>
      </TableWrapperHeader>
      {children}
    </TableWrapper>
  );
};

export default TableOutline;
