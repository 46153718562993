import React, { createContext, useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import mixpanel from "mixpanel-browser";

import { getCurrentUser, googleAuth, googleAuthUnity, signIn } from "../APIs";
import { TRACK_EVENTS } from "../constants/mixpanel";

const mixpanelLogin = (currentUser, fromGoogle = false) => {
  const { id, email, firstName, lastName } = currentUser;

  mixpanel.identify(id);

  mixpanel.people.set_once({
    id,
    $email: email,
    firstName,
    lastName,
  });

  mixpanel.track(fromGoogle ? TRACK_EVENTS.gmailSignup : TRACK_EVENTS.logIn);
};

export const CurrentUserContext = createContext(null);

export const CurrentUserProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const { mutate: userSignInMutation } = useMutation("sign-in", signIn);

  const userSignIn = useCallback(
    (v, { setErrors, goToDashboard }) => {
      return userSignInMutation(v, {
        onError: (err) => {
          if (err?.response?.data?.message) {
            setErrors({ general: err.response.data.message });
          }
          console.log("err", err);
        },
        onSuccess: (data) => {
          if (!data.error) {
            mixpanelLogin(data?.user);
            goToDashboard();
          }
        },
      });
    },
    [userSignInMutation]
  );

  const { mutate: userGoogleAuthMutation } = useMutation(
    "google-auth",
    googleAuth
  );

  const userGoogleAuth = useCallback(
    (v, { setErrors, goToDashboard }) => {
      return userGoogleAuthMutation(v, {
        onError: (err) => console.log("err", err),
        onSuccess: (data) => {
          if (data.error) {
            setErrors({ general: data?.message });
          } else {
            mixpanelLogin(data?.user, true);
            goToDashboard();
          }
        },
      });
    },
    [userGoogleAuthMutation]
  );

  const { mutate: userGoogleAuthUnityMutation } = useMutation(
    "unity/google-auth",
    googleAuthUnity
  );

  const userGoogleAuthUnity = useCallback(
    (v, { endAuthorization, setErrors }) => {
      return userGoogleAuthUnityMutation(v, {
        onError: (err) => console.log("err", err),
        onSuccess: (data) => {
          if (data.error) {
            setErrors({ general: data?.message });
          } else {
            endAuthorization();
            mixpanelLogin(data?.user, true);
          }
        },
      });
    },
    [userGoogleAuthUnityMutation]
  );

  const {
    data: currentUser,
    isLoading: userLoading,
    refetch: refetchCurrentUser,
  } = useQuery("current-user", getCurrentUser, {
    enabled: !!localStorage.getItem("token"),
    onError: () => localStorage.removeItem("token"),
  });

  const logOut = useCallback(() => {
    localStorage.removeItem("token");
    refetchCurrentUser();

    try {
      mixpanel.reset();
    } catch (err) {
      console.log("reset mix-erro:", err);
    }
  }, [refetchCurrentUser]);

  const contextValue = useMemo(
    () => ({
      currentUser,
      isLoading: userLoading,
      userSignIn,
      userGoogleAuth,
      userGoogleAuthUnity,
      logOut,
      sidebarOpen,
      setSidebarOpen,
      refetchCurrentUser,
    }),
    [
      currentUser,
      userLoading,
      userSignIn,
      userGoogleAuth,
      userGoogleAuthUnity,
      logOut,
      sidebarOpen,
      setSidebarOpen,
      refetchCurrentUser,
    ]
  );

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  );
};
